import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import wretch from 'wretch';
import moment from 'moment-timezone';

import { IWOS_COMMANDS } from 'utils/constants';

import CommandHistory from 'components/CommandHistory';

moment.tz.setDefault('UTC');

const IWOSCommands = ({ device }) => {
	const { username, token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [reportingMode, setReportingMode] = useState('');
	const [gps, setGps] = useState('');
	const [module, setModule] = useState('');
	const [other, setOther] = useState('');
	const [isSending, setSending] = useState(false);
	const [disableSend, setDisableSend] = useState(false);
	const [confirmType, setConfirmType] = useState(null);

	//values that need secondary inputs
	const [reportingModeRate, setReportingModeRate] = useState('5');
	const [reportingModeSpeci, setReportingModeSpeci] = useState('');
	const [gpsElevation, setGpsElevation] = useState('');
	const [gpsCompassDecline, setGpsCompassDecline] = useState('');
	const [modulesDisable, setModulesDisable] = useState('');
	const [modulesEnable, setModulesEnable] = useState('');
	const [otherDate, setOtherDate] = useState('');

	const [errorFields, setErrorFields] = useState([]);
	const [shouldGetHistory, setShouldGetHistory] = useState(false);

	const {
		REPORTING,
		GPS,
		MODULES,
		OTHER
	} = IWOS_COMMANDS;

	//disable the send button if a request is in progress, or all values are blank,
	//or if the secondary values are blank or don't match regex
	useEffect(() => {
		if (
			isSending ||
			(reportingMode === '' && gps === '' && module === '' && other === '') ||
			(reportingMode === REPORTING.SPECI &&
				(!reportingModeSpeci.match(/^([0-9]{2})$/) || parseInt(reportingModeSpeci) > 60))
		) {
			setDisableSend(true);
		} else {
			setDisableSend(false);
		}

		let eFields = errorFields;
		setErrorFields(eFields);
	}, [isSending, gps, reportingMode, reportingModeSpeci, module, other]);

	const submitCommand = async (e) => {
		e.preventDefault();
		setSending(true);

		let reportingModeFormatted = reportingMode;
		if (reportingMode === REPORTING.RATE) {
			reportingModeFormatted += reportingModeRate;
		} else if (reportingMode === REPORTING.SPECI) {
			reportingModeFormatted += reportingModeSpeci;
		}

		let gpsFormatted = gps;
		if (gps === GPS.SET_ELEV) {
			gpsFormatted += gpsElevation;
		} else if (gps === GPS.COMPASS_DECLINE) {
			gpsFormatted += gpsCompassDecline;
		}

		let moduleFormatted = module;
		if (module === MODULES.DISABLE) {
			moduleFormatted += modulesDisable;
		} else if (module === MODULES.ENABLE) {
			moduleFormatted += modulesEnable;
		}

		let otherFormatted = other;
		if (other === OTHER.SET_DATE) {
			otherFormatted += otherDate;
		}

		const commandsToSend = [];
		[reportingModeFormatted, gpsFormatted, moduleFormatted, otherFormatted].forEach((command) => {
			if (command !== '') {
				commandsToSend.push(command);
			}
		});

		let options = {
			method: 'IWOSCommands',
			params: {},
			timeout: 5000,
		};

		options['params']['DestinationIMEI'] = [device.imei];
		options['params']['DeviceType'] = ['IWOS'];
		options['params']['Command'] = [commandsToSend.join(' ')];
		options['params']['Issuer'] = [username];
		options['params']['Modem'] = [device.modem];
		options['params']['CmdSendDate'] = moment().valueOf();

		await wretch(`${process.env.REACT_APP_UTIL_API_URL}/unit/commands/send`)
			.auth(`Bearer ${token}`)
			.post({
				deviceId: device.id,
				options: options,
			})
			.error(500, (error) => {
				console.log(error);
				setConfirmType('error');
			})
			.res(() => {
				setConfirmType('success');
				setShouldGetHistory(true);
			})
			.finally(() => {
				setSending(false);
			});

		setTimeout(() => {
			setConfirmType(null);
		}, 7000);
	};

	return (
		<>
			<div className="command-form">
				<div className="command-input">
					<span>Reporting Mode:&nbsp;&nbsp;</span>
					<select onChange={(e) => setReportingMode(e.target.value)}>
						<option value="">No command</option>
						<option value={REPORTING.RATE}>Transmit Module Frequency</option>
						<option value={REPORTING.SPECI}>Speci Repeat Time</option>
					</select>
					{reportingMode === REPORTING.RATE && (
						<>
							<div></div>
							<div className="command-input sub-command">
								<span>Rate:&nbsp;&nbsp;</span>
								<select onChange={(e) => setReportingModeRate(e.target.value)}>
									<option value="5">Every 5 minutes</option>
									<option value="10">Every 10 minutes</option>
									<option value="60">Every 60 minutes</option>
								</select>
							</div>
						</>
					)}
					{reportingMode === REPORTING.SPECI && (
						<>
							<div></div>
							<div className="command-input sub-command">
								<span># of mins:&nbsp;&nbsp;</span>
								<input
									type="text"
									placeholder="00 - 60 minutes"
									className={errorFields.includes('reportingModeSpeci') ? 'error' : ''}
									maxLength={2}
									value={reportingModeSpeci}
									onChange={(e) => setReportingModeSpeci(e.target.value)}
								/>
							</div>
						</>
					)}
				</div>
				<div className="command-input">
					<span>GPS:&nbsp;&nbsp;</span>
					<select onChange={(e) => setGps(e.target.value)}>
						<option value="">No command</option>
						<option value={GPS.DISABLE}>Disable GPS</option>
						<option value={GPS.ENABLE}>Enable GPS</option>
						<option value={GPS.DISABLE_ELEV}>Disable Elevation</option>
						<option value={GPS.ENABLE_ELEV}>Enable Elevation</option>
						<option value={GPS.READ}>GPS Immediate Read</option>
						<option value={GPS.READ_LOC}>GPS Immediate Read No Elevation</option>
						<option value={GPS.SET_ELEV}>Set Elevation</option>
						<option value={GPS.COMPASS_DECLINE}>Set Declination</option>
					</select>
					{gps === GPS.SET_ELEV && (
						<>
							<div></div>
							<div className="command-input sub-command">
								<span>Elevation (m):&nbsp;&nbsp;</span>
								<input
									type="text"
									placeholder="meters above sea level"
									className={errorFields.includes('gpsElevation') ? 'error' : ''}
									value={gpsElevation}
									onChange={(e) => setGpsElevation(e.target.value)}
								/>
							</div>
						</>
					)}
					{gps === GPS.COMPASS_DECLINE && (
						<>
							<div></div>
							<div className="command-input sub-command">
								<span>Degrees:&nbsp;&nbsp;</span>
								<input
									type="text"
									placeholder="-180 to 180"
									className={errorFields.includes('gpsCompassDecline') ? 'error' : ''}
									value={gpsCompassDecline}
									onChange={(e) => setGpsCompassDecline(e.target.value)}
								/>
							</div>
						</>
					)}
				</div>
				<div className="command-input">
					<span>Enable/Disable Modules:&nbsp;&nbsp;</span>
					<select onChange={(e) => setModule(e.target.value)}>
						<option value="">No command</option>
						<option value={MODULES.DISABLE}>Disable</option>
						<option value={MODULES.ENABLE}>Enable</option>
					</select>
					{module === MODULES.DISABLE && (
						<>
							<div></div>
							<div className="command-input sub-command">
								<span>Module Ids:&nbsp;&nbsp;</span>
								<input
									type="text"
									placeholder="CT,TPH"
									className={errorFields.includes('modulesDisable') ? 'error' : ''}
									value={modulesDisable}
									onChange={(e) => setModulesDisable(e.target.value)}
								/>
							</div>
						</>
					)}
					{module === MODULES.ENABLE && (
						<>
							<div></div>
							<div className="command-input sub-command">
								<span>Module Ids:&nbsp;&nbsp;</span>
								<input
									type="text"
									placeholder="CT,TPH"
									className={errorFields.includes('modulesEnable') ? 'error' : ''}
									value={modulesEnable}
									onChange={(e) => setModulesEnable(e.target.value)}
								/>
							</div>
						</>
					)}
				</div>
				<div className="command-input">
					<span>Other Commands:&nbsp;&nbsp;</span>
					<select onChange={(e) => setOther(e.target.value)}>
						<option value="">No command</option>
						<option value={OTHER.SET_DATE}>Set Date/Time</option>
						<option value={OTHER.DEFAULT}>Default Settings</option>
						<option value={OTHER.LOG_CLEAR}>Clear Log</option>
						<option value={OTHER.LOG_DISABLE}>Disable Logging</option>
						<option value={OTHER.LOG_ENABLE}>Enable Logging</option>
						<option value={OTHER.RESET}>Reset Unit</option>
					</select>
					{other === OTHER.SET_DATE && (
						<>
							<div></div>
							<div className="command-input sub-command">
								<span>Date:&nbsp;&nbsp;</span>
								<input
									type="text"
									placeholder="DD/MM/YY HH:MM:SS"
									className={errorFields.includes('otherDate') ? 'error' : ''}
									value={otherDate}
									onChange={(e) => setOtherDate(e.target.value)}
								/>
							</div>
						</>
					)}
				</div>
				<div className="command-input">
					<span></span>
					<div className="with-confirm">
						<input
							type="submit"
							value="Send"
							disabled={disableSend}
							onClick={(e) => submitCommand(e)}
						/>
						<div
							className={`confirmation-message success ${confirmType === 'success' ? 'show' : ''}`}>
							The command has been successfully queued.
						</div>
						<div className={`confirmation-message error ${confirmType === 'error' ? 'show' : ''}`}>
							Sorry, there was an error queuing the command.
						</div>
					</div>
				</div>
			</div>
			<CommandHistory
				device={device}
				shouldGetHistory={shouldGetHistory}
				setShouldGetHistory={setShouldGetHistory}
			/>
		</>
	);
};

IWOSCommands.propTypes = {
	device: PropTypes.object.isRequired,
};

export default IWOSCommands;
