import React, { createContext, useReducer } from 'react';

const initialState = {
	notifications: [],
};

const actions = {
	SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.SET_NOTIFICATIONS:
			return { ...state, notifications: [...action.notifications] };
		default:
			return state;
	}
};

export const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = {
		notifications: state.notifications,
		setNotifications: (notifications) => {
			dispatch({ type: actions.SET_NOTIFICATIONS, notifications });
		},
	};

	return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export default NotificationsProvider;
