import React from 'react';
import PropTypes from 'prop-types';
import config from 'utils/config';

import './Forms.scss';

const CommandForm = ({ device }) => {
	const showCommands = () => {
		return config[device.type]['command'](device);
	};

	return <div className="device-command-wrapper">{showCommands()}</div>;
};

CommandForm.propTypes = {
	device: PropTypes.object.isRequired,
};

export default CommandForm;
