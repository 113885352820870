/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import wretch from 'wretch';

const ContactUs = () => {
	const { token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [isFormLoading, setIsFormLoading] = useState(false);
	const [confirmType, setConfirmType] = useState(null);

	const submitEmail = async (e) => {
		e.preventDefault();
		setIsFormLoading(true);

		await wretch(`${process.env.REACT_APP_UTIL_API_URL}/inquiry`)
			.auth(`Bearer ${token}`)
			.post({
				email: email,
				name: `${firstName} ${lastName}`,
				message: message,
			})
			.error(500, (error) => {
				console.log(error);
				setConfirmType('error');
			})
			.json(() => {
				setIsFormLoading(false);
				setConfirmType('success');
			});

		setTimeout(() => {
			setConfirmType(null);
		}, 7000);
	};

	return (
		<>
			<p className="form-disclaimer">All fields are required</p>
			<form className="submit-email-form" onSubmit={submitEmail}>
				<div className="form-row">
					<label htmlFor="first-name">First Name:</label>
					<input
						required
						id="first-name"
						type="text"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
				</div>
				<div className="form-row">
					<label htmlFor="last-name">Last Name:</label>
					<input
						required
						id="last-name"
						type="text"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					/>
				</div>
				<div className="form-row">
					<label htmlFor="login-username">Email:</label>
					<input
						required
						id="email"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div className="form-row top-align">
					<label htmlFor="login-password">Message:</label>
					<textarea
						id="message"
						rows={6}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
				</div>
				<div className="form-row submit">
					<div></div>
					<input type="submit" value="Submit" disabled={isFormLoading} />
				</div>
				<div className="form-row">
					<div></div>
					<div className="confirm-block">
						<div
							className={`confirmation-message success ${confirmType === 'success' ? 'show' : ''}`}>
							The email was successfully sent.
						</div>
						<div className={`confirmation-message error ${confirmType === 'error' ? 'show' : ''}`}>
							Sorry, there was an error sending the email.
							<br />
							Please try again or email us directly at MWSHelp@intellisenseinc.com.
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

export default ContactUs;
