import React from 'react';
import moment from 'moment';
import { formatCloudLayersValue, getMETAR, doesWeatherValueExist } from 'utils/functions';
import __isEmpty from 'lodash/isEmpty';

import Compass from 'components/Compass';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import OpacityIcon from '@material-ui/icons/Opacity';
import CloudIcon from '@material-ui/icons/Cloud';
import WarningIcon from '@material-ui/icons/Warning';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import Battery60Icon from '@material-ui/icons/Battery60';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';

const IWOSDashboard = ({
	latestEntry,
	isLightningActive,
	isRainActive,
	isCloudsActive,
	isTamperingActive,
	batteryLevel,
	showMetricOnly,
}) => {
	let latLon;
	let temp;
	let dew;
	let precip;
	let lightningDist;
	let humidity;
	let altimeter;
	let pressureAlt;
	let denseAlt;
	let stationPressure;
	let windSpeed;
	let windDir;
	let maxWindSpeed;
	let maxWindDir;
	let cloudLayers;
	let cloudCoverage;
	let lowestCloudLayer;
	let precipMoving;
	let precipAcc;
	let METAR;
	let verticalVisibility;

	latLon = doesWeatherValueExist(latestEntry.lat) ? (
		`${latestEntry.lat.value}°, ${latestEntry.lon.value}°`
	) : (
		<>&nbsp;</>
	);
	METAR = getMETAR(latestEntry);
	cloudLayers = doesWeatherValueExist(latestEntry.cloudLayers) ? (
		formatCloudLayersValue(latestEntry.cloudLayers)
	) : (
		<>&nbsp;</>
	);
	lowestCloudLayer = doesWeatherValueExist(latestEntry.lowestCloud) ? (
		`${latestEntry.lowestCloud.value} ft`
	) : (
		<>&nbsp;</>
	);
	cloudCoverage = doesWeatherValueExist(latestEntry.cloudCoverage) ? (
		latestEntry.cloudCoverage.value
	) : (
		<>&nbsp;</>
	);
	lightningDist =
		latestEntry.lightningFreq.value != '0' && doesWeatherValueExist(latestEntry.lightningDist) ? (
			`${latestEntry.lightningDist.value} NM`
		) : (
			<>&nbsp;</>
		);
	humidity = doesWeatherValueExist(latestEntry.humidity) ? (
		`${latestEntry.humidity.value}%`
	) : (
		<>&nbsp;</>
	);
	windDir = doesWeatherValueExist(latestEntry.windDir2min) ? (
		`${latestEntry.windDir2min.value}°`
	) : (
		null
	);
	maxWindDir = doesWeatherValueExist(latestEntry.maxWindDir) ? (
		`${latestEntry.maxWindDir.value}°`
	) : (
		<>&nbsp;</>
	);
	verticalVisibility = doesWeatherValueExist(latestEntry.verticalVisibility) ? (
		latestEntry.verticalVisibility.value == '0' ? (
			<>&nbsp;</>
		) : (
			`${latestEntry.verticalVisibility.value} ft`
		)
	) : (
		<>&nbsp;</>
	);
	stationPressure =
		doesWeatherValueExist(latestEntry.haPressureInHg) && latestEntry.haPressureInHg.value != '0' ? (
			`${latestEntry.haPressureInHg.value} inHg`
		) : latestEntry.stationPressure && latestEntry.stationPressure.value ? (
			`${latestEntry.stationPressure.value} inHg`
		) : (
			<>&nbsp;</>
		);

	if (showMetricOnly) {
		temp = doesWeatherValueExist(latestEntry.temperatureC) ? (
			`${latestEntry.temperatureC.value} °C`
		) : (
			<>&nbsp;</>
		);
		dew = doesWeatherValueExist(latestEntry.dewPoint) ? (
			`${latestEntry.dewPoint.value} °C`
		) : (
			<>&nbsp;</>
		);
		precip = doesWeatherValueExist(latestEntry.precipMm) ? (
			`${latestEntry.precipMm.value} mm/hr`
		) : (
			<>&nbsp;</>
		);
		altimeter = doesWeatherValueExist(latestEntry.altimeterMb) ? (
			`${latestEntry.altimeterMb.value} mb/hPa`
		) : (
			<>&nbsp;</>
		);
		pressureAlt = doesWeatherValueExist(latestEntry.pressureAlt) ? (
			`${latestEntry.pressureAlt.value} m`
		) : (
			<>&nbsp;</>
		);
		denseAlt = doesWeatherValueExist(latestEntry.denseAlt) ? (
			`${latestEntry.denseAlt.value} m`
		) : (
			<>&nbsp;</>
		);
		windSpeed = doesWeatherValueExist(latestEntry.windSpeed2minMs) ? (
			`${latestEntry.windSpeed2minMs.value} m/s`
		) : (
			<>&nbsp;</>
		);
		maxWindSpeed = doesWeatherValueExist(latestEntry.maxWindSpeedMps) ? (
			`${latestEntry.maxWindSpeedMps.value} m/s`
		) : (
			<>&nbsp;</>
		);
		precipMoving = doesWeatherValueExist(latestEntry.precip3hrMm) ? (
			`${latestEntry.precip3hrMm.value} mm/hr, ${latestEntry.precip6hrMm.value} mm/hr, ${latestEntry.precip24hrMm.value} mm/hr `
		) : (
			<>&nbsp;</>
		);
		precipAcc = doesWeatherValueExist(latestEntry.precipAcc3hrMm) ? (
			`${latestEntry.precipAcc3hrMm.value} mm, ${latestEntry.precipAcc6hrMm.value} mm, ${latestEntry.precipAcc24hrMm.value} mm`
		) : (
			<>&nbsp;</>
		);
	} else {
		temp = doesWeatherValueExist(latestEntry.temperatureF) ? (
			`${latestEntry.temperatureF.value} °F`
		) : (
			<>&nbsp;</>
		);
		dew = doesWeatherValueExist(latestEntry.dewPointF) ? (
			`${latestEntry.dewPointF.value} °F`
		) : (
			<>&nbsp;</>
		);
		precip = doesWeatherValueExist(latestEntry.precip) ? (
			`${latestEntry.precip.value} in/hr`
		) : (
			<>&nbsp;</>
		);
		altimeter = doesWeatherValueExist(latestEntry.altimeter) ? (
			`${latestEntry.altimeter.value} inHg`
		) : (
			<>&nbsp;</>
		);
		pressureAlt = doesWeatherValueExist(latestEntry.pressureAltFt) ? (
			`${latestEntry.pressureAltFt.value} ft`
		) : (
			<>&nbsp;</>
		);
		denseAlt = doesWeatherValueExist(latestEntry.denseAltFt) ? (
			`${latestEntry.denseAltFt.value} ft`
		) : (
			<>&nbsp;</>
		);
		windSpeed = doesWeatherValueExist(latestEntry.windSpeed2minMph) ? (
			`${latestEntry.windSpeed2minMph.value} mph`
		) : (
			<>&nbsp;</>
		);
		maxWindSpeed = doesWeatherValueExist(latestEntry.maxWindSpeedMph) ? (
			`${latestEntry.maxWindSpeedMph.value} mph`
		) : (
			<>&nbsp;</>
		);
		precipMoving = doesWeatherValueExist(latestEntry.precip3hr) ? (
			`${latestEntry.precip3hr.value} in/hr, ${latestEntry.precip6hr.value} in/hr, ${latestEntry.precip24hr.value} in/hr `
		) : (
			<>&nbsp;</>
		);
		precipAcc = doesWeatherValueExist(latestEntry.precipAcc3hr) ? (
			`${latestEntry.precipAcc3hr.value} in, ${latestEntry.precipAcc6hr.value} in, ${latestEntry.precipAcc24hr.value} in`
		) : (
			<>&nbsp;</>
		);
	}

	const isValue = (value) => typeof value === 'string';
	return (
		<div className="view-inner" data-with-compass={isValue(windSpeed)}>
			<div className="top-info">
				<div className="left-side">
					<div className="info-box">
						<label>Time (UTC)</label>
						<div className="value">
							{moment(latestEntry.timestamp).format('YYYY-MM-DD HH:mm:ss')}
						</div>
					</div>
					<div className="icon-view">
						<p className="status-title">Status</p>
						<div className="status">
							<div className="status-row">
								<div className="lightning icon-toggle" data-active={isLightningActive}>
									<FlashOnIcon />
									<p className="status-text">Lightning</p>
								</div>
								<div className="rain icon-toggle" data-active={isRainActive}>
									<OpacityIcon />
									<p className="status-text">Rain</p>
								</div>
							</div>
							<div className="status-row">
								<div className="clouds icon-toggle" data-active={isCloudsActive}>
									<CloudIcon />
									<p className="status-text">Clouds</p>
								</div>
								<div className="tampering icon-toggle" data-active={isTamperingActive}>
									<WarningIcon />
									<p className="status-text">Tampering</p>
								</div>
							</div>
							<div className="status-row center">
								<div className="battery" data-level={batteryLevel}>
									{batteryLevel === 'good' && <BatteryFullIcon />}
									{batteryLevel === 'meh' && <Battery60Icon />}
									{batteryLevel === 'bad' && <BatteryAlertIcon />}
									<p className="status-text">Battery</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="right-side">
					{/* <div className="info-box">
                        <label>Coordinates</label>
                        <div className="value">{latLon}</div>
                    </div> */}
					<div className="sub-grid">
						<div className="info-box">
							<label>Temperature</label>
							<div className="value">{temp}</div>
						</div>
						<div className="info-box">
							<label>Dew Point</label>
							<div className="value">{dew}</div>
						</div>
						<div className="info-box">
							<label>Precipitation</label>
							<div className="value">{precip}</div>
						</div>
						<div className="info-box">
							<label>Lightning Distance</label>
							<div className="value">{lightningDist}</div>
						</div>
						<div className="info-box">
							<label>Humidity</label>
							<div className="value">{humidity}</div>
						</div>
						<div className="info-box">
							<label>Altimeter</label>
							<div className="value">{altimeter}</div>
						</div>
						<div className="info-box">
							<label>Pressure Altitude</label>
							<div className="value">{pressureAlt}</div>
						</div>
						<div className="info-box">
							<label>Density Altitude</label>
							<div className="value">{denseAlt}</div>
						</div>
						<div className="info-box">
							<label>Station Pressure</label>
							<div className="value">{stationPressure}</div>
						</div>
						<div className="info-box">
							<label>Vertical Visibility</label>
							<div className="value">{verticalVisibility}</div>
						</div>
					</div>
					<br />
					<br />
					{/* <div className="info-box">
                        <label>Precipitation Moving Average Rates (3hr, 6hr, 24hr)</label>
                        <div className="value">{precipMoving}</div>
                    </div>
                    <br /> */}
					<div className="info-box">
						<label>Precipitation Accumulations (3hr, 6hr, 24hr)</label>
						<div className="value">{precipAcc}</div>
					</div>
				</div>
				{isValue(windSpeed) && (
					<div className="compass-side">
						<div>
							<label>Sustained Winds</label>
							<Compass direction={windDir ? parseInt(windDir) : null} windSpeed={windSpeed} />
						</div>
						<div className="sub-grid">
							<div className="info-box">
								<label>Max Wind</label>
								<div className="value">{maxWindSpeed}</div>
							</div>
							<div className="info-box">
								<label>Max Wind Dir.</label>
								<div className="value">{maxWindDir}</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="bottom-info">
				<div className="info-box full-width">
					<label>Clouds</label>
					<div className="value">
						{cloudLayers}
						{isValue(lowestCloudLayer) && (
							<>
								<br />
								<br />
								Lowest Broken Layer at {lowestCloudLayer}
							</>
						)}
						{/* {cloudCoverage && cloudCoverage.replace(/ /g, '') !== ',,,,,,' && <><br /><br />{cloudCoverage}</>} */}
					</div>
				</div>
				{/* <div className="info-box full-width">
                    <label></label>
                    <div className="value">{METAR}</div>
                </div> */}
			</div>
		</div>
	);
};

export default IWOSDashboard;
