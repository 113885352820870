/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import wretch from 'wretch';
import { DEFAULT_PASSWORD, USER_ROLES } from 'utils/constants';

const MyInformation = () => {
	const selfData = JSON.parse(sessionStorage.getItem('selfData'));
	const masterEmail = selfData.username;
	const [username, setUsername] = useState(masterEmail || '');
	const [password, setPassword] = useState('');
	const [firstName, setFirstName] = useState(selfData.firstName || '');
	const [lastName, setLastName] = useState(selfData.lastName || '');
	const [showPassword, setShowPassword] = useState(false);
	const [companyName, setCompanyName] = useState(selfData.companyName || '');
	const [isFormLoading, setIsFormLoading] = useState(false);

	const submitUserUpdate = async (e) => {
		e.preventDefault();
		setIsFormLoading(true);

		try {
			let dataToSave = {
				email: username,
				groupId: selfData.groupId,
				firstName,
				lastName,
				companyName,
			};

			if (password !== '') {
				if (isValidPassword()) {
					dataToSave['password'] = password;
				} else {
					alert(
						'Your password should be at least 10 characters with at least one number, one lowercase letter, and one uppercase letter.',
					);
					return false;
				}
			}

			await wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/${selfData.userId}/update`)
				.auth(`Bearer ${selfData.token}`)
				.post(dataToSave);

			sessionStorage.setItem('selfData', JSON.stringify({ ...selfData, ...dataToSave }));
			alert('Your info has been saved.');
		} catch (error) {
			console.log(error);

			if (error.text && JSON.parse(error.text).type === 'token') {
				alert('Your session has expired. Please log in again.');
				return;
			}

			alert('There was a problem updating your info. Please try again or contact us.');
		} finally {
			setIsFormLoading(false);
		}
	};

	const togglePassword = (e) => {
		e.preventDefault();

		const show = showPassword;
		setShowPassword(!show);
	};

	const isValidPassword = () => {
		const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
		return regex.test(password);
	};

	return (
		<>
			<p className="form-disclaimer">All fields are required</p>
			<form className="update-user-form" onSubmit={submitUserUpdate}>
				<div className="form-row">
					<label htmlFor="login-username">Email:</label>
					<input
						required
						id="login-username"
						type="email"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</div>
				<div className="form-row">
					<label htmlFor="login-password">Password:</label>
					<input
						id="login-password"
						type={showPassword ? 'text' : 'password'}
						value={password}
						placeholder={DEFAULT_PASSWORD}
						onChange={(e) => setPassword(e.target.value)}
					/>
					{password.length > 0 && (
						<button className="password-visibility" onClick={togglePassword}>
							{showPassword ? 'Hide' : 'Show'}
						</button>
					)}
				</div>
				<div className="form-row">
					<label htmlFor="first-name">First Name:</label>
					<input
						required
						id="first-name"
						type="text"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
				</div>
				<div className="form-row">
					<label htmlFor="last-name">Last Name:</label>
					<input
						required
						id="last-name"
						type="text"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					/>
				</div>
				{selfData.roleId !== USER_ROLES.USER && (
					<div className="form-row">
						<label htmlFor="company">Company/Affiliation:</label>
						<input
							required
							id="company"
							type="text"
							value={companyName}
							onChange={(e) => setCompanyName(e.target.value)}
						/>
					</div>
				)}
				<div className="form-row submit">
					<div></div>
					<input type="submit" value="Update" disabled={isFormLoading} />
				</div>
			</form>
		</>
	);
};

export default MyInformation;
