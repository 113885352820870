import React from 'react';

import IWOSTable from './data/Table';
import IWOSDashboard from './data/Dashboard';
import IWOSCommands from './data/Commands';
import returnIWOSPlots from './data/Plots';
import returnIWOSExport from './data/Export';

const IWOS = {
	constants: {
		imageURL: '',
	},
	table: (props) => <IWOSTable {...props} />,
	plots: (entries, showMetricOnly) => returnIWOSPlots(entries, showMetricOnly),
	export: (entries, deviceTypeOfficial, showMetricOnly) => returnIWOSExport(entries, deviceTypeOfficial, showMetricOnly),
	command: (device) => <IWOSCommands device={device} />,
	dashboard: (props) => <IWOSDashboard {...props} />,
	// settings: () => {}, // it's an entire React component so no need to put it here - see containers/WeatherDisplay/SingleDeviceView/Notifications
};

export default IWOS;
