import React, { useContext } from 'react';
import moment from 'moment';
import { LatestDeviceImagesContext } from '__state/LatestDeviceImages';
import { doesWeatherValueExist } from 'utils/functions';
import config from 'utils/config';

import Compass from 'components/Compass';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import OpacityIcon from '@material-ui/icons/Opacity';
import CloudIcon from '@material-ui/icons/Cloud';
import WarningIcon from '@material-ui/icons/Warning';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import Battery60Icon from '@material-ui/icons/Battery60';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';

moment.tz.setDefault('UTC');

const MWSDashboard = ({
	device,
	latestEntry,
	isLightningActive,
	isRainActive,
	isCloudsActive,
	isTamperingActive,
	batteryLevel,
	showMetricOnly,
}) => {
	const { deviceImages } = useContext(LatestDeviceImagesContext);
	const imageBaseURL = config['MWS']['constants']['imageURL'];
	const latestEntryImages = deviceImages[device.imei];
	let latLon;
	let temp;
	let humidity;
	let windSpeed;
	let windDir;
	let maxWindSpeed;
	let maxWindDir;
	let pressure;
	let dew;

	latLon = doesWeatherValueExist(latestEntry.lat) ? (
		`${latestEntry.lat.value}°, ${latestEntry.lon.value}°`
	) : (
		<>&nbsp;</>
	);
	humidity = doesWeatherValueExist(latestEntry.humidity) ? (
		`${latestEntry.humidity.value}%`
	) : (
		<>&nbsp;</>
	);
	windDir = doesWeatherValueExist(latestEntry.windDir) ? (
		`${latestEntry.windDir.value}°`
	) : (
		null
	);
	maxWindDir = doesWeatherValueExist(latestEntry.maxWindDir) ? (
		`${latestEntry.maxWindDir.value}°`
	) : (
		<>&nbsp;</>
	);

	if (showMetricOnly) {
		temp = doesWeatherValueExist(latestEntry.temperature) ? (
			`${latestEntry.temperature.value} °C`
		) : (
			<>&nbsp;</>
		);
		windSpeed = doesWeatherValueExist(latestEntry.windSpeedMps) ? (
			`${latestEntry.windSpeedMps.value} m/s`
		) : (
			<>&nbsp;</>
		);
		maxWindSpeed = doesWeatherValueExist(latestEntry.maxWindSpeedMps) ? (
			`${latestEntry.maxWindSpeedMps.value} m/s`
		) : (
			<>&nbsp;</>
		);
		pressure = doesWeatherValueExist(latestEntry.stationPressureMb) ? (
			`${latestEntry.stationPressureMb.value} mb/hPa`
		) : (
			<>&nbsp;</>
		);
		dew = doesWeatherValueExist(latestEntry.dewPoint) ? (
			`${latestEntry.dewPoint.value} °C`
		) : (
			<>&nbsp;</>
		);
	} else {
		temp = doesWeatherValueExist(latestEntry.temperatureF) ? (
			`${latestEntry.temperatureF.value} °F`
		) : (
			<>&nbsp;</>
		);
		windSpeed = doesWeatherValueExist(latestEntry.maxWindSpeedMph) ? (
			`${latestEntry.windSpeedMph.value} mph`
		) : (
			<>&nbsp;</>
		);
		maxWindSpeed = doesWeatherValueExist(latestEntry.maxWindSpeedMph) ? (
			`${latestEntry.maxWindSpeedMph.value} mph`
		) : (
			<>&nbsp;</>
		);
		pressure = doesWeatherValueExist(latestEntry.stationPressure) ? (
			`${latestEntry.stationPressure.value} inHg`
		) : (
			<>&nbsp;</>
		);
		dew = doesWeatherValueExist(latestEntry.dewPointF) ? (
			`${latestEntry.dewPointF.value} °F`
		) : (
			<>&nbsp;</>
		);
	}

	// we're only asking for a specific timestamp so do not show "all" latest images
	/* const latestImages = latestEntryImages.images.filter((imageName) => {
		let simpleTS = latestEntryImages.timestamp.replace('20', ''); // only the first '20' because the filename has a 2-digit year
		simpleTS = simpleTS.replace('T', '');
		return imageName.includes(simpleTS);
	}); */
	
	const imageTimestamp = (imageName) => {
		let imageTs = '20' + imageName.split('-')[1];
		imageTs = imageTs.slice(0,imageTs.length-4) + 'T' + imageTs.slice(imageTs.length-4);
		imageTs = moment(imageTs).format('YYYY-MM-DD HH:mm:ss');
		return imageTs;
	};
	
	const latestImages = latestEntryImages.images;

	const calculateOrientation = (orientation, multiplier) => {
		const finalO = orientation + multiplier * 90;
		return finalO > 360 ? finalO - 360 : finalO;
	};

	const isValue = (value) => typeof value === 'string';
	return (
		<div className="view-inner" data-with-compass={isValue(windSpeed)}>
			<div className="top-info">
				<div className="left-side">
					<div className="info-box">
						<label>Time (UTC)</label>
						<div className="value">
							{moment(latestEntry.timestamp).format('YYYY-MM-DD HH:mm:ss')}
						</div>
					</div>
					<div className="icon-view">
						<p className="status-title">Status</p>
						<div className="status">
							<div className="status-row">
								<div className="lightning icon-toggle" data-active={isLightningActive}>
									<FlashOnIcon />
									<p className="status-text">Lightning</p>
								</div>
								<div className="rain icon-toggle" data-active={isRainActive}>
									<OpacityIcon />
									<p className="status-text">Rain</p>
								</div>
							</div>
							<div className="status-row">
								<div className="clouds icon-toggle" data-active={isCloudsActive}>
									<CloudIcon />
									<p className="status-text">Clouds</p>
								</div>
								<div className="tampering icon-toggle" data-active={isTamperingActive}>
									<WarningIcon />
									<p className="status-text">Tampering</p>
								</div>
							</div>
							<div className="status-row center">
								<div className="battery" data-level={batteryLevel}>
									{batteryLevel === 'good' && <BatteryFullIcon />}
									{batteryLevel === 'meh' && <Battery60Icon />}
									{batteryLevel === 'bad' && <BatteryAlertIcon />}
									<p className="status-text">Battery</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="right-side">
					<div className="info-box">
						<label>Coordinates</label>
						<div className="value">{latLon}</div>
					</div>
					<div className="sub-grid">
						<div className="info-box">
							<label>Temperature</label>
							<div className="value">{temp}</div>
						</div>
						<div className="info-box">
							<label>Max Wind Speed</label>
							<div className="value">{maxWindSpeed}</div>
						</div>
						<div className="info-box">
							<label>Station Pressure</label>
							<div className="value">{pressure}</div>
						</div>
						<div className="info-box">
							<label>Max Wind Direction</label>
							<div className="value">{maxWindDir}</div>
						</div>
						<div className="info-box">
							<label>Humidity</label>
							<div className="value">{humidity}</div>
						</div>
						<div className="info-box">
							<label>Dew Point</label>
							<div className="value">{dew}</div>
						</div>
					</div>
				</div>
				{isValue(windSpeed) && (
					<div className="compass-side">
						<label>Sustained Winds</label>
						<Compass direction={windDir ? parseInt(windDir) : null} windSpeed={windSpeed} />
					</div>
				)}
			</div>
			{latestImages.length > 0 && (
				<div className="bottom-info">
					<div className="info-box">
						<label>Latest Images (UTC)</label>
						{/* <div className="value">
							{moment(latestEntryImages.timestamp).format('YYYY-MM-DD HH:mm:ss')}
						</div> */}
					</div>
					<div className="latest-images">
						<div className="image-grid">
							{latestImages.map((imageSrc, i) => {
								const orientation = latestEntryImages.initialImgOrientation;

								return (
									<div key={i}>
										<img alt="" src={`${imageBaseURL}/${device.imei}/${imageSrc}`} />
										{orientation && (
											<span className="orientation">
												{`Camera ${i+1} (${calculateOrientation(parseInt(orientation), i)}`}
												&deg;{')'}<br></br>{imageTimestamp(latestImages[i])}
											</span>
										)}
									</div>
								);
							})}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default MWSDashboard;
