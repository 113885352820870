import React, { createContext, useReducer } from 'react';
import { VIEW_TABLE } from 'utils/constants';

const initialState = {
	devicesView: '',
	entriesView: VIEW_TABLE,
};

const actions = {
	SET_DEVICES_VIEW: 'SET_DEVICES_VIEW',
	SET_ENTRIES_VIEW: 'SET_ENTRIES_VIEW',
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.SET_DEVICES_VIEW:
			return { ...state, devicesView: action.view };
		case actions.SET_ENTRIES_VIEW:
			return { ...state, entriesView: action.view };
		default:
			return state;
	}
};

export const ViewChoiceContext = createContext();

const ViewChoiceProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = {
		devicesView: state.devicesView,
		entriesView: state.entriesView,
		setDevicesView: (view) => {
			dispatch({ type: actions.SET_DEVICES_VIEW, view: view });
		},
		setEntriesView: (view) => {
			dispatch({ type: actions.SET_ENTRIES_VIEW, view: view });
		},
	};

	return <ViewChoiceContext.Provider value={value}>{children}</ViewChoiceContext.Provider>;
};

export default ViewChoiceProvider;
