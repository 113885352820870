import React, { useState, useContext } from 'react';
import moment from 'moment';
import { LatestDeviceImagesContext } from '__state/LatestDeviceImages';
import { useDeepEffect } from 'utils/hooks';
import config from 'utils/config';
import { getMETAR, doesWeatherValueExist } from 'utils/functions';
import __find from 'lodash/find';

import './EntriesMobile.scss';

import AddBoxIcon from '@material-ui/icons/AddBox';
import MinusBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CloseIcon from '@material-ui/icons/Close';

const EntriesMobile = ({ deviceType, deviceTypeOfficial, deviceName, entries, showMetricOnly }) => {
	const imageBaseURL = config[deviceType]['constants']['imageURL'];
	const [expandedRow, setExpandedRow] = useState(0);
	const [data1HourAgo, setData1HourAgo] = useState({});
	const [fullImage, setFullImage] = useState('');
	const { deviceImages } = useContext(LatestDeviceImagesContext);

	useDeepEffect(() => {
		if (expandedRow) {
			const data = __find(entries, (entry) => {
				return (
					moment(entry.timestamp).subtract(1, 'hour').valueOf() === entries[expandedRow].timestamp
				);
			});

			setData1HourAgo(data);
		}
	}, [entries, expandedRow]);

	const handleExpandedRow = (rowIndex) => {
		if (expandedRow !== rowIndex) {
			setExpandedRow(rowIndex);
		} else {
			setExpandedRow(null);
		}
	};

	const calculateOrientation = (orientation, multiplier) => {
		const finalO = parseInt(orientation) + multiplier * 90;
		return finalO > 360 ? finalO - 360 : finalO;
	};

	return (
		<>
			<div className="entries-mobile-component">
				{entries.length > 0 ? (
					<div className="entries-inner">
						{entries.map((entry, i) => {
							const orientation =
								deviceImages[entry.imei] && deviceImages[entry.imei]['initialImgOrientation'];
							const isImageEntry =
								doesWeatherValueExist(entry.pict) ||
								doesWeatherValueExist(entry.pict1) ||
								doesWeatherValueExist(entry.pict2) ||
								doesWeatherValueExist(entry.pict3) ||
								doesWeatherValueExist(entry.pict4);

							const isAudioEntry =
								doesWeatherValueExist(entry.spectralMP3) ||
								doesWeatherValueExist(entry.spectralINP) ||
								doesWeatherValueExist(entry.spectralWAV);

							if ((entry.hasOwnProperty("lightningFreq")) || (isImageEntry) || (isAudioEntry)) {
			
							} else {
								entry["lightningFreq"]="";
							}

							if (isImageEntry) {
								return (
									<div key={i} className="entry-images">
										<div className="timestamp">
											{moment(entry.timestamp).format('YYYY-MM-DD HH:mm:ss')}
										</div>
										<div className="links">
											{entry.pict && (
												<button onClick={() => setFullImage(`${imageBaseURL}/${entry.imei}/${entry.pict.value}`)}>
													{calculateOrientation(orientation, 1)}&deg;
												</button>
											)}
											{entry.pict1 && (
												<button
													onClick={() => setFullImage(`${imageBaseURL}/${entry.imei}/${entry.pict1.value}`)}>
													{calculateOrientation(orientation, 1)}&deg;
												</button>
											)}
											{entry.pict2 && (
												<button
													onClick={() => setFullImage(`${imageBaseURL}/${entry.imei}/${entry.pict2.value}`)}>
													{calculateOrientation(orientation, 2)}&deg;
												</button>
											)}
											{entry.pict3 && (
												<button
													onClick={() => setFullImage(`${imageBaseURL}/${entry.imei}/${entry.pict3.value}`)}>
													{calculateOrientation(orientation, 3)}&deg;
												</button>
											)}
											{entry.pict4 && (
												<button
													onClick={() => setFullImage(`${imageBaseURL}/${entry.imei}/${entry.pict4.value}`)}>
													{calculateOrientation(orientation, 4)}&deg;
												</button>
											)}
										</div>
									</div>
								);
							}

							if (isAudioEntry) {
								return (
									<div key={i} className="entry-audio">
										Audio
									</div>
								);
							}

							return (
								<div key={i} className="entry-data" data-expanded={expandedRow === i}>
									<div className="title" role="button" onClick={() => handleExpandedRow(i)}>
										{expandedRow === i ? <MinusBoxIcon /> : <AddBoxIcon />}
										<span>{getMETAR(entry, showMetricOnly)}</span>
									</div>
									<div className="entry-props">
										{config[deviceType]['table']({
											row: entry,
											deviceType: deviceType,
											deviceTypeOfficial: deviceTypeOfficial,
											deviceName: deviceName,
											highlightedKeys: [],
											data1HourAgo: data1HourAgo,
											showMetricOnly,
										})}
									</div>
								</div>
							);
						})}
					</div>
				) : (
					<p>No entries for this device</p>
				)}
			</div>
			{fullImage !== '' && (
				<div className="fullscreen-image">
					<div className="image">
						<img alt="" src={fullImage} />
					</div>
					<div role="button" onClick={() => setFullImage('')}>
						<CloseIcon />
					</div>
				</div>
			)}
		</>
	);
};

export default EntriesMobile;
