// (${showMetricOnly ? 'm/s' : 'kt'})
// (${showMetricOnly ? 'm' : 'SM'})
// (${showMetricOnly ? '°C' : '°F'})
// (${showMetricOnly ? 'mb/hPa' : 'inHg'})
// (NM)
// (ft)
// (°)
// (${showMetricOnly ? 'm' : 'ft'})
// (${showMetricOnly ? 'mm/hr' : 'in/hr'})

export const createMWSCSVHeader = (showMetricOnly) => {
  return '' +
    `IMEI,` +
    `METAR,` +
    `Date/Time (UTC),` +
    `Unit Type,` +
    `DataType,` +
    `Latitude,` +
    `Longitude,` +
    `Elevation (${showMetricOnly ? 'm' : 'ft'}),` +
    `Tilt (°),` +
    `Orientation (°),` +
    `Wind Direction (°),` +
    `Wind Speed (${showMetricOnly ? 'm/s' : 'kt'}),` +
    `Peak Wind Direction (°),` +
    `Peak Wind Speed (${showMetricOnly ? 'm/s' : 'kt'}),` +
    `Visibility (${showMetricOnly ? 'm' : 'SM'}),` +
    `Weather,` +
    `Cloud Layers (ft),` +
    `Temperature (${showMetricOnly ? '°C' : '°F'}),` +
    `DewPoint (${showMetricOnly ? '°C' : '°F'}),` +
    `Relative Humidity (%),` +
    `Altimeter (${showMetricOnly ? 'mb/hPa' : 'inHg'}),` +
    `Precipitation (${showMetricOnly ? 'mm/hr' : 'in/hr'}),` +
    `Ceilometer Hits,` +
    `Wind Chill (${showMetricOnly ? '°C' : '°F'}),` +
    `Heat Index (${showMetricOnly ? '°C' : '°F'}),` +
    `Lightning Frequency,` +
    `Lightning Distance (NM),` +
    `Voltage (V),` +
    `Mode,` +
    `Station Pressure (${showMetricOnly ? 'mb/hPa' : 'inHg'}),` +
    `Pressure Altitude (${showMetricOnly ? 'm' : 'ft'}),` +
    `Density Altitude (${showMetricOnly ? 'm' : 'ft'}),` +
    `Sedimentatary Interference,` +
    `Full Packet` +
    '\n'
  ;
};