import React, { useState, useEffect } from 'react';
import wretch from 'wretch';
import __isEmpty from 'lodash/isEmpty';

const NotificationsView = ({ device, showMetricOnly }) => {
	const { username, token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [notifications, setNotifications] = useState([]);

	// get the saved notification settings
	useEffect(() => {
		const getUserNotificationsSettings = async () => {
			try {
				const loadedNotifications = await wretch(
					`${process.env.REACT_APP_UTIL_API_URL}/user/notifications/device`,
				)
					.auth(`Bearer ${token}`)
					.post({
						username,
						imei: device.imei,
					})
					.json();

				setNotifications([...loadedNotifications]);
			} catch (error) {
				console.log(error);

				if (error.text && JSON.parse(error.text).type === 'token') {
					alert('Your session has expired. Please log in again.');
					return;
				}

				alert(
					'Something went wrong getting your notification settings. Please try again or contact us.',
				);
			}
		};

		getUserNotificationsSettings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [device.imei, username]);

	const saveNotifications = async (e) => {
		e.preventDefault();

		try {
			await wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/notifications/save`)
				.auth(`Bearer ${token}`)
				.post({
					username,
					imei: device.imei,
					settings: notifications,
				})
				.json();

			alert('Your settings have been saved.');
		} catch (error) {
			console.log(error);

			if (error.text && JSON.parse(error.text).type === 'token') {
				alert('Your session has expired. Please log in again.');
				return;
			}

			alert(
				'Something went wrong saving your notification settings. Please try again or contact us.',
			);
		}
	};

	const toggleNotification = (index, shouldNotify) => {
		let newNotifications = notifications;
		newNotifications[index].notify = shouldNotify;
		setNotifications([...newNotifications]);
	};

	const saveInput = (index, key, value) => {
		let newNotifications = notifications;
		newNotifications[index][`${key}`] = value;
		setNotifications([...newNotifications]);
	};

	return (
		<div className="notifications-view">
			<div className="notifications-wrapper">
				<div className="save-section">
					<span>Notifications</span>
					<input type="submit" value="Save All" onClick={saveNotifications} />
				</div>
				<div className="notifications-table">
					<div className="table-header table-row">
						<div className="table-header-cell">Parameter</div>
						<div className="table-header-cell">Notifications</div>
						<div className="table-header-cell">&gt;</div>
						<div className="table-header-cell">&lt;</div>
						<div className="table-header-cell">Units</div>
					</div>
					{notifications.length > 0 &&
						notifications.map(({ title, units, notify, lessThan, greaterThan, savedUnit }, i) => {
							// metric units are always last (from API)
							const defaultUnit = showMetricOnly ? units[units.length - 1] : units[0];

							// use default unit if the value has never been saved
							const isValueSaved = lessThan || greaterThan;

							return (
								<div key={i} className="table-row">
									<div className="table-body-cell">{title}</div>
									<div className="table-body-cell">
										<button
											className={notify ? 'on' : ''}
											onClick={() => toggleNotification(i, !notify)}>
											{notify ? 'ON' : 'OFF'}
										</button>
									</div>
									<div className="table-body-cell with-input">
										{title !== 'Tamper' && (
											<input
												id={`greaterThan-${device.imei}`}
												type="number"
												placeholder=">"
												value={greaterThan ? greaterThan : ''}
												onChange={(e) => saveInput(i, 'greaterThan', e.target.value)}
												disabled={!notify}
											/>
										)}
									</div>
									<div className="table-body-cell with-input">
										{title !== 'Tamper' && (
											<input
												id={`lessThan-${device.imei}`}
												type="number"
												placeholder="<"
												value={lessThan ? lessThan : ''}
												onChange={(e) => saveInput(i, 'lessThan', e.target.value)}
												disabled={!notify}
											/>
										)}
									</div>
									<div className="table-body-cell with-input">
										{!__isEmpty(savedUnit) && title !== 'Tamper' && (
											<select
												id={`savedUnit-${device.imei}`}
												value={isValueSaved ? savedUnit : defaultUnit}
												onChange={(e) => saveInput(i, 'savedUnit', e.target.value)}
												disabled={!notify}>
												{units.map((unit, k) => (
													<option key={k} value={unit}>
														{unit}
													</option>
												))}
											</select>
										)}
									</div>
								</div>
							);
						})}
				</div>
				<div className="save-section">
					<input type="submit" value="Save All" onClick={saveNotifications} />
				</div>
			</div>
		</div>
	);
};

export default NotificationsView;
