import React, { useState } from 'react';
import { Link } from '@curi/react-dom';

import './Register.scss';

import Logo from '__assets/img/qlogo-color.svg';

const Register = () => {
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [company, setCompany] = useState('');
	const [isFormLoading] = useState(false);

	const submitRegistration = (e) => {
		e.preventDefault();

		// TODO: make API call to register -- FUTURE

		const toEmail = 'quantimet@intellisenseinc.com';
		const subject = 'Registration Request - IntelliSense Weather Display';
		const body = `Name: ${firstName} ${lastName}\nEmail: ${email}\nCompany: ${company}\n`;
		window.open(`mailto:${toEmail}?subject=${subject}&body=${body}`);
	};

	return (
		<div className="reg-wrapper">
			<div className="reg-form-container">
				<div className="form-logo">
					<img alt="IntelliSense Systems" src={Logo} />
				</div>
				<form className="reg-form" onSubmit={submitRegistration}>
					<div className="form-row">
						<label htmlFor="reg-email">Email:</label>
						<input
							required
							id="reg-email"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="form-row">
						<label htmlFor="reg-fname">First Name:</label>
						<input
							required
							id="reg-fname"
							type="text"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
					</div>
					<div className="form-row">
						<label htmlFor="reg-lname">Last Name:</label>
						<input
							required
							id="reg-lname"
							type="text"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</div>
					<div className="form-row">
						<label htmlFor="reg-company">Company:</label>
						<input
							required
							id="reg-company"
							type="text"
							value={company}
							onChange={(e) => setCompany(e.target.value)}
						/>
					</div>
					<div className="form-row multi-button">
						<Link name="Login">Cancel</Link>
						<input type="submit" value="Send Request" disabled={isFormLoading} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default Register;
