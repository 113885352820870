import React, { createContext, useReducer } from 'react';

const initialState = {
	showFirstEntriesPage: false,
};

const actions = {
	SET_SHOW_FIRST_PAGE: 'SET_SHOW_FIRST_PAGE',
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.SET_SHOW_FIRST_PAGE:
			return { ...state, showFirstEntriesPage: action.showFirstEntriesPage };
		default:
			return state;
	}
};

export const PaginationContext = createContext();

const PaginationProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = {
		showFirstEntriesPage: state.showFirstEntriesPage,
		setShowFirstEntriesPage: (showFirstEntriesPage) => {
			dispatch({
				type: actions.SET_SHOW_FIRST_PAGE,
				showFirstEntriesPage: showFirstEntriesPage,
			});
		},
	};

	return <PaginationContext.Provider value={value}>{children}</PaginationContext.Provider>;
};

export default PaginationProvider;
