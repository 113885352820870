import React from 'react';

import MWSTable from './data/Table';
import MWSDashboard from './data/Dashboard';
import MWSCommands from './data/Commands';
import returnMWSPlots from './data/Plots';
import returnMWSExport from './data/Export';

const mws = {
	constants: {
		imageURL: process.env.REACT_APP_MWS_IMAGE_URL,
	},
	table: (props) => <MWSTable {...props} />,
	plots: (entries, showMetricOnly) => returnMWSPlots(entries, showMetricOnly),
	export: (entries, deviceTypeOfficial, showMetricOnly) => returnMWSExport(entries, deviceTypeOfficial, showMetricOnly),
	command: (device) => <MWSCommands device={device} />,
	dashboard: (props) => <MWSDashboard {...props} />, 
	// settings: () => {}, // it's an entire React component so no need to put it here - see containers/WeatherDisplay/SingleDeviceView/Notifications
};

export default mws;
