import React from 'react';
import moment from 'moment-timezone';
import __isEmpty from 'lodash/isEmpty';
import {
	isTampered,
	createTableRowValue,
	getWeather,
	setValueColor,
	formatCloudLayersValue,
  formatCloudLayersCoverage,
	renderAudioLinks,
} from 'utils/functions';

moment.tz.setDefault('UTC');

const IWOSTable = ({ row, deviceTypeOfficial, deviceName, highlightedKeys, data1HourAgo, showMetricOnly }) => (
  <div className="device-data-grid">
    {!row.pict &&
      !row.pict1 &&
      !row.pict2 &&
      !row.pict3 &&
      !row.pict4 &&
      !row.spectralINP &&
      !row.spectralMP3 &&
      !row.spectralWAV && (
        <div className="grid-sections">
          <div className="data-inner">
            <ul className="data-titles">
              <li className={highlightedKeys.includes('timestamp') ? 'highlight' : ''}>
                <span>Date/Time (UTC):</span>
              </li>
              <li className={highlightedKeys.includes('windDir2min') ? 'highlight' : ''}>
                <span>Wind Direction:</span>
              </li>
              <li className={highlightedKeys.includes('windSpeed2minKt') ? 'highlight' : ''}>
                <span>Wind Speed:</span>
              </li>
              <li className={highlightedKeys.includes('maxWindDir') ? 'highlight' : ''}>
                <span>Peak Wind Direction:</span>
              </li>
              <li className={highlightedKeys.includes('maxWindSpeed') ? 'highlight' : ''}>
                <span>Peak Wind Speed:</span>
              </li>
              <li>&nbsp;</li>
              <li className={highlightedKeys.includes('cloudLayers') ? 'highlight' : ''}>
                <span>Cloud Layers:</span>
              </li>
              <li className={highlightedKeys.includes('lowestCloud') ? 'highlight' : ''}>
                <span>Cloud Ceiling:</span>
              </li>
              <li className={highlightedKeys.includes('lightningFreq') ? 'highlight' : ''}>
                <span>Lightning Freq.:</span>
              </li>
              <li className={highlightedKeys.includes('lightningDist') ? 'highlight' : ''}>
                <span>Lightning Dist.:</span>
              </li>
              <li>&nbsp;</li>
              <li className={highlightedKeys.includes('sigWaveDir') ? 'highlight' : ''}>
                <span>Sig Wave Direction:</span>
              </li>
              <li className={highlightedKeys.includes('sigWavePrd') ? 'highlight' : ''}>
                <span>Sig Wave Period:</span>
              </li>
              <li className={highlightedKeys.includes('sigWaveHgt') ? 'highlight' : ''}>
                <span>Sig Wave Height:</span>
              </li>
              <li className={highlightedKeys.includes('seaTemp') ? 'highlight' : ''}>
                <span>SST:</span>
              </li>
              {/* <li className={highlightedKeys.includes('compass') ? 'highlight' : ''}>
                <span>Compass Heading:</span>
              </li> */}
            </ul>
            <ul className="data-values">
              <li>
                <span>{moment(row.timestamp).format('YYYY-MM-DD HH:mm:ss')}</span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.windDir2min, units: '°', show: true }])}{' '}
                  {row.windDirCard && row.windDirCard.value}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    { rowObj: row.windSpeed2minKt, units: ' kt', show: true },
                    {
                      rowObj: row.windSpeed2minMph,
                      units: ' mph',
                      show: !showMetricOnly,
                    },
                    {
                      rowObj: row.windSpeed2minMs,
                      units: ' m/s',
                      show: true,
                    },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.maxWindDir, units: '°', show: true }])}{' '}
                  {row.maxWindDirCard && row.maxWindDirCard.value}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    { rowObj: row.maxWindSpeed, units: ' kt', show: true },
                    {
                      rowObj: row.maxWindSpeedMph,
                      units: ' mph',
                      show: !showMetricOnly,
                    },
                    {
                      rowObj: row.maxWindSpeedMps,
                      units: ' m/s',
                      show: true,
                    },
                  ])}
                </span>
              </li>
              <li>&nbsp;</li>
              <li>
                <span>{formatCloudLayersCoverage(row.cloudLayers,row.cloudCoverage)}</span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.lowestCloud,
                      units: ' ft',
                      show: true,
                      key: 'lowestCloud',
                    },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.lightningFreq, units: '', show: true }])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.lightningFreq.value == '0' ? { value: '' } : row.lightningDist,
                      units: row.lightningFreq == '0' ? '' : ' NM',
                      show: true,
                    }, // special rule
                  ])}
                </span>
              </li>
              <li>&nbsp;</li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.sigWaveDir, units: '°', show: true }])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.sigWavePrd, units: ' sec', show: true }])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.sigWaveHgtFt,
                      units: ' ft',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.sigWaveHgt, units: ' m', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    { rowObj: row.seaTempF, units: ' °F', show: !showMetricOnly },
                    { rowObj: row.seaTemp, units: ' °C', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.compass, units: '°', show: false }])}
                </span>
              </li>

              
              
              
              
              
              
              
              
              
              
            </ul>
          </div>
          <div className="data-inner">
            <ul className="data-titles">
              <li className={highlightedKeys.includes('temperature') ? 'highlight' : ''}>
                <span>Temperature:</span>
              </li>
              <li className={highlightedKeys.includes('dewPoint') ? 'highlight' : ''}>
                <span>Dew Point:</span>
              </li>
              <li className={highlightedKeys.includes('humidity') ? 'highlight' : ''}>
                <span>Relative Humidity:</span>
              </li>
              <li className={highlightedKeys.includes('windChillIndex') ? 'highlight' : ''}>
                <span>Wind Chill Index:</span>
              </li>
              <li className={highlightedKeys.includes('heatIndex') ? 'highlight' : ''}>
                <span>Heat Index:</span>
              </li>
              <li>&nbsp;</li>
              <li className={highlightedKeys.includes('visibility') ? 'highlight' : ''}>
                <span>Visibility:</span>
              </li>
              <li className={highlightedKeys.includes('weather') ? 'highlight' : ''}>
                <span>Present Weather:</span>
              </li>
              <li className={highlightedKeys.includes('precipAccumlation') ? 'highlight' : ''}>
                <span>Precip Accumulation (1hr):</span>
              </li>
              <li className={highlightedKeys.includes('CT2Counts') ? 'highlight' : ''}>
                <span>CT2 Counts (100Hz):</span>
              </li>
              <li className={highlightedKeys.includes('CT2Reading') ? 'highlight' : ''}>
                <span>CT2 Reading (@ 1 Meter):</span>
              </li>
              <li className={highlightedKeys.includes('altimeter') ? 'highlight' : ''}>
                <span>Altimeter:</span>
              </li>
              <li className={highlightedKeys.includes('stationPressure') ? 'highlight' : ''}>
                <span>Station Pressure:</span>
              </li>
              <li className={highlightedKeys.includes('pressureAlt') ? 'highlight' : ''}>
                <span>Pressure Altitude:</span>
              </li>
              <li className={highlightedKeys.includes('denseAlt') ? 'highlight' : ''}>
                <span>Density Altitude:</span>
              </li>
            </ul>
            <ul className="data-values">
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.temperatureF,
                      units: ' °F',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.temperatureC, units: ' °C', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.dewPointF,
                      units: ' °F',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.dewPoint, units: ' °C', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.humidity, units: '%', show: true }])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.windChillIndexF,
                      units: ' °F',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.windChillIndex, units: ' °C', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.heatIndexF,
                      units: ' °F',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.heatIndex, units: ' °C', show: true },
                  ])}
                </span>
              </li>
              <li>&nbsp;</li>
              <li>
                <span>
                  {createTableRowValue([
                    { rowObj: row.visibility, units: '', show: !showMetricOnly },
                    { rowObj: row.visibilityMeters, units: ' m', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>{getWeather(row.weather)}</span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    { rowObj: row.precipAccumlation, units: '', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    { rowObj: row.CT2Counts, units: 'counts', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    { rowObj: row.CT2Reading, units: 'C^2/m^(2/3)', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.altimeter,
                      units: ' inHg',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.altimeterMb, units: ' mb/hPa', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: __isEmpty(row.haPressureInHg)
                        ? row.stationPressure
                        : row.haPressureInHg,
                      units: ' inHg',
                      show: !showMetricOnly,
                    },
                    {
                      rowObj: __isEmpty(row.haPressure) ? row.stationPressure : row.haPressure,
                      units: ' mb/hPa',
                      show: true,
                    },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.pressureAltFt,
                      units: ' ft',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.pressureAlt, units: ' m', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.denseAltFt,
                      units: ' ft',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.denseAlt, units: ' m', show: true },
                  ])}
                </span>
              </li>
            </ul>
          </div>
          <div className="data-inner">
            <ul className="data-titles">
              <li className={highlightedKeys.includes('lon') ? 'highlight' : ''}>
                <span>Latitude:</span>
              </li>
              <li className={highlightedKeys.includes('lat') ? 'highlight' : ''}>
                <span>Longitude:</span>
              </li>
              <li className={highlightedKeys.includes('elevation') ? 'highlight' : ''}>
                <span>Elevation:</span>
              </li>
              <li>&nbsp;</li>
              <li className="section-title system"></li>
              <li>&nbsp;</li>
              <li>Alias:</li>
              <li>Type:</li>
              <li className={highlightedKeys.includes('tip') ? 'highlight' : ''}>
                <span>Tilt:</span>
              </li>
              <li className={highlightedKeys.includes('orientation') ? 'highlight' : ''}>
                <span>Orientation:</span>
              </li>
              <li className={highlightedKeys.includes('battery') ? 'highlight' : ''}>
                <span>Voltage:</span>
              </li>
              <li className={highlightedKeys.includes('mode') ? 'highlight' : ''}>
                <span>Mode:</span>
              </li>
              <li className={highlightedKeys.includes('sediment') ? 'highlight' : ''}>
                <span>Vis Contamination:</span>
              </li>
              <li className={highlightedKeys.includes('tamper') ? 'highlight' : ''}>
                <span>Tampering:</span>
              </li>
            </ul>
            <ul className="data-values">
              <li>
                <span>{createTableRowValue([{ rowObj: row.lat, units: '°', show: true }])}</span>
              </li>
              <li>
                <span>{createTableRowValue([{ rowObj: row.lon, units: '°', show: true }])}</span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.elevationFt,
                      units: ' ft',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.elevation, units: ' m', show: true },
                  ])}
                </span>
              </li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>
                <span>{deviceName || <>&nbsp;</>}</span>
              </li>
              <li>
                <span>{deviceTypeOfficial}</span>
              </li>
              <li>
                <span>{createTableRowValue([{ rowObj: row.tip, units: '°', show: true }])}</span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.orientation, units: '°', show: true }])}{' '}
                  {row.orientationCard && row.orientationCard.value}
                </span>
              </li>
              <li>
                <span className={setValueColor(row, data1HourAgo, 'mws-voltage', row.battery)}>
                  {createTableRowValue([{ rowObj: row.battery, units: ' V', show: true }])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.mode, units: ' minute', show: true }])}
                </span>
              </li>
              <li>
                <span className={setValueColor(row, data1HourAgo, 'sediment', row.sediment)}>
                  {createTableRowValue([{ rowObj: row.sediment, units: '', show: true }])}
                </span>
              </li>
              <li>
                <span className={setValueColor(row, data1HourAgo, 'tampering', row.battery)}>
                  {isTampered(row, data1HourAgo) ? 'Yes' : 'No'}
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
    {renderAudioLinks(row)}
  </div>
);

export default IWOSTable;
