import React, { useState, useEffect, useContext } from 'react';
import { Link } from '@curi/react-dom';
import wretch from 'wretch';
import moment from 'moment';
import { NotificationsContext } from '__state/Notifications';
import { ACCT_PLAN_LEVELS } from 'utils/constants';

import './HeaderDefault.scss';

import LogoWhite from '__assets/img/qlogo-white.svg';
import Clock from 'react-live-clock';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const HeaderDefault = ({ router, setIsLoggingOut }) => {
	const { username, firstName, planLevel, token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [showOptions, setShowOptions] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);
	const { notifications, setNotifications } = useContext(NotificationsContext);

	// desktop only
	useEffect(() => {
		const getUserNotifications = async () => {
			try {
				const loadedNotifications = await wretch(
					`${process.env.REACT_APP_UTIL_API_URL}/user/notifications/${username}`,
				)
					.auth(`Bearer ${token}`)
					.get()
					.json();

				loadedNotifications.sort((a, b) => {
					return moment(b.timestamp) - moment(a.timestamp);
				});
				setNotifications([...loadedNotifications]);
			} catch (error) {
				console.log(error);

				if (error.text && JSON.parse(error.text).type === 'token') {
					alert('Your session has expired. Please log in again.');
					return;
				}

				console.error("Can't get notifications.");
			}
		};

		getUserNotifications();

		setInterval(() => {
			getUserNotifications();
		}, 1000 * 60 * 5); // 5 minutes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username]);

	const toggleSettings = () => {
		let show = showOptions;
		setShowOptions(!show);
	};

	const logout = () => {
		if (setIsLoggingOut) {
			setIsLoggingOut(true);
		}

		sessionStorage.removeItem('selfData');

		const url = router.url({ name: 'Login' });
		router.navigate({ url });
	};

	if (window.innerWidth < 768) {
		return (
			<div className="header-default-component">
				<div className="header-left">
					<img alt="IntelliSense Systems" src={LogoWhite} />
				</div>
				<div className="header-right">
					<div className="time-notify">
						<div className="time-wrapper">
							<Clock format={'DD MMM YYYY HH:mm:ss'} timezone={'UTC'} ticking={true} />
							<span>&nbsp;UTC</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="header-default-component">
			<div className="header-left">
				<img alt="IntelliSense Systems" src={LogoWhite} />
			</div>
			<div className="header-right">
				<div className="time-notify" data-plan-basic={planLevel === ACCT_PLAN_LEVELS.BASIC}>
					<div className="time-wrapper">
						<Clock format={'ddd, DD MMM YYYY HH:mm:ss'} timezone={'UTC'} ticking={true} />
						<span>&nbsp;UTC</span>
					</div>
					{planLevel !== ACCT_PLAN_LEVELS.BASIC && (
						<div className="notifications">
							<div
								className="click-wrapper"
								role="button"
								onClick={() => setShowNotifications(true)}>
								<NotificationsIcon />
							</div>
							{notifications.length > 0 && (
								<div className="notifications-count">
									{notifications.length > 99 ? '99+' : notifications.length}
								</div>
							)}
						</div>
					)}
					{showNotifications && (
						<ClickAwayListener onClickAway={() => setShowNotifications(false)}>
							<Paper className="notify-popup">
								<div className="list-wrapper">
									<List dense={true}>
										{notifications
											.slice(0, 9)
											.map(({ imei, rule, actual, timestamp, threshold, title, unit }, i) => {
												let statement = '';
												const unitText = unit === 'null' ? '' : ` ${unit}`;
												const secondary = moment(timestamp).format('YYYY-MM-DD HH:mm:ss');

												if (title.toLowerCase() === 'tamper') {
													statement = `${imei} was tampered`;
												} else {
													statement = `${imei} was ${rule} ${threshold}${unitText} for ${title.toLowerCase()}, reaching ${actual.replace(
														/SM/gi,
														'',
													)}${unitText}.`;
												}

												return (
													<div key={i}>
														<ListItem>
															<ListItemText primary={statement} secondary={secondary} />
														</ListItem>
														{i < 8 && <Divider />}
													</div>
												);
											})}
									</List>
									<div className="manage-all">
										<Link name="Notifications">Manage notifications</Link>
									</div>
								</div>
							</Paper>
						</ClickAwayListener>
					)}
				</div>
				<div className="options" onClick={toggleSettings}>
					<span>{firstName || 'User'}</span>
					<div className="header-icon-bg">
						<PersonOutlineIcon />
					</div>
					<span className="header-arrow">
						{showOptions ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
					</span>
				</div>
			</div>
			<div className={`options-container ${showOptions ? 'show' : ''}`}>
				<Link name="MyAccount">
					<div role="button" className="user-option my-account">
						<span>My Account</span>
						<div className="header-icon-bg">
							<SettingsIcon />
						</div>
					</div>
				</Link>
				<Link name="Help">
					<div role="button" className="user-option help">
						<span>Help</span>
						<div className="help-icon">
							<HelpIcon />
						</div>
					</div>
				</Link>
				<div role="button" className="user-option" onClick={logout}>
					<span>Sign Out</span>
					<div className="header-icon-bg">
						<ExitToAppIcon />
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeaderDefault;
