import React, { useState, useEffect, useContext, Suspense } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';

import { LargeGraphViewContext } from '__state/LargeGraphView';
import { useDevices } from 'utils/hooks';
import __isEmpty from 'lodash/isEmpty';

import './WeatherDisplay.scss';

import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import HeaderDefault from 'components/HeaderDefault';
import DeviceFilterView from 'containers/WeatherDisplay/DeviceFilterView';
import Snackbar from '@material-ui/core/Snackbar';

moment.tz.setDefault('UTC');

//because we don't need these components for first paint
const SingleDeviceView = React.lazy(() => import('containers/WeatherDisplay/SingleDeviceView'));
const LargeGraphView = React.lazy(() => import('containers/WeatherDisplay/LargeGraphView'));

const WeatherDisplay = ({ google, detectValidToken, router }) => {
	let mapRef;
	const selfData = JSON.parse(sessionStorage.getItem('selfData')),
		{ metricValOn } = selfData;
	const [selectedDevice, setSelectedDevice] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isLoggingOut, setIsLoggingOut] = useState(false);
	const [markerHovered, setMarkerHovered] = useState(null);
	const [isResettingDevices, setIsResettingDevices] = useState(false);

	const { graphToShow } = useContext(LargeGraphViewContext);

	const { devices, setDevices, totalDeviceCount } = useDevices(
		isLoggingOut,
		setIsLoading,
		detectValidToken,
	);

	useEffect(() => {
		setMarkerHovered(null);

		if (devices.length === 0 && !isLoading) {
			setIsLoading(true);
		}
	}, [devices, isLoading]);

	useEffect(() => {
		// on first load
		if (
			__isEmpty(selectedDevice) ||
			__isEmpty(selectedDevice.lat[0]) ||
			isNaN(selectedDevice.lat[0].value)
		) {
			const zoom = window.innerWidth > 1920 ? 4 : 2;
			mapRef.map.setZoom(zoom);
			return;
		}

		// where to zoom the map based on device selected
		const pasredLat = parseFloat(selectedDevice.lat[0].value);
		const parsedLon = parseFloat(selectedDevice.lon[0].value);
		if (!isNaN(pasredLat) && !isNaN(parsedLon)) {
			mapRef.map.setCenter({ lat: pasredLat, lng: parsedLon });
			mapRef.map.setZoom(16);
		}
	}, [mapRef, selectedDevice]);

	const handleMarkerHover = (marker, imei) => {
		if (!markerHovered || imei !== markerHovered.imei) {
			setMarkerHovered({ marker: marker, imei: imei });
		}
	};

	return (
		<div className="weather-display-wrapper">
			<HeaderDefault router={router} setIsLoggingOut={setIsLoggingOut} />
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={isLoading}
				classes={{ root: 'loading-indicator' }}
				message="Loading Data..."
			/>
			<Map
				ref={(map) => (mapRef = map)}
				className="map-container"
				google={google}
				initialCenter={{ lat: 10.4806, lng: -66.9036 }}
				disableDefaultUI={true}
				minZoom={2}
				maxZoom={20}>
				{devices.map((device, i) => {
					if (!device.lat[0].value) {
						return null;
					}

					return (
						<Marker
							key={i}
							position={{
								lat: parseFloat(device.lat[0].value),
								lng: parseFloat(device.lon[0].value),
							}}
							icon={`https://maps.google.com/mapfiles/ms/icons/${device.color}-dot.png`}
							onClick={() => setSelectedDevice(device)}
							onMouseover={(props, marker, e) => handleMarkerHover(marker, device.imei)}
							optimized={true}></Marker>
					);
				})}
				<InfoWindow
					visible={markerHovered !== null}
					marker={markerHovered ? markerHovered.marker : null}
					pixelOffset={new google.maps.Size(1, 1)}>
					<div>{markerHovered ? markerHovered.imei : null}</div>
				</InfoWindow>
			</Map>
			<DeviceFilterView
				devices={devices}
				setDevices={setDevices}
				totalDeviceCount={totalDeviceCount}
				selectedDevice={selectedDevice}
				setSelectedDevice={setSelectedDevice}
				setIsResettingDevices={setIsResettingDevices}
			/>
			<Suspense fallback={<div></div>}>
				<LargeGraphView isOpen={graphToShow !== ''} showMetricOnly={metricValOn} />
				{!__isEmpty(selectedDevice) && (
					<SingleDeviceView
						device={selectedDevice}
						areEntriesLoading={isLoading}
						setAreEntriesLoading={setIsLoading}
						detectValidToken={detectValidToken}
						isResettingDevices={isResettingDevices}
						setIsResettingDevices={setIsResettingDevices}
					/>
				)}
			</Suspense>
		</div>
	);
};

WeatherDisplay.propTypes = {
	router: PropTypes.object.isRequired,
	response: PropTypes.object.isRequired,
};

export default GoogleApiWrapper({
	//This API key is tied to the gmail account d27software@gmail.com
	apiKey: 'AIzaSyAID4xqesYkpxo4nQDMvWb2AtVZxHfwVOE',
})(WeatherDisplay);
