import React, { useState, useMemo, useEffect, useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PaginationContext } from '__state/Pagination';
import { DataWindowContext } from '__state/DataWindowCollapse';
import { DevicesContext } from '__state/Devices';
import { DeviceEntriesContext } from '__state/DeviceEntries';
import __filter from 'lodash/filter';
import __isEmpty from 'lodash/isEmpty';
import __isEqual from 'lodash/isEqual';

import './DeviceFilterView.scss';

import Table from 'components/Table';
import RefreshIcon from '@material-ui/icons/Refresh';

const DeviceFilterView = ({
	devices,
	setDevices,
	selectedDevice,
	setSelectedDevice,
	totalDeviceCount,
	setIsResettingDevices,
}) => {
	const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(-1);
	const [searchedDevicesByText, setSearchedDevicesByText] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [sortedCols, setSortedCols] = useState([{ id: 'latestWeather', desc: true }]);
	const { setShowFirstEntriesPage } = useContext(PaginationContext);
	const { devicesCollapsed, entriesCollapsed, setDevicesCollapsed, setEntriesCollapsed } =
		useContext(DataWindowContext);
	const { resetCachedDevices } = useContext(DevicesContext);
	const { resetDeviceEntries } = useContext(DeviceEntriesContext);
	const columns = useMemo(
		() => [
			{
				id: `table-${new Date().getUTCMilliseconds()}`,
				Header: null,
				columns: [
					{
						Header: 'IMEI | Alias',
						accessor: (row) => {
							return `${row.imei}\n${row.name ? `(${row.name})` : ''}`;
						},
					},
					{
						Header: 'Latest Weather',
						id: 'latestWeather',
						accessor: (row) => {
							if ((moment(row.latestEntryTS, 'YYYY-MM-DD HH:mm:ss', true).isValid()) || (row.latestEntryTS == undefined)) {
								return row.latestEntryTS || 'N/A';
							} else {
								return moment(row.latestEntryTS).format('YYYY-MM-DD HH:mm:ss') || 'N/A';
							}
						},
					},
					{
						Header: 'Type',
						accessor: 'type',
					},
				],
			},
		],
		[],
	);

	//search by device's IMEI and name only
	useEffect(() => {
		if (__isEmpty(searchText)) {
			setSearchedDevicesByText(devices);
		} else {
			const searchedDevices = __filter(devices, (device) => {
				const text = searchText.toLowerCase();

				return (
					device.imei.toLowerCase().includes(text) ||
					(device.name && device.name.toLowerCase().includes(text))
				);
			});

			setSearchedDevicesByText(searchedDevices);
		}
	}, [devices, searchText]);

	//if search is used, find the selected device's index to fix the highlight
	//since the devices order has changed
	useEffect(() => {
		const index = searchedDevicesByText.findIndex((device) => {
			return __isEqual(device, selectedDevice);
		});

		setSelectedDeviceIndex(index);
	}, [searchedDevicesByText, selectedDevice, setShowFirstEntriesPage]);

	// toggle the entries window if the devices window opens
	// tablet only
	useEffect(() => {
		if (window.outerWidth < 1440 && !entriesCollapsed && !devicesCollapsed) {
			setDevicesCollapsed(true);
		}
	}, [devicesCollapsed, entriesCollapsed, setDevicesCollapsed]);

	const data = useMemo(() => searchedDevicesByText, [searchedDevicesByText]);

	const toggleCollapsed = () => {
		let collapsed = devicesCollapsed;
		setDevicesCollapsed(!collapsed);
		setEntriesCollapsed(true);
	};

	const handleRowClick = (row) => {
		setSelectedDeviceIndex(row.index);
		setSelectedDevice(searchedDevicesByText[row.index]);
		setShowFirstEntriesPage(true);
	};

	const handleHeaderClick = (header) => {
		setSortedCols([
			{
				id: header.id,
				desc: header.isSortedDesc ? false : true,
			},
		]);
	};

	const handleReset = () => {
		setEntriesCollapsed(true);
		setIsResettingDevices(true);

		Promise.all(
			devices.map(async (device) => {
				await resetDeviceEntries(device.id);
			}),
		);

		// reset the devices
		resetCachedDevices();
		setDevices([]); // triggers useEffect in WeatherDisplay/index.js line 61
		setSelectedDevice({});
	};

	return (
		<div className="device-filter-wrapper" data-collapsed={devicesCollapsed}>
			<div className="collapse-tab" role="button" onClick={toggleCollapsed}>
				<div className="triangle"></div>
			</div>
			<div className="name-tab">Devices</div>
			<div className="filter-inner inner-base">
				<div className="collapse-header">
					<span>Devices</span>
					<div
						className="refresh-data-button"
						role="button"
						onClick={handleReset}
						title="Refresh the entries">
						<RefreshIcon />
					</div>
				</div>
				<div className="filter-controls">
					<div className="search-devices">
						<input
							id="entry-search"
							type="text"
							value={searchText}
							placeholder="Search by IMEI or Alias"
							onChange={(e) => setSearchText(e.target.value)}
						/>
					</div>
				</div>
				<div className="filter-table table-wrapper">
					<Table
						columns={columns}
						data={data}
						sortedCols={sortedCols}
						selectedPrimaryRow={selectedDeviceIndex}
						selectedPageSize={1000}
						getRowProps={(row) => ({
							onClick: () => handleRowClick(row),
						})}
						getHeaderProps={(header) => ({
							onClick: () => handleHeaderClick(header),
						})}
					/>
					<div className="table-pagination-wrapper">
						<div className="table-page-info">
							Showing {searchedDevicesByText.length} of {totalDeviceCount} total devices
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

DeviceFilterView.propTypes = {
	devices: PropTypes.array.isRequired,
	totalDeviceCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	selectedDevice: PropTypes.object.isRequired,
	setSelectedDevice: PropTypes.func.isRequired,
};

export default DeviceFilterView;
