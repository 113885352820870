/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@curi/react-dom';
import { USER_ROLES, ACCT_PLAN_LEVELS } from 'utils/constants.js';

import './MyAccount.scss';

import HeaderDefault from 'components/HeaderDefault';

import MyInformation from './MyInformation';
import MyDevices from './MyDevices';
import AccountInfo from './AccountInfo';
import GroupInfo from './GroupInfo';
import Notifications from './Notifications';
import Settings from './Settings';

const MY_INFO = 'My Information';
const ACCT_INFO = 'Account Information';
const GROUP_INFO = 'Group Information';
const MY_DEVICES = 'My Devices';
const NOTIFICATIONS = 'App Notifications';
const SETTINGS = 'Settings';

const MyAccount = ({ router, response }) => {
	const { roleId, planLevel } = JSON.parse(sessionStorage.getItem('selfData'));

	const renderPageForm = () => {
		switch (response.name) {
			case 'MyDevices':
				return {
					headerText: MY_DEVICES,
					form: <MyDevices />,
				};
			case 'AccountInformation':
				return {
					headerText: ACCT_INFO,
					form: <AccountInfo />,
				};
			case 'AccountGroupInformation':
				return {
					headerText: GROUP_INFO,
					form: <GroupInfo />,
				};
			case 'Notifications':
				return {
					headerText: NOTIFICATIONS,
					form: <Notifications />,
				};
			case 'Settings':
				return {
					headerText: SETTINGS,
					form: <Settings />,
				};
			default:
				return {
					headerText: MY_INFO,
					form: <MyInformation />,
				};
		}
	};

	const { headerText, form } = renderPageForm();
	return (
		<>
			<HeaderDefault router={router} />
			<div className="admin-layout">
				<div className="admin-menu">
					<h1 className="admin-title">My Account</h1>
					<ul className="admin-navbar">
						<li>
							<Link name="MyAccount" className={response.name === 'MyAccount' ? 'active' : ''}>
								{MY_INFO}
							</Link>
						</li>
						{roleId === USER_ROLES.USER ? (
							<li>
								<Link
									name="AccountInformation"
									className={response.name === 'AccountInformation' ? 'active' : ''}>
									{ACCT_INFO}
								</Link>
							</li>
						) : (
							<li>
								<Link
									name="AccountGroupInformation"
									className={response.name === 'AccountGroupInformation' ? 'active' : ''}>
									{GROUP_INFO}
								</Link>
							</li>
						)}
						<li>
							<Link name="MyDevices" className={response.name === 'MyDevices' ? 'active' : ''}>
								{MY_DEVICES}
							</Link>
						</li>
						{planLevel !== ACCT_PLAN_LEVELS.BASIC && (
							<li>
								<Link
									name="Notifications"
									className={response.name === 'Notifications' ? 'active' : ''}>
									{NOTIFICATIONS}
								</Link>
							</li>
						)}
						<li>
							<Link name="Settings" className={response.name === 'Settings' ? 'active' : ''}>
								{SETTINGS}
							</Link>
						</li>
					</ul>
				</div>
				<div className="admin-content">
					<div className="return-main">
						<Link name="WeatherDisplay">Return to Main Screen</Link>
					</div>
					<div className="inner-content">
						<h2>{headerText}</h2>
						{form}
					</div>
				</div>
			</div>
		</>
	);
};

MyAccount.propTypes = {
	router: PropTypes.object.isRequired,
};

export default MyAccount;
