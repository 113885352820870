/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import wretch from 'wretch';
import moment from 'moment';
import { DevicesContext } from '__state/Devices';
import { DEFAULT_PASSWORD, USER_ROLES } from 'utils/constants';
import __sortBy from 'lodash/sortBy';
import __isEmpty from 'lodash/isEmpty';
import __startCase from 'lodash/startCase';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CloseIcon from '@material-ui/icons/Close';

moment.tz.setDefault('UTC');

const GroupInfo = () => {
	const { groupId, roleId, username, token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [groupInfo, setGroupInfo] = useState(null);
	const [members, setMembers] = useState([]);
	const [refreshMembers, setRefreshMembers] = useState(true);
	const [checkedGA, setCheckedGA] = useState(''); // user id
	const [openAddAcct, setOpenAddAcct] = useState(false);
	const [openManageDevices, setOpenManageDevices] = useState(false);
	const [memberSelected, setMemberSelected] = useState(null);
	const [isFormLoading, setIsFormLoading] = useState(false);
	const { cachedDevices } = useContext(DevicesContext);

	useEffect(() => {
		const getGroupInfo = async () => {
			wretch(`${process.env.REACT_APP_UTIL_API_URL}/group/${groupId}/info`)
				.auth(`Bearer ${token}`)
				.get()
				.error(500, (error) => {
					console.error(JSON.stringify(error));
					alert('There was an issue getting your group info. Please contact us.');
				})
				.json((data) => {
					setGroupInfo(data);
				});
		};

		getGroupInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupId]);

	useEffect(() => {
		const getMembers = async () => {
			wretch(`${process.env.REACT_APP_UTIL_API_URL}/group/${groupId}/members`)
				.auth(`Bearer ${token}`)
				.get()
				.error(500, (error) => {
					console.error(JSON.stringify(error));
					alert('There was an issue getting your group members. Please contact us.');
				})
				.json((data) => {
					let members = data;
					members = __sortBy(members, ['firstName']);
					setMembers(members);

					const defaultGroupAdmin = members.find(
						(member) => member.roleId === USER_ROLES.GROUPADMIN,
					);
					if (defaultGroupAdmin) {
						setCheckedGA(defaultGroupAdmin.userId);
					}
				})
				.finally(() => {
					setRefreshMembers(false);
				});
		};

		if (refreshMembers && roleId !== USER_ROLES.USER) {
			getMembers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshMembers, groupId, roleId, username]);

	// the view checkbox is tied to the command checkbox - toggling on and off
	useEffect(() => {
		if (memberSelected !== null) {
			const allChecks = document.querySelectorAll('input[type="checkbox"]');
			for (let i = 0; i < allChecks.length; i++) {
				const check = allChecks[i];
				check.addEventListener('change', () => {
					// check "view" if cmd was checked
					if (check.id.includes('cmd-')) {
						const userIdAndIMEI = check.id.substr(check.id.indexOf('-') + 1);

						if (check.checked) {
							document.getElementById(`view-${userIdAndIMEI}`).checked = true;
						}
					}

					// uncheck "cmd" if view was unchecked
					if (check.id.includes('view-')) {
						const userIdAndIMEI = check.id.substr(check.id.indexOf('-') + 1);

						if (!check.checked) {
							document.getElementById(`cmd-${userIdAndIMEI}`).checked = false;
						}
					}
				});
			}
		}
	}, [memberSelected]);

	useEffect(() => {
		if (!openManageDevices) {
			setMemberSelected(null);
		}
	}, [openManageDevices]);

	const updateMembers = (e) => {
		e.preventDefault();
		setIsFormLoading(true);

		try {
			Promise.all(
				members.map(async (member) => {
					await updateSingleMember(member.userId);
				}),
			);

			alert('Update successful.');
		} catch (error) {
			console.error(JSON.stringify(error));

			if (error.text && JSON.parse(error.text).type === 'token') {
				alert('Your session has expired. Please log in again.');
				return;
			}

			alert(`There was an issue updating your members. Please try again or contact us.`);
		} finally {
			setIsFormLoading(false);
		}
	};

	const updateSingleMember = (userId) => {
		return new Promise((resolve, reject) => {
			const newFirstName = document.getElementById(`firstname-${userId}`).value;
			const newLastName = document.getElementById(`lastname-${userId}`).value;
			const newUsername = document.getElementById(`username-${userId}`).value;
			const newPassword = document.getElementById(`password-${userId}`).value;
			const isGroupAdmin = document.getElementById(`role-${userId}`).checked;

			let newData = {
				groupId: groupId,
			};

			const member = members.find((member) => member.userId === userId);
			if (newFirstName !== member.firstName) {
				newData['firstName'] = newFirstName;
			}
			if (newLastName !== member.lastName) {
				newData['lastName'] = newLastName;
			}
			if (newUsername !== member.username) {
				newData['email'] = newUsername;
			}
			if (newPassword !== '') {
				newData['password'] = newPassword;
			}

			const wasGroupAdmin = member.roleId === USER_ROLES.GROUPADMIN;
			if (isGroupAdmin && !wasGroupAdmin) {
				// we want to change member to groupAdmin status
				newData['isGroupAdmin'] = true;
			} else if (!isGroupAdmin && wasGroupAdmin) {
				// we want to change member to user status
				newData['isGroupAdmin'] = false;
			}

			if (__isEmpty(newData)) {
				alert('Please edit information first.');
				return;
			}

			wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/${userId}/update`)
				.auth(`Bearer ${token}`)
				.post(newData)
				.error(500, (error) => {
					reject(error);
				})
				.json(() => {
					resolve();
				});
		});
	};

	const removeMember = (e, userId) => {
		e.preventDefault();

		const memberToRemove = members.find((member) => member.userId === userId);
		const confirmed = window.confirm(
			`You are about to remove ${memberToRemove.username}. This is PERMANENT and cannot be undone.`,
		);

		if (confirmed) {
			setIsFormLoading(true);

			wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/${userId}`)
				.auth(`Bearer ${token}`)
				.json({
					groupId: groupId,
				})
				.delete()
				.error(500, (error) => {
					console.error(JSON.stringify(error));
					alert(`There was an issue removing ${memberToRemove.username}. Please contact us.`);
				})
				.json(() => {
					alert('Update successful.');
					setMembers([]);
					setRefreshMembers(true);
				})
				.finally(() => {
					setIsFormLoading(false);
				});
		}
	};

	const saveNewMember = (e) => {
		e.preventDefault();
		setIsFormLoading(true);

		const newFirstName = document.getElementById('new-acct-firstname').value;
		const newLastName = document.getElementById('new-acct-lastname').value;
		const newUsername = document.getElementById('new-acct-email').value;
		const newPassword = document.getElementById('new-acct-password').value;

		let newData = {
			firstName: newFirstName,
			lastName: newLastName,
			email: newUsername,
			password: newPassword,
			groupId: groupId,
		};

		if (
			__isEmpty(newFirstName) ||
			__isEmpty(newLastName) ||
			__isEmpty(newUsername) ||
			__isEmpty(newPassword)
		) {
			alert('Please edit information first.');
			return;
		}

		wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/add`)
			.auth(`Bearer ${token}`)
			.post(newData)
			.error(500, (error) => {
				console.error(JSON.stringify(error));
				alert(`There was an issue add a new member. Please try again or contact us.`);
			})
			.json(() => {
				alert('New member was created.');
				setMembers([]);
				setRefreshMembers(true);

				document.getElementById('new-acct-firstname').value = '';
				document.getElementById('new-acct-lastname').value = '';
				document.getElementById('new-acct-email').value = '';
				document.getElementById('new-acct-password').value = '';

				setOpenAddAcct(false);
			})
			.finally(() => {
				setIsFormLoading(false);
			});
	};

	const handleManageDevices = (userId) => {
		const member = members.find((member) => member.userId === userId);
		setMemberSelected(member);
		setOpenManageDevices(true);
	};

	const addChecked = (array, value) => {
		if (array.includes('NONE')) {
			array.splice(array.indexOf('NONE'), 1);
		}

		if (!array.includes(value)) {
			array.push(value);
		}

		return array;
	};

	const removeUnchecked = (array, value) => {
		if (array.includes(value)) {
			array.splice(array.indexOf(value), 1);
		}

		return array;
	};

	const submitDevicesUpdate = (userId) => {
		const user = members.find((member) => member.userId === userId);
		let devicesWithView = user.viewPerms.split(',');
		let devicesWithCmd = user.cmdPerms.split(',');

		cachedDevices.forEach((device) => {
			if (document.getElementById(`view-${userId}-${device.imei}`).checked) {
				devicesWithView = addChecked(devicesWithView, device.imei);
			} else {
				devicesWithView = removeUnchecked(devicesWithView, device.imei);
			}
		});

		cachedDevices.forEach((device) => {
			if (document.getElementById(`cmd-${userId}-${device.imei}`).checked) {
				devicesWithCmd = addChecked(devicesWithCmd, device.imei);
			} else {
				devicesWithCmd = removeUnchecked(devicesWithCmd, device.imei);
			}
		});

		let newData = {
			groupId: groupId,
		};

		newData['viewPerms'] = devicesWithView.length > 0 ? devicesWithView.join(',') : 'NONE';
		newData['cmdPerms'] = devicesWithCmd.length > 0 ? devicesWithCmd.join(',') : 'NONE';

		wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/${userId}/update`)
			.auth(`Bearer ${token}`)
			.post(newData)
			.error(500, (error) => {
				console.error(JSON.stringify(error));
				alert(`There was an issue updating your members. Please try again or contact us.`);
			})
			.json(() => {
				alert('Update successful.');
				setRefreshMembers(true);
			});
	};

	const acctsLeft =
		groupInfo && groupInfo.acctsTotal > -1 ? groupInfo.acctsTotal - members.length : -1;
	return (
		<div className="group-info-view">
			{groupInfo !== null && (
				<div className="info-block">
					<div className="info-row">
						<p className="info-title">Account Type:</p>
						<p className="info-data">
							{__startCase(Object.keys(USER_ROLES)[roleId - 1].toLowerCase())}
						</p>
					</div>
					<div className="info-row">
						<p className="info-title">Account Expiration:</p>
						<p className="info-data">
							{groupInfo.expiration
								? `${moment(groupInfo.expiration).format('YYYY-MM-DD HH:mm')} UTC`
								: 'N/A'}
						</p>
					</div>
					<div className="info-row">
						<p className="info-title">Maximum Accounts:</p>
						<p className="info-data">
							{groupInfo.acctsTotal === -1 ? 'Unlimited' : groupInfo.acctsTotal}
						</p>
					</div>
				</div>
			)}
			{
				// see src/utils/constants for USER_ROLES
				roleId !== USER_ROLES.USER && (
					<div className="group-accounts-table">
						<h2>Group Accounts</h2>
						<div className="maxed-table-width">
							<div className="table-intro">
								<p>Total Accounts: {members.length}</p>
								<button className="submit-button" disabled={isFormLoading} onClick={updateMembers}>
									Update All
								</button>
							</div>
							<div className="table-wrapper">
								<div className="devices-table account-table">
									<div className="table-header table-row">
										<div className="table-header-cell">First Name</div>
										<div className="table-header-cell">Last Name</div>
										<div className="table-header-cell">Email</div>
										<div className="table-header-cell">Password</div>
										{roleId !== USER_ROLES.GROUPADMIN && ( // group admins should not be able to remove themselves as the admin
											<div className="table-header-cell">Group Admin?</div>
										)}
										<div className="table-header-cell"></div>
										<div className="table-header-cell"></div>
									</div>
									{members.length > 0 &&
										members.map(({ firstName, lastName, userId, username, roleId }, i) => {
											return (
												<div key={i} className="table-row">
													<div className="table-body-cell with-input">
														<input
															id={`firstname-${userId}`}
															type="text"
															defaultValue={firstName}
															disabled={roleId === USER_ROLES.GROUPOWNER}
														/>
													</div>
													<div className="table-body-cell with-input">
														<input
															id={`lastname-${userId}`}
															type="text"
															defaultValue={lastName}
															disabled={roleId === USER_ROLES.GROUPOWNER}
														/>
													</div>
													<div className="table-body-cell with-input">
														<input
															id={`username-${userId}`}
															type="text"
															defaultValue={username}
															disabled={roleId === USER_ROLES.GROUPOWNER}
														/>
													</div>
													<div className="table-body-cell with-input">
														{roleId !== USER_ROLES.GROUPOWNER && (
															<input
																id={`password-${userId}`}
																type="password"
																placeholder={DEFAULT_PASSWORD}
															/>
														)}
													</div>
													{
														// group admins should not be able to remove themselves as the admin
														roleId !== USER_ROLES.GROUPADMIN && (
															<div className="table-body-cell with-input">
																{roleId !== USER_ROLES.GROUPOWNER && (
																	<input
																		id={`role-${userId}`}
																		type="checkbox"
																		checked={checkedGA === userId}
																		onChange={() => setCheckedGA(userId)}
																	/>
																)}
															</div>
														)
													}
													<div className="table-body-cell">
														<div className="action-links">
															{roleId !== USER_ROLES.GROUPOWNER && (
																<span role="button" onClick={() => handleManageDevices(userId)}>
																	Devices
																</span>
															)}
														</div>
													</div>
													<div className="table-body-cell">
														<div className="action-links">
															{/* <span role="button" onClick={(e) => updateMember(e, userId)}>Update</span> */}
															{roleId !== USER_ROLES.GROUPOWNER && (
																<span role="button" onClick={(e) => removeMember(e, userId)}>
																	Remove
																</span>
															)}
														</div>
													</div>
												</div>
											);
										})}
								</div>
								{acctsLeft !== 0 && (
									<div role="button" className="add-account" onClick={() => setOpenAddAcct(true)}>
										<PersonAddIcon />
										<span>Add Account</span>
									</div>
								)}
							</div>
							<div className="table-footer"></div>
						</div>
					</div>
				)
			}
			<div className="account-popup" data-open={openAddAcct}>
				<div className="popup-wrapper">
					<div className="popup-header">
						<CloseIcon onClick={() => setOpenAddAcct(false)} />
					</div>
					<div className="popup-body">
						<h3>Add New Member</h3>
						<form onSubmit={saveNewMember}>
							<div className="form-row">
								<label htmlFor="new-acct-firstname">First Name</label>
								<input id="new-acct-firstname" type="text" />
							</div>
							<div className="form-row">
								<label htmlFor="new-acct-lastname">Last Name</label>
								<input id="new-acct-lastname" type="text" />
							</div>
							<div className="form-row">
								<label htmlFor="new-acct-email">Email</label>
								<input id="new-acct-email" type="email" />
							</div>
							<div className="form-row">
								<label htmlFor="new-acct-password">Password</label>
								<input id="new-acct-password" type="password" />
							</div>
							<div className="form-row submit">
								<div></div>
								<input type="submit" disabled={isFormLoading} value="Add Member" />
							</div>
						</form>
					</div>
				</div>
			</div>
			{memberSelected !== null && (
				<div className="account-popup" data-open={openManageDevices}>
					<div className="popup-wrapper">
						<div className="popup-header">
							<CloseIcon onClick={() => setOpenManageDevices(false)} />
						</div>
						<div className="popup-body">
							<div className="maxed-table-width">
								<div className="table-intro">
									<p>Devices for {`${memberSelected.firstName} ${memberSelected.lastName}`}</p>
									<button
										className="submit-button"
										disabled={isFormLoading}
										onClick={() => submitDevicesUpdate(memberSelected.userId)}>
										Update
									</button>
								</div>
								<div className="table-wrapper">
									<div className="devices-table account-table">
										<div className="table-header table-row">
											<div className="table-header-cell">IMEI</div>
											<div className="table-header-cell device-type">Type</div>
											<div className="table-header-cell">View</div>
											<div className="table-header-cell">Command</div>
										</div>
										{cachedDevices.length > 0 &&
											cachedDevices.map(({ imei, type }, i) => {
												const hasViewPerm =
													memberSelected.viewPerms === 'ALL' ||
													memberSelected.viewPerms.includes(imei);
												const hasCmdPerm =
													memberSelected.cmdPerms === 'ALL' ||
													memberSelected.cmdPerms.includes(imei);

												return (
													<div key={i} className="table-row">
														<div className="table-body-cell">{imei}</div>
														<div className="table-body-cell device-type">{type}</div>
														<div className="table-body-cell with-input">
															<input
																id={`view-${memberSelected.userId}-${imei}`}
																type="checkbox"
																defaultChecked={hasViewPerm}
															/>
														</div>
														<div className="table-body-cell with-input">
															<input
																id={`cmd-${memberSelected.userId}-${imei}`}
																type="checkbox"
																defaultChecked={hasCmdPerm}
															/>
														</div>
													</div>
												);
											})}
									</div>
								</div>
								<div className="table-footer"></div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default GroupInfo;
