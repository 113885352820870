import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import wretch from 'wretch';
import moment from 'moment-timezone';
import __forEach from 'lodash/forEach';

import { MWS_COMMANDS } from 'utils/constants';

import CommandHistory from 'components/CommandHistory';

moment.tz.setDefault('UTC');

const MWSCommands = ({ device }) => {
	const { username, token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [reportingMode, setReporting] = useState('');
	const [camera, setCamera] = useState('');
	const [ceilometer, setCeilometer] = useState('');
	const [gps, setGps] = useState('');
	const [speci] = useState('');
	const [isSending, setSending] = useState(false);
	const [disableSend, setDisableSend] = useState(false);
	const [confirmType, setConfirmType] = useState(null);

	//values that need secondary inputs
	const [elevationValue, setElevationValue] = useState('');
	const [whichCamera, setWhichCamera] = useState('');
	const [cameraHour, setCameraHour] = useState('');
	const [mp3Time] = useState('');
	const [rawTime] = useState('');
	const [errorFields, setErrorFields] = useState([]);

	const [shouldGetHistory, setShouldGetHistory] = useState(false);

	const { 
		REPORTING,
		GPS,
		CAMERA,
		CEILOMETER, 
	} = MWS_COMMANDS;

	//disable the send button if a request is in progress, or all values are blank,
	//or if the secondary values are blank
	useEffect(() => {
		if (
			isSending ||
			(reportingMode === '' &&
				camera === '' &&
				ceilometer === '' &&
				gps === '' &&
				speci === '') ||
			(gps === GPS.SET_ELEV && !elevationValue.match(/^(\+|-)([0-9]{4})$/)) ||
			(camera === CAMERA.TAKE_SINGLE && !whichCamera.match(/^(1|2|3|4)$/)) ||
			(camera === CAMERA.SET_AUTO && !cameraHour.match(/^(([01]\d|2[0-3])00)(,([01]\d|2[0-3])00)*$/))
		) {
			setDisableSend(true);
		} else {
			setDisableSend(false);
		}

		let eFields = errorFields;

		if (gps === GPS.SET_ELEV) {
			if (!elevationValue.match(/^(\+|-)([0-9]{4})$/)) {
				eFields = addToArray(eFields, 'elevationValue');
			} else {
				eFields = removeFromArray(eFields, 'elevationValue');
			}
		}

		if (camera === CAMERA.TAKE_SINGLE) {
			if (!whichCamera.match(/^(1|2|3|4)$/)) {
				eFields = addToArray(eFields, 'whichCamera');
			} else {
				eFields = removeFromArray(eFields, 'whichCamera');
			}
		}

		if (camera === CAMERA.SET_AUTO) {
			if (!cameraHour.match(/^(([01]\d|2[0-3])00)(,([01]\d|2[0-3])00)*$/)) {
				eFields = addToArray(eFields, 'cameraHour');
			} else {
				eFields = removeFromArray(eFields, 'cameraHour');
			}
		}

		setErrorFields(eFields);
	}, [isSending, gps, elevationValue, reportingMode,
			camera, ceilometer, speci, whichCamera, cameraHour,
			mp3Time, rawTime ]);

	const addToArray = (array, value) => {
		if (!array.includes(value)) {
			array.push(value);
		}

		return array;
	};

	const removeFromArray = (array, value) => {
		if (array.includes(value)) {
			array.splice(array.indexOf(value), 1);
		}

		return array;
	};

	const submitCommand = async (e) => {
		e.preventDefault();
		setSending(true);

		let newGPS = gps;
		if (gps === GPS.SET_ELEV) {
			newGPS += elevationValue;
		}

		let newCamera = camera;
		if (camera === CAMERA.TAKE_SINGLE) {
			newCamera = `${camera}${whichCamera}`;
		} else if (camera === CAMERA.SET_AUTO) {
			newCamera = '';
			const allHours = cameraHour.split(',');
			__forEach(allHours, (time) => {
				newCamera += `${camera}${time.substr(0, 2)} `;
			});

			newCamera = newCamera.trim();
		}

		const commandsToSend = [];
		reportingMode !== '' && commandsToSend.push(reportingMode);
		newGPS !== '' && commandsToSend.push(newGPS);
		newCamera !== '' && commandsToSend.push(newCamera);
		ceilometer !== '' && commandsToSend.push(ceilometer);
		speci !== '' && commandsToSend.push(speci);

		let options = {
			method: device.modem === 'Cellular' ? 'MWSCellCommands' : 'MWSCommands',
			params: {},
			timeout: 5000,
		};

		options['params']['DestinationIMEI'] = [device.imei];
		options['params']['DeviceType'] = ['MWS'];
		options['params']['Command'] = [commandsToSend.join(' ')];
		options['params']['Issuer'] = [username];
		options['params']['Modem'] = [device.modem];
		options['params']['CmdSendDate'] = moment().valueOf();

		await wretch(`${process.env.REACT_APP_UTIL_API_URL}/unit/commands/send`)
			.auth(`Bearer ${token}`)
			.post({
				deviceId: device.id,
				options: options,
			})
			.error(500, (error) => {
				console.log(error);
				setConfirmType('error');
			})
			.res(() => {
				setConfirmType('success');
				setShouldGetHistory(true);
			})
			.finally(() => {
				setSending(false);
			});

		setTimeout(() => {
			setConfirmType(null);
		}, 7000);
	};

	return (
		<>
			<div className="command-form">
				<div className="command-input">
					<span>Reporting Mode:&nbsp;&nbsp;</span>
					<select onChange={(e) => setReporting(e.target.value)}>
						<option value="">No command</option>
						<option value={REPORTING.HOURS_3}>3 Hour</option>
						<option value={REPORTING.HOURS_1}>1 Hour</option>
						<option value={REPORTING.MINS_20}>20 Minute</option>
						<option value={REPORTING.MINS_5}>5 Minute</option>
					</select>
				</div>
				<div className="command-input">
					<span>GPS:&nbsp;&nbsp;</span>
					<select onChange={(e) => setGps(e.target.value)}>
						<option value="">No command</option>
						<option value={GPS.CANCEL}>Cancel previous GPS update</option>
						<option value={GPS.DISABLE}>Disable Lat/Lon</option>
						<option value={GPS.ENABLE_LL}>Enable Lat/Lon</option>
						<option value={GPS.UPDATE_LL}>Update Lat/Lon</option>
						<option value={GPS.UPDATE_LLE}>Update Lat/Lon/Elevation</option>
						<option value={GPS.SET_ELEV}>Set Elevation</option>
					</select>
					{gps === GPS.SET_ELEV && (
						<>
							<div></div>
							<div className="command-input sub-command">
								<span>Elevation (m):&nbsp;&nbsp;</span>
								<input
									type="text"
									placeholder="+xxxx or -xxxx"
									className={errorFields.includes('elevationValue') ? 'error' : ''}
									value={elevationValue}
									onChange={(e) => setElevationValue(e.target.value)}
								/>
							</div>
						</>
					)}
				</div>
				<div className="command-input">
					<span>Camera:&nbsp;&nbsp;</span>
					<select onChange={(e) => setCamera(e.target.value)}>
						<option value="">No command</option>
						<option value={CAMERA.TAKE_SINGLE}>Take Single Image</option>
						<option value={CAMERA.TAKE_MULTIPLE}>Take Images</option>
						<option value={CAMERA.SET_AUTO}>Set Auto Image Capture</option>
						<option value={CAMERA.CANCEL_AUTO}>Cancel Auto Image Capture</option>
					</select>
					{camera === CAMERA.TAKE_SINGLE && (
						<>
							<div></div>
							<div className="command-input sub-command">
								<span>Camera number:&nbsp;&nbsp;</span>
								<input
									type="text"
									placeholder="1, 2, 3, or 4"
									className={errorFields.includes('whichCamera') ? 'error' : ''}
									value={whichCamera}
									onChange={(e) => setWhichCamera(e.target.value)}
								/>
							</div>
						</>
					)}
					{camera === CAMERA.SET_AUTO && (
						<>
							<div></div>
							<div className="command-input sub-command">
								<span>Which hour(s)?&nbsp;&nbsp;</span>
								<input
									type="text"
									placeholder="24-hr (ex: 0300,2100)"
									className={errorFields.includes('cameraHour') ? 'error' : ''}
									value={cameraHour}
									onChange={(e) => setCameraHour(e.target.value)}
								/>
							</div>
						</>
					)}
				</div>
				<div className="command-input">
					<span>Ceilometer:&nbsp;&nbsp;</span>
					<select onChange={(e) => setCeilometer(e.target.value)}>
						<option value="">No command</option>
						<option value={CEILOMETER.DISABLE}>Disable</option>
						<option value={CEILOMETER.ENABLE_AUTO}>Enable Auto</option>
						<option value={CEILOMETER.ENABLE_ALWAYS}>Enable Always</option>
					</select>
				</div>
				<div className="command-input">
					<span></span>
					<div className="with-confirm">
						<input
							type="submit"
							value="Send"
							disabled={disableSend}
							onClick={(e) => submitCommand(e)}
						/>
						<div
							className={`confirmation-message success ${confirmType === 'success' ? 'show' : ''}`}>
							The command has been successfully queued.
						</div>
						<div className={`confirmation-message error ${confirmType === 'error' ? 'show' : ''}`}>
							Sorry, there was an error queuing the command.
						</div>
					</div>
				</div>
			</div>
			<CommandHistory
				device={device}
				shouldGetHistory={shouldGetHistory}
				setShouldGetHistory={setShouldGetHistory}
			/>
		</>
	);
};

MWSCommands.propTypes = {
	device: PropTypes.object.isRequired,
};

export default MWSCommands;
