import React from 'react';
import PropTypes from 'prop-types';

import './Compass.scss';

const Compass = ({ direction = null, windSpeed = '' }) => {
	//because the original dial rotation looks -45 deg off
	const realRotation = direction && direction + 45;

	return (
		<div className="compass">
			<div className="compass__windrose">
				{[...Array(10)].map((k, i) => (
					<div className="compass__mark" key={i + 1}></div>
				))}
				<div className="compass__mark--direction-h"></div>
				<div className="compass__mark--direction-v"></div>
			</div>
			<div className="compass__arrow-container">
				{ direction && <div className="compass__arrow" style={{ transform: `rotate(${realRotation}deg)` }}></div>}
				<div className="compass__labels">
					<div>
						<span>{windSpeed}</span>
						{direction && <span>{`${direction}°`}</span>}
					</div>
				</div>
			</div>
		</div>
	);
};

Compass.propTypes = {
	direction: PropTypes.any,
	windSpeed: PropTypes.string,
};

export default Compass;
