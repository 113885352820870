export const VIEW_G_EXPORT = 'GroupExport';
export const VIEW_G_COMMAND = 'GroupCommand';
export const VIEW_TABLE = 'Table';
export const VIEW_PLOTS = 'Plots';
export const VIEW_EXPORT = 'Export';
export const VIEW_COMMAND = 'Command';
export const VIEW_DASHBOARD = 'Dashboard';
export const VIEW_NOTIFICATIONS = 'Notifications';

export const DEFAULT_PASSWORD = '******';

export const USER_ROLES = {
	SUPERADMIN: 1,
	ADMIN: 2,
	INTERNALTESTER: 3,
	GROUPOWNER: 4,
	GROUPADMIN: 5,
	USER: 6,
};

// keys mimic postgres
export const ALERT_PARAMETERS = [
	{
		key: 'temp',
		name: 'Temperature',
		unit: 'C',
	},
	{
		key: 'windspeed',
		name: 'Wind Speed',
		unit: 'kt',
	},
	{
		key: 'winddir',
		name: 'Wind Direction Change',
		unit: 'degrees',
	},
	{
		key: 'precip',
		name: 'Precipitation',
		unit: 'in/hr',
	},
	{
		key: 'cloudlayer',
		name: 'Lowest Cloud Layer',
		unit: 'ft',
	},
	{
		key: 'statpress',
		name: 'Station Pressure',
		unit: 'inHg',
	},
	{
		key: 'relhum',
		name: 'Relative Humidity',
		unit: '%',
	},
	{
		key: 'lightningdist',
		name: 'Lightning Distance',
		unit: 'NM',
	},
	{
		key: 'heatindex',
		name: 'Heat Index',
		unit: 'C',
	},
	{
		key: 'windchill',
		name: 'Wind Chill',
		unit: 'C',
	},
	{
		key: 'lightningfreq',
		name: 'Lightning Frequency',
		unit: '',
	},
];

export const ACCT_PLAN_LEVELS = {
	UNRESTRICTED: -1,
	BASIC: 0,
	ADVANCED: 1,
	PRO: 2,
};

export const MWS_COMMANDS = {
	REPORTING: {
		HOURS_3: 'M:0',
		HOURS_1: 'M:1',
		MINS_20: 'M:2',
		MINS_5: 'M:3',
	},
	GPS: {
		CANCEL: 'G:-',
		DISABLE: 'G:0',
		ENABLE_LL: 'G:1',
		UPDATE_LL: 'G:I',
		UPDATE_LLE: 'G:i',
		SET_ELEV: 'G:E',
	},
	CAMERA: {
		TAKE_SINGLE: 'I:',
		TAKE_MULTIPLE: 'I:5',
		SET_AUTO: 'i:',
		CANCEL_AUTO: 'i:-',
	},
	CEILOMETER: {
		DISABLE: 'C:0',
		ENABLE_AUTO: 'C:1',
		ENABLE_ALWAYS: 'C:2',
	},
};

export const IWOS_COMMANDS = {
	REPORTING: {
		RATE: 'rate',
		SPECI: 'speci',
	},
	GPS: {
		DISABLE: 'gps_disable',
		ENABLE: 'gps_enable',
		DISABLE_ELEV: 'gps_disable_elev',
		ENABLE_ELEV: 'gps_enable_elev',
		READ: 'gps_read',
		READ_LOC: 'gps_read_loc',
		SET_ELEV: 'gps_set_elev',
		COMPASS_DECLINE: 'compass_declination',
	},
	MODULES: {
		DISABLE: 'disable',
		ENABLE: 'enable',
	},
	OTHER: {
		SET_DATE: 'set_date',
		DEFAULT: 'default',
		LOG_CLEAR: 'log_clear',
		LOG_DISABLE: 'log_disable',
		LOG_ENABLE: 'log_enable',
		RESET: 'reset',
	},
};