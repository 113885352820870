import React, { createContext, useReducer } from 'react';

const initialState = {
	cachedDevices:
		sessionStorage.getItem('cachedDevices') !== null
			? JSON.parse(sessionStorage.getItem('cachedDevices'))
			: [],
};

const actions = {
	ADD_DEVICES: 'ADD_DEVICES',
	RESET_DEVICES: 'RESET_DEVICES',
};

const reducer = (state, action) => {
	const { devices } = action;
	switch (action.type) {
		case actions.ADD_DEVICES:
			const newDevices = { ...state, cachedDevices: [...devices] };
			sessionStorage.setItem('cachedDevices', JSON.stringify(newDevices.cachedDevices));
			return newDevices;
		case actions.RESET_DEVICES:
			let tempDevices = state.cachedDeviceEntries;
			tempDevices = [];
			sessionStorage.removeItem('cachedDevices');
			return { ...state, cachedDevices: tempDevices };
		default:
			return state;
	}
};

export const DevicesContext = createContext();

const DevicesProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = {
		cachedDevices: state.cachedDevices,
		setCachedDevices: (devices) => {
			dispatch({ type: actions.ADD_DEVICES, devices: devices });
		},
		resetCachedDevices: () => {
			dispatch({ type: actions.RESET_DEVICES });
		},
	};

	return <DevicesContext.Provider value={value}>{children}</DevicesContext.Provider>;
};

export default DevicesProvider;
