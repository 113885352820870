/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@curi/react-dom';

import './Help.scss';

import HeaderDefault from 'components/HeaderDefault';

import ContactUs from './ContactUs';
import Downloads from './Downloads';

const CONTACT_US = 'Contact Us';
const DOWNLOADS = 'Downloads';

const Help = ({ router }) => {
	const [innerPage, setInnerPage] = useState(CONTACT_US);

	const changePage = (e, page) => {
		e.preventDefault();
		setInnerPage(page);
	};

	const renderPageForm = () => {
		switch (innerPage) {
			case DOWNLOADS:
				return <Downloads />;
			default:
				return <ContactUs />;
		}
	};

	return (
		<>
			<HeaderDefault router={router} />
			<div className="admin-layout">
				<div className="admin-menu">
					<h1 className="admin-title">Help</h1>
					<ul className="admin-navbar">
						<li>
							<a
								className={innerPage === CONTACT_US ? 'active' : ''}
								onClick={(e) => changePage(e, CONTACT_US)}>
								{CONTACT_US}
							</a>
						</li>
						<li>
							<a
								className={innerPage === DOWNLOADS ? 'active' : ''}
								onClick={(e) => changePage(e, DOWNLOADS)}>
								{DOWNLOADS}
							</a>
						</li>
					</ul>
				</div>
				<div className="admin-content">
					<div className="return-main">
						<Link name="WeatherDisplay">Return to Main Screen</Link>
					</div>
					<div className="inner-content">
						<h2>{innerPage}</h2>
						{renderPageForm()}
					</div>
				</div>
			</div>
		</>
	);
};

Help.propTypes = {
	router: PropTypes.object.isRequired,
};

export default Help;
