import React, { createContext, useReducer } from 'react';

const initialState = {
	devicesCollapsed: false,
	entriesCollapsed: true,
};

const actions = {
	COLLAPSE_DEVICES: 'COLLAPSE_DEVICES',
	COLLAPSE_ENTRIES: 'COLLAPSE_ENTRIES',
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.COLLAPSE_DEVICES:
			return { ...state, devicesCollapsed: action.collapse };
		case actions.COLLAPSE_ENTRIES:
			return { ...state, entriesCollapsed: action.collapse };
		default:
			return state;
	}
};

export const DataWindowContext = createContext();

const DataWindowProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = {
		devicesCollapsed: state.devicesCollapsed,
		entriesCollapsed: state.entriesCollapsed,
		setDevicesCollapsed: (collapse) => {
			dispatch({ type: actions.COLLAPSE_DEVICES, collapse: collapse });
		},
		setEntriesCollapsed: (collapse) => {
			dispatch({ type: actions.COLLAPSE_ENTRIES, collapse: collapse });
		},
	};

	return <DataWindowContext.Provider value={value}>{children}</DataWindowContext.Provider>;
};

export default DataWindowProvider;
