/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react';
import wretch from 'wretch';
import { DevicesContext } from '__state/Devices';
import { USER_ROLES, ALERT_PARAMETERS, ACCT_PLAN_LEVELS } from 'utils/constants';

import CloseIcon from '@material-ui/icons/Close';

const defaultAlerts = (username) => ({
	unit_imei: null,
	email_list: '',
	alerts_enabled: false,
	temp_alert_below: '',
	temp_alert_above: '',
	windspeed_alert_below: '',
	windspeed_alert_above: '',
	winddir_alert_below: '',
	winddir_alert_above: '',
	precip_alert_below: '',
	precip_alert_above: '',
	cloudlayer_alert_below: '',
	cloudlayer_alert_above: '',
	statpress_alert_below: '',
	statpress_alert_above: '',
	relhum_alert_below: '',
	relhum_alert_above: '',
	lightningdist_alert_below: '',
	lightningdist_alert_above: '',
	heatindex_alert_below: '',
	heatindex_alert_above: '',
	windchill_alert_below: '',
	windchill_alert_above: '',
	lightningfreq_alert_below: '',
	lightningfreq_alert_above: '',
	username: username,
});

const MyDevices = () => {
	const { username, roleId, planLevel, token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [isFormLoading, setIsFormLoading] = useState(false);
	const [emailList, setEmailList] = useState('');
	const [openEmailAlerts, setOpenEmailAlerts] = useState(false);
	const { cachedDevices } = useContext(DevicesContext);
	const [alerts, setAlerts] = useState(defaultAlerts(username));

	const submitDevicesUpdate = async () => {
		setIsFormLoading(true);

		try {
			await Promise.all(
				cachedDevices.map(async ({ imei, name }) => {
					await updateSingleDevice(imei, name);
				}),
			);

			alert('Update successful.');
		} catch (error) {
			console.error(JSON.stringify(error));

			if (error.text && JSON.parse(error.text).type === 'token') {
				alert('Your session has expired. Please log in again.');
				return;
			}

			alert(`There was an issue updating your devices. Please try again or contact us.`);
		} finally {
			setIsFormLoading(false);
		}
	};

	const updateSingleDevice = (imei, alias) => {
		return new Promise((resolve, reject) => {
			let newData = {};
			const newAlias = document.getElementById(`alias-${imei}`).value;

			if (newAlias !== alias) {
				newData['alias'] = newAlias;

				wretch(`${process.env.REACT_APP_UTIL_API_URL}/unit/${imei}/update`)
					.auth(`Bearer ${token}`)
					.post(newData)
					.error(500, (error) => {
						reject(error);
					})
					.json(() => {
						resolve();
					});
			} else {
				resolve();
			}
		});
	};

	const handleOpenAlerts = async (imei) => {
		setAlerts(defaultAlerts(username));

		try {
			const selectedAlerts = await wretch(`${process.env.REACT_APP_UTIL_API_URL}/unit/alerts`)
				.auth(`Bearer ${token}`)
				.post({ imei })
				.json();

			if (selectedAlerts.length > 0) {
				setAlerts(selectedAlerts[0]);
				setEmailList(selectedAlerts[0]['email_list']);
			} else {
				changeIMEIForAlerts(imei);
			}
			setOpenEmailAlerts(true);
		} catch (error) {
			console.log(error);

			if (error.text && JSON.parse(error.text).type === 'token') {
				alert('Your session has expired. Please log in again.');
				return;
			}

			alert('Something went wrong getting your alerts. Please try again or contact us.');
		}
	};

	const changeIMEIForAlerts = (imei) => {
		let selected = alerts;
		selected['unit_imei'] = imei;
		setAlerts({ ...selected });
	};

	const toggleStatus = (shouldNotify) => {
		let selected = alerts;
		selected[`alerts_enabled`] = shouldNotify;
		setAlerts({ ...selected });
	};

	const onAboveChange = (key) => {
		let selected = alerts;
		const value = document.getElementById(`greaterThan-${key}`).value;
		selected[`${key}_alert_above`] = value === '' ? null : value;
		setAlerts({ ...selected });
	};

	const onBelowChange = (key) => {
		let selected = alerts;
		const value = document.getElementById(`lessThan-${key}`).value;
		selected[`${key}_alert_below`] = value === '' ? null : value;
		setAlerts({ ...selected });
	};

	const closeEmailAlerts = () => {
		setOpenEmailAlerts(false);
		setAlerts(defaultAlerts(username));
		setEmailList('');
	};

	const setEmailAlerts = async (imei) => {
		let alertsToSend = alerts;
		alertsToSend['email_list'] = emailList;

		// set up thingsboard keys
		let thbAlerts = {
			alertList: conformValueForDB(alertsToSend['email_list']),
			alertsEnabled: conformValueForDB(alertsToSend['alerts_enabled']),
			tempHigh: conformValueForDB(alertsToSend['temp_alert_above']),
			tempLow: conformValueForDB(alertsToSend['temp_alert_below']),
			windHigh: conformValueForDB(alertsToSend['windspeed_alert_above']),
			windLow: conformValueForDB(alertsToSend['windspeed_alert_below']),
			windDirHigh: conformValueForDB(alertsToSend['winddir_alert_above']),
			windDirLow: conformValueForDB(alertsToSend['winddir_alert_below']),
			precipHigh: conformValueForDB(alertsToSend['precip_alert_above']),
			precipLow: conformValueForDB(alertsToSend['precip_alert_below']),
			cloudHigh: conformValueForDB(alertsToSend['cloudlayer_alert_above']),
			cloudLow: conformValueForDB(alertsToSend['cloudlayer_alert_below']),
			pressureHigh: conformValueForDB(alertsToSend['statpress_alert_above']),
			pressureLow: conformValueForDB(alertsToSend['statpress_alert_below']),
			humidityHigh: conformValueForDB(alertsToSend['relhum_alert_above']),
			humidityLow: conformValueForDB(alertsToSend['relhum_alert_below']),
			lightningDistHigh: conformValueForDB(alertsToSend['lightningdist_alert_above']),
			lightningDistLow: conformValueForDB(alertsToSend['lightningdist_alert_below']),
			heatIndexHigh: conformValueForDB(alertsToSend['heatindex_alert_above']),
			heatIndexLow: conformValueForDB(alertsToSend['heatindex_alert_below']),
			windChillHigh: conformValueForDB(alertsToSend['windchill_alert_above']),
			windChillLow: conformValueForDB(alertsToSend['windchill_alert_below']),
			lightningFreqHigh: conformValueForDB(alertsToSend['lightningfreq_alert_above']),
			lightningFreqLow: conformValueForDB(alertsToSend['lightningfreq_alert_below']),
		};

		// get device token
		const deviceId = cachedDevices.find((device) => device.imei === imei).id;
		try {
			const { credentialsId } = await wretch(
				`${process.env.REACT_APP_UTIL_API_URL}/unit/${deviceId}/token/`,
			)
				.auth(`Bearer ${token}`)
				.get()
				.json();

			// save to postgres
			await wretch(`${process.env.REACT_APP_UTIL_API_URL}/unit/save-alerts`)
				.auth(`Bearer ${token}`)
				.post({ alerts: alertsToSend })
				.json();

			// send to thingsboard API
			await wretch(`${process.env.REACT_APP_UTIL_API_URL}/alerts/post`)
				.auth(`Bearer ${token}`)
				.post({
					credentials: credentialsId,
					attributes: thbAlerts,
				})
				.json();

			alert('Your alerts have been saved.');
		} catch (error) {
			console.log(error);

			if (error.text && JSON.parse(error.text).type === 'token') {
				alert('Your session has expired. Please log in again.');
				return;
			}

			alert('Something went wrong saving your alerts. Please try again or contact us.');
		}
	};

	const conformValueForDB = (value) => {
		if (value === 'null' || value === '' || value === null) {
			return 'null';
		}

		return value;
	};

	const conformValueForForm = (value) => {
		if (value === 'null' || value === '' || value === null) {
			return '';
		}

		return value;
	};

	let notify = alerts[`alerts_enabled`];

	const isNotUserRole = roleId !== USER_ROLES.USER;
	const isAdvancedAcctOrHigher =
		planLevel === ACCT_PLAN_LEVELS.ADVANCED ||
		planLevel === ACCT_PLAN_LEVELS.PRO ||
		planLevel === ACCT_PLAN_LEVELS.UNRESTRICTED;
	const isSomeTypeOfAdmin =
		roleId === USER_ROLES.SUPERADMIN ||
		roleId === USER_ROLES.ADMIN ||
		roleId === USER_ROLES.INTERNALTESTER ||
		roleId === USER_ROLES.GROUPOWNER;

	return (
		<div className="my-devices-view">
			<div className="maxed-table-width">
				<div className="table-intro">
					<p>Total Devices: {cachedDevices.length}</p>
					{isNotUserRole && (
						<button
							className="submit-button"
							disabled={isFormLoading}
							onClick={submitDevicesUpdate}>
							Update
						</button>
					)}
				</div>
				<div className="table-wrapper">
					<div className="devices-table account-table">
						<div className="table-header table-row">
							<div className="table-header-cell">IMEI</div>
							<div className="table-header-cell device-type">Type</div>
							{isNotUserRole && <div className="table-header-cell">Alias</div>}
							{isAdvancedAcctOrHigher && isSomeTypeOfAdmin && (
								<div className="table-header-cell">Alerts</div>
							)}
						</div>
						{cachedDevices.length > 0 &&
							cachedDevices.map(({ imei, type, name }, i) => (
								<div key={i} className="table-row">
									<div className="table-body-cell">{imei}</div>
									<div className="table-body-cell device-type">{type}</div>
									{isNotUserRole && (
										<div className="table-body-cell with-input">
											<input id={`alias-${imei}`} type="text" defaultValue={name || ''} />
										</div>
									)}
									{isAdvancedAcctOrHigher && isSomeTypeOfAdmin && (
										<div className="table-body-cell">
											<div className="action-links">
												<span role="button" onClick={() => handleOpenAlerts(imei)}>
													Modify
												</span>
											</div>
										</div>
									)}
								</div>
							))}
					</div>
				</div>
				<div className="table-footer"></div>
			</div>
			{alerts !== null && (
				<div className="account-popup email-alerts-popup" data-open={openEmailAlerts}>
					<div className="popup-wrapper">
						<div className="popup-header">
							<CloseIcon onClick={closeEmailAlerts} />
						</div>
						<div className="popup-body">
							<div className="maxed-table-width">
								<div className="table-intro">
									<p>IMEI: {alerts.unit_imei}</p>
									<button
										className="submit-button"
										disabled={isFormLoading}
										onClick={() => setEmailAlerts(alerts.unit_imei)}>
										Update
									</button>
								</div>
								<div className="table-sub-intro">
									<div>
										<label>Alerts Enabled *</label>
										<button className={notify ? 'on' : ''} onClick={() => toggleStatus(!notify)}>
											{notify ? 'ON' : 'OFF'}
										</button>
									</div>
									<div>
										<label>Email Addresses</label>
										<input
											type="text"
											value={emailList}
											onChange={(e) => setEmailList(e.target.value.replace(/\s/gi, ''))}
											disabled={!notify}
										/>
									</div>
								</div>
								<div className="table-wrapper">
									<div className="alerts-table account-table">
										<div className="table-header table-row">
											<div className="table-header-cell">Parameter</div>
											<div className="table-header-cell">&gt;</div>
											<div className="table-header-cell">&lt;</div>
											<div className="table-header-cell">Units</div>
										</div>
										{ALERT_PARAMETERS.map(({ key, name, unit }, i) => {
											return (
												<div key={i} className="table-row">
													<div className="table-body-cell">{name}</div>
													<div className="table-body-cell with-input">
														{
															<input
																id={`greaterThan-${key}`}
																type="number"
																value={conformValueForForm(alerts[`${key}_alert_above`])}
																onChange={() => onAboveChange(key)}
																disabled={!notify}
															/>
														}
													</div>
													<div className="table-body-cell with-input">
														{
															<input
																id={`lessThan-${key}`}
																type="number"
																value={conformValueForForm(alerts[`${key}_alert_below`])}
																onChange={() => onBelowChange(key)}
																disabled={!notify}
															/>
														}
													</div>
													<div className="table-body-cell">{unit}</div>
												</div>
											);
										})}
									</div>
								</div>
								<div className="alerts-footer table-footer">
									<br />
									Note: Please be sure to convert all values to the units above.
									<br />
									<br />
									* Alert emails will be sent when a parameter exceeds a set threshold. Emails will
									send no faster than at 1-hour intervals regardless of unit reporting mode.
									<br />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default MyDevices;
