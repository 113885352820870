export const createIWOSCSVHeader = (showMetricOnly) => {
  // need to use pluses because the csv registers new lines
  return '' +
    `IMEI,` +
    `METAR,` +
    `Date/Time (UTC),` +
    `Unit Type,` +
    `DataType,` +
    `Wind Direction (°),` +
    `Wind Speed (${showMetricOnly ? 'm/s' : 'kt'}),` +
    `Visibility (${showMetricOnly ? 'm' : 'SM'}),` +
    `Present Weather,` +
    `Cloud Layers (ft),` +
    `Temperature (${showMetricOnly ? '°C' : '°F'}),` +
    `Dew Point (${showMetricOnly ? '°C' : '°F'}),` +
    `Relative Humidity %,` +
    `Altimeter (${showMetricOnly ? 'mb/hPa' : 'inHg'}),` +
    `Lightning Freq.,` +
    `Lightning Dist. (NM),` +
    `Cloud Ceiling (ft),` +
    `Sig Wave Direction (°),` +
    `Sig Wave Period (sec),` +
    `Sig Wave Height (${showMetricOnly ? 'm' : 'ft'}),` +
    `Peak Wind Direction (°),` +
    `Peak Wind Speed (${showMetricOnly ? 'm/s' : 'kt'}),` +
    `Precipitation Rate (${showMetricOnly ? 'mm/hr' : 'in/hr'}),` +
    `Wind Chill Index (${showMetricOnly ? '°C' : '°F'}),` +
    `Heat Index (${showMetricOnly ? '°C' : '°F'}),` +
    `Station Pressure (${showMetricOnly ? 'mb/hPa' : 'inHg'}),` +
    `Pressure Altitude (${showMetricOnly ? 'm' : 'ft'}),` +
    `Density Altitude (${showMetricOnly ? 'm' : 'ft'}),` +
    `SST (${showMetricOnly ? '°C' : '°F'}),` +
    `Compass Heading (°),` +
    `Latitude,` +
    `Longitude,` +
    `Elevation (${showMetricOnly ? 'm' : 'ft'}),` +
    `Tilt (°),` +
    `Orientation (°),` +
    `Voltage (V),` +
    `Mode,` +
    `Vis Contamination,` +
    `Full Packet` +
    '\n'
  ;
};