import React, { createContext, useReducer } from 'react';

const initialState = {
	deviceImages: {},
};

const actions = {
	SET_IMAGES: 'SET_IMAGES',
	RESET_IMAGES: 'RESET_IMAGES',
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.SET_IMAGES:
			return { ...state, deviceImages: action.images };
		case actions.RESET_IMAGES:
			return { ...state, ...initialState };
		default:
			return state;
	}
};

export const LatestDeviceImagesContext = createContext();

const LatestDeviceImagesProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = {
		deviceImages: state.deviceImages,
		setDeviceImages: (images) => {
			dispatch({ type: actions.SET_IMAGES, images: images });
		},
		resetDeviceImages: () => {
			dispatch({ type: actions.RESET_IMAGES });
		},
	};

	return (
		<LatestDeviceImagesContext.Provider value={value}>
			{children}
		</LatestDeviceImagesContext.Provider>
	);
};

export default LatestDeviceImagesProvider;
