/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import wretch from 'wretch';
import moment from 'moment';
import { NotificationsContext } from '__state/Notifications';
import __remove from 'lodash/remove';

import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Divider from '@material-ui/core/Divider';

const Notifications = () => {
	const { username, token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [isLoading, setIsLoading] = useState(false);
	const { notifications, setNotifications } = useContext(NotificationsContext);

	const archiveNotification = async (e, deviceNotificationId) => {
		e.preventDefault();

		try {
			await wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/notifications/archive`)
				.auth(`Bearer ${token}`)
				.post({
					deviceNotificationId,
				})
				.json();

			let remainingNotifications = notifications;
			__remove(remainingNotifications, (notification) => {
				return notification.deviceNotificationId === deviceNotificationId;
			});

			setNotifications([...remainingNotifications]);
		} catch (error) {
			console.log(error);

			if (error.text && JSON.parse(error.text).type === 'token') {
				alert('Your session has expired. Please log in again.');
				return;
			}

			console.error('There was a problem deleting your notification.');
		}
	};

	const archiveAll = async () => {
		if (isLoading) {
			return;
		}

		if (!username) {
			alert('There was a problem deleting your notifications.');
			return;
		}

		setIsLoading(true);

		try {
			await wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/notifications/archive/all`)
				.auth(`Bearer ${token}`)
				.post({ username })
				.json();

			setNotifications([]);
			alert('All notifications have been deleted.');
		} catch (error) {
			console.log(error);

			if (error.text && JSON.parse(error.text).type === 'token') {
				alert('Your session has expired. Please log in again.');
				return;
			}

			console.error('There was a problem deleting your notifications.');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="notifications-view">
			<div className="info-block">
				{notifications.length > 0 ? (
					notifications.map(
						(
							{ deviceNotificationId, imei, rule, actual, timestamp, threshold, title, unit },
							i,
						) => {
							let statement = '';
							const unitText = unit === 'null' ? '' : ` ${unit}`;
							const secondary = moment(timestamp).format('YYYY-MM-DD HH:mm:ss');

							if (title.toLowerCase() === 'tamper') {
								statement = `${imei} was tampered`;
							} else {
								statement = `${imei} was ${rule} ${threshold}${unitText} for ${title.toLowerCase()}, reaching ${actual.replace(
									/SM/gi,
									'',
								)}${unitText}.`;
							}

							return (
								<div key={i}>
									<div className="info-row">
										<div>
											<div
												className="delete-wrapper"
												role="button"
												title="Archive notification"
												onClick={(e) => archiveNotification(e, deviceNotificationId)}>
												<DeleteIcon />
											</div>
										</div>
										<p className="info-data">
											<span className="timestamp">{secondary}</span>
											<span className="statement">{statement}</span>
										</p>
									</div>
									{i < notifications.length - 1 && <Divider />}
								</div>
							);
						},
					)
				) : (
					<p>No new notifications</p>
				)}
			</div>
			{notifications.length > 0 && (
				<div className="delete-all">
					<div role="button" onClick={archiveAll} data-is-loading={isLoading}>
						<DeleteSweepIcon />
						<span>Delete All Notifications</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default Notifications;
