import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@curi/react-dom';
import wretch from 'wretch';

import './Login.scss';

import Logo from '__assets/img/qlogo-color.svg';

const Login = ({ router }) => { 
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [triesLeft, setTriesLeft] = useState(null);
	const [showTriesLeft, setShowTriesLeft] = useState(false);
	const [isAcctLocked, setIsAcctLocked] = useState(false);
	const [isFormLoading, setIsFormLoading] = useState(false);
	const [showTermsError, setShowTermsError] = useState(false);
	const [showDeactivatedError, setShowDeactivatedError] = useState(false);

	const submitLogin = (e) => {
		e.preventDefault();
		setIsFormLoading(true);
		
		wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/login`)
			.post({
				username: username,
				password: password,
			})
			.unauthorized(({ message }) => {
				//send the user to the terms page
				const error = JSON.parse(message);
				console.log(error);

				if (error.type === 'credentials') {
					const { data } = error;
					if (data && typeof data.triesLeft === 'number') {
						const isLocked = data.triesLeft === 0;
						setTriesLeft(data.triesLeft);
						setIsAcctLocked(isLocked);
						setShowTriesLeft(true);

						if (isLocked) {
							// don't allow the alert to trigger
							return;
						}
					}
					setShowDeactivatedError(false);
					setShowTermsError(false);

					alert('Please check your credentials and try again.');
				}
				else if(error.type === 'account-deactivated'){
					setShowDeactivatedError(true);
					setShowTermsError(false);
					setShowTriesLeft(false);
				} 
				else if(error.type === 'not-active'){
					setShowTermsError(true);
					setShowDeactivatedError(false);
					setShowTriesLeft(false);
				}
				else{
					setShowTermsError(false);
					setShowDeactivatedError(false);
					setShowTriesLeft(false);

					console.error(JSON.stringify(error));
					alert('Something went wrong while signing in. Please try again.');
				}
			})
			.error(500, (error) => {
				console.error(JSON.stringify(error));
				alert('There was an issue logging you in. Please contact us.');
			})
			.json((data) => {
				sessionStorage.setItem('selfData', JSON.stringify(data));

				const url = router.url({ name: 'WeatherDisplay' });
				router.navigate({ url });
			})
			.finally(() => {
				setIsFormLoading(false);
			});
	};

	return (
		<div className="login-wrapper">
			<div className="login-form-container">
				<div className="form-logo">
					<img alt="Quantimet from IntelliSense Systems" src={Logo} />
				</div>
				{typeof triesLeft == 'number' && !isAcctLocked && showTriesLeft &&(
					<p className="tries-left">
						You have {triesLeft} attempt{triesLeft === 1 ? '' : 's'} left before your account is
						locked.
					</p>
				)}
				{isAcctLocked && (
					<p className="tries-left">
						Your account has been locked for 5 minutes. Please try again later.
					</p>
				)}
				{showDeactivatedError && (
						<p className="tries-left">
						Your account has been deactivated.
					</p>
					)}
				<form className="login-form" onSubmit={submitLogin}>
					<div className="form-row">
						<label htmlFor="login-username">Email:</label>
						<input
							required
							id="login-username"
							type="email"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							disabled={isAcctLocked}
						/>
					</div>
					<div className="form-row">
						<label htmlFor="login-password">Password:</label>
						<input
							required
							id="login-password"
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							disabled={isAcctLocked}
						/>
					</div>
					{!isAcctLocked && (
						<div className="form-row">
							<div></div>
							<div className="forgot-password">
								<Link name="ForgotPassword">Forgot Password?</Link>
							</div>
						</div>
					)}
					<div className="form-row submit">
						<input type="submit" value="Log In" disabled={isFormLoading || isAcctLocked} />
					</div>
				</form>
				<Link
					name="Terms"
					state={{ username: username, password: password }}
					className={`terms-error ${showTermsError ? 'show' : ''}`}>
					Your account is not yet active. Please click here to accept our terms.
				</Link>
			</div>
		</div>
	);
};

Login.propTypes = {
	router: PropTypes.object.isRequired,
	response: PropTypes.object.isRequired,
};

export default Login;
