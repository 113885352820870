import React, { useState } from 'react';
import PropTypes from 'prop-types';
import wretch from 'wretch';
import { Link } from '@curi/react-dom';

import './ForgotPassword.scss';

import Logo from '__assets/img/qlogo-color.svg';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [isFormLoading, setIsFormLoading] = useState(false);
	const [confirmType, setConfirmType] = useState(null);

	const submitForgotPassword = async (e) => {
		e.preventDefault();

		await wretch(`${process.env.REACT_APP_UTIL_API_URL}/inquiry`)
			.post({
				email: email,
				message: `Please reset the password for this email address: ${email}. Thanks!`,
			})
			.error(500, (error) => {
				console.log(error);
				setConfirmType('error');
			})
			.json(() => {
				setIsFormLoading(false);
				setConfirmType('success');
			});

		setTimeout(() => {
			setConfirmType(null);
		}, 7000);
	};

	return (
		<div className="fp-wrapper">
			<div className="fp-form-container">
				<div className="form-logo">
					<img alt="IntelliSense Systems" src={Logo} />
				</div>
				<form className="fp-form" onSubmit={submitForgotPassword}>
					<div className="form-row">
						<label htmlFor="fp-email">Email Address</label>
						<br />
						<input
							required
							id="fp-email"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="form-row">
						<input type="submit" value="Send Email" disabled={isFormLoading} />
					</div>
					<div className="form-row">
						<div></div>
						<div className="confirm-block">
							<div
								className={`confirmation-message success ${
									confirmType === 'success' ? 'show' : ''
								}`}>
								The email was successfully sent.
							</div>
							<div
								className={`confirmation-message error ${confirmType === 'error' ? 'show' : ''}`}>
								Sorry, there was an error sending the email.
								<br />
								Please try again or email us directly at quantimet@intellisenseinc.com.
							</div>
						</div>
					</div>
				</form>
				<div className="more-login-actions">
					<Link name="Login">Back to Login</Link>
					<Link name="Register">Request Account</Link>
				</div>
			</div>
		</div>
	);
};

ForgotPassword.propTypes = {
	router: PropTypes.object.isRequired,
	response: PropTypes.object.isRequired,
};

export default ForgotPassword;
