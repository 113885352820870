import { prepareRoutes } from '@curi/router';

import Login from './containers/Login';
import Terms from './containers/Login/Terms';
import Register from './containers/Register';
import ForgotPassword from './containers/ForgotPassword';
import WeatherDisplay from './containers/WeatherDisplay';
import WeatherDisplayMobile from './containers/WeatherDisplayMobile';
import MyAccount from './containers/MyAccount';
import Help from './containers/Help';
import Error from './containers/Error';

const hasToken = () => {
	return sessionStorage.getItem('selfData') !== null;
};

const WeatherDisplayBody = window.innerWidth < 768 ? WeatherDisplayMobile : WeatherDisplay;

export default prepareRoutes([
	{
		name: 'Login',
		path: '',
		respond() {
			//having a token means they are logged in
			if (hasToken()) {
				return {
					redirect: { name: 'WeatherDisplay' },
				};
			}

			return {
				body: Login,
			};
		},
	},
	{
		name: 'Terms',
		path: 'terms',
		respond() {
			return {
				body: Terms,
			};
		},
	},
	{
		name: 'Register',
		path: 'register',
		respond() {
			//having a token means they are logged in
			if (hasToken()) {
				return {
					redirect: { name: 'WeatherDisplay' },
				};
			}

			return {
				body: Register,
			};
		},
	},
	{
		name: 'ForgotPassword',
		path: 'forgot-password',
		respond() {
			return {
				body: ForgotPassword,
			};
		},
	},
	{
		name: 'WeatherDisplay',
		path: 'weather-display',
		respond() {
			if (!hasToken()) {
				return {
					redirect: { name: 'Login' },
				};
			}

			return {
				body: WeatherDisplayBody,
			};
		},
	},
	{
		name: 'MyAccount',
		path: 'account',
		respond() {
			if (!hasToken()) {
				return {
					redirect: { name: 'Login' },
				};
			}

			return {
				body: MyAccount,
			};
		},
	},
	{
		name: 'AccountInformation',
		path: 'account/info',
		respond() {
			if (!hasToken()) {
				return {
					redirect: { name: 'Login' },
				};
			}

			return {
				body: MyAccount,
			};
		},
	},
	{
		name: 'AccountGroupInformation',
		path: 'account/group',
		respond() {
			if (!hasToken()) {
				return {
					redirect: { name: 'Login' },
				};
			}

			return {
				body: MyAccount,
			};
		},
	},
	{
		name: 'MyDevices',
		path: 'account/devices',
		respond() {
			if (!hasToken()) {
				return {
					redirect: { name: 'Login' },
				};
			}

			return {
				body: MyAccount,
			};
		},
	},
	{
		name: 'Notifications',
		path: 'account/notifications',
		respond() {
			if (!hasToken()) {
				return {
					redirect: { name: 'Login' },
				};
			}

			return {
				body: MyAccount,
			};
		},
	},
	{
		name: 'Settings',
		path: 'account/settings',
		respond() {
			if (!hasToken()) {
				return {
					redirect: { name: 'Login' },
				};
			}

			return {
				body: MyAccount,
			};
		},
	},
	{
		name: 'Help',
		path: 'help',
		respond() {
			if (!hasToken()) {
				return {
					redirect: { name: 'Login' },
				};
			}

			return {
				body: Help,
			};
		},
	},
	{
		name: 'Error',
		path: '(.*)',
		respond() {
			return {
				body: Error,
			};
		},
	},
]);
