import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ViewChoiceContext } from '__state/ViewChoice';
import __upperCase from 'lodash/upperCase';
import {
	VIEW_G_EXPORT,
	VIEW_G_COMMAND,
	VIEW_TABLE,
	VIEW_PLOTS,
	VIEW_EXPORT,
	VIEW_COMMAND,
	VIEW_DASHBOARD,
	VIEW_NOTIFICATIONS,
} from 'utils/constants';

import './ViewChooser.scss';

const ViewChooser = ({ type, views, router, response }) => {
	const { devicesView, entriesView, setDevicesView, setEntriesView } =
		useContext(ViewChoiceContext);
	const setView = (view) => {
		// TODO: remove entries view state in favor of query params - right
		// now, mobile uses hashes and it's not optimized
		// example: url?imei=____&view=___
		if (router) {
			const url = router.url({
				name: 'WeatherDisplay',
				hash: `#${view.toLowerCase()}`,
			});
			router.navigate({ url });
			return;
		}

		return type === 'devices' ? setDevicesView(view) : setEntriesView(view);
	};

	const setHighlightClass = (view) => {
		if (response) {
			if (response.location.hash === view.toLowerCase()) {
				return 'highlight';
			}
		} else if (devicesView === view || entriesView === view) {
			return 'highlight';
		}

		return '';
	};

	return (
		<div className="view-chooser">
			{views.map((view, i) => (
				<div
					key={i}
					role="button"
					className={`view-choice ${setHighlightClass(view)}`}
					onClick={() => setView(view)}>
					{__upperCase(view)}
				</div>
			))}
		</div>
	);
};

ViewChooser.propTypes = {
	type: PropTypes.oneOf(['devices', 'entries']),
	views: PropTypes.arrayOf(
		PropTypes.oneOf([
			VIEW_G_EXPORT,
			VIEW_G_COMMAND,
			VIEW_TABLE,
			VIEW_PLOTS,
			VIEW_EXPORT,
			VIEW_COMMAND,
			VIEW_DASHBOARD,
			VIEW_NOTIFICATIONS,
		]),
	).isRequired,
};

export default ViewChooser;
