import React, { useState } from 'react';
import PropTypes from 'prop-types';
import wretch from 'wretch';

import './Login.scss';

import Logo from '__assets/img/qlogo-color.svg';
import PDF  from '__assets/pdf/quantimet_terms.pdf';

const Terms = ({ router, response }) => {
	const username = response.location.state.username;
	const password = response.location.state.password;
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [isFormLoading, setIsFormLoading] = useState(false);

	const submitLogin = (e) => {
		e.preventDefault();
		setIsFormLoading(true);

		wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/login`)
			.post({
				username: username,
				password: password,
				acceptedTerms: true,
			})
			.error(500, (error) => {
				console.error(JSON.stringify(error));
				alert('There was an issue logging you in. Please contact us.');
			})
			.json((data) => {
				sessionStorage.setItem('selfData', JSON.stringify(data));

				const url = router.url({ name: 'WeatherDisplay' });
				router.navigate({ url });
			})
			.finally(() => {
				setIsFormLoading(false);
			});
	};

	return (
		<div className="terms-wrapper">
			<div className="terms-form-container">
				<div className="form-logo">
					<img alt="IntelliSense Systems" src={Logo} />
				</div>
				<div className="terms">
					<iframe src={PDF} width="100%" height="100%" title="Terms &amp; Conditions"></iframe>
				</div>
				<form className="terms-form" onSubmit={submitLogin}>
					<label>
						<input
							type="checkbox"
							checked={isConfirmed}
							onChange={(e) => {
								setIsConfirmed(e.target.checked);
							}}
						/>
						I understand and accept the terms and conditions above.
					</label>
					<div className="form-row submit">
						<input
							type="submit"
							value="Submit"
							disabled={isFormLoading || !isConfirmed || !username || !password}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

Terms.propTypes = {
	router: PropTypes.object.isRequired,
	response: PropTypes.object.isRequired,
};

export default Terms;
