import React, { useState, useEffect, useContext } from 'react';
import wretch from 'wretch';
import moment from 'moment';
import { NotificationsContext } from '__state/Notifications';
import __remove from 'lodash/remove';

import './FooterMobile.scss';

import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';

const FooterMobile = ({ router, setIsLoggingOut }) => {
	const { username, token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [isLoading, setIsLoading] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);
	const { notifications, setNotifications } = useContext(NotificationsContext);

	useEffect(() => {
		const getUserNotifications = async () => {
			try {
				const loadedNotifications = await wretch(
					`${process.env.REACT_APP_UTIL_API_URL}/user/notifications/${username}`,
				)
					.auth(`Bearer ${token}`)
					.get()
					.json();
				loadedNotifications.sort((a, b) => {
					return moment(b.timestamp) - moment(a.timestamp);
				});
				setNotifications([...loadedNotifications]);
			} catch (error) {
				console.log(error);

				if (error.text && JSON.parse(error.text).type === 'token') {
					alert('Your session has expired. Please log in again.');
					return;
				}

				console.error("Can't get notifications.");
			}
		};

		getUserNotifications();

		if (window.innerWidth < 768) {
			setInterval(() => {
				getUserNotifications();
			}, 1000 * 60 * 5); // 5 minutes
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username]);

	useEffect(() => {
		if (showNotifications) {
			document.querySelector('.notifications-drawer .inner').scrollTop = 0;
		}
	}, [showNotifications]);

	const logout = () => {
		if (setIsLoggingOut) {
			setIsLoggingOut(true);
		}

		sessionStorage.removeItem('selfData');

		const url = router.url({ name: 'Login' });
		router.navigate({ url });
	};

	const archiveNotification = async (e, deviceNotificationId) => {
		e.preventDefault();

		try {
			await wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/notifications/archive`)
				.auth(`Bearer ${token}`)
				.post({
					deviceNotificationId,
				})
				.json();

			let remainingNotifications = notifications;
			__remove(remainingNotifications, (notification) => {
				return notification.deviceNotificationId === deviceNotificationId;
			});

			setNotifications([...remainingNotifications]);
		} catch (error) {
			console.log(error);

			if (error.text && JSON.parse(error.text).type === 'token') {
				alert('Your session has expired. Please log in again.');
				return;
			}

			console.error('There was a problem deleting your notification.');
		}
	};

	const archiveAll = async () => {
		if (isLoading) {
			return;
		}

		if (!username) {
			alert('There was a problem deleting your notifications.');
			return;
		}

		setIsLoading(true);

		try {
			await wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/notifications/archive/all`)
				.auth(`Bearer ${token}`)
				.post({
					username: username,
				})
				.json();
			setNotifications([]);
			alert('All notifications have been deleted.');
		} catch (error) {
			console.log(error);

			if (error.text && JSON.parse(error.text).type === 'token') {
				alert('Your session has expired. Please log in again.');
				return;
			}

			console.error('There was a problem deleting your notifications.');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="footer-mobile-component">
			<div className="menu">
				<div className="help mobile-option">
					<a href="mailto:admin@quantimet.com?subject=Quantimet%20Help%20Inquiry">
						<div role="button" className="user-option help">
							<HelpIcon />
							<span>Help</span>
						</div>
					</a>
				</div>
				<div className="notifications mobile-option">
					<div role="button" onClick={() => setShowNotifications(true)}>
						<div className="icon">
							<NotificationsIcon />
							{notifications.length > 0 && (
								<div className="notifications-count">
									{notifications.length > 99 ? '99+' : notifications.length}
								</div>
							)}
						</div>
						<span>Notifications</span>
					</div>
				</div>
				<div className="sign-out mobile-option">
					<div role="button" className="user-option" onClick={logout}>
						<ExitToAppIcon />
						<span>Sign Out</span>
					</div>
				</div>
			</div>
			<div className="notifications-drawer" data-show={showNotifications}>
				<div className="inner">
					{notifications.length > 0 ? (
						notifications.map(
							(
								{ deviceNotificationId, imei, rule, actual, timestamp, threshold, title, unit },
								i,
							) => {
								let statement = '';
								const unitText = unit === 'null' ? '' : ` ${unit}`;
								const secondary = moment(timestamp).format('YYYY-MM-DD HH:mm:ss');

								if (title.toLowerCase() === 'tamper') {
									statement = `${imei} was tampered`;
								} else {
									statement = `${imei} was ${rule} ${threshold}${unitText} for ${title.toLowerCase()}, reaching ${actual.replace(
										/SM/gi,
										'',
									)}${unitText}.`;
								}

								return (
									<div key={i}>
										<div className="info-row">
											<div>
												<div
													className="delete-wrapper"
													role="button"
													title="Archive notification"
													onClick={(e) => archiveNotification(e, deviceNotificationId)}>
													<DeleteIcon />
												</div>
											</div>
											<p className="info-data">
												<span className="timestamp">{secondary}</span>
												<span className="statement">{statement}</span>
											</p>
										</div>
										{i < notifications.length - 1 && <Divider />}
									</div>
								);
							},
						)
					) : (
						<div className="no-notifications">No new notifications</div>
					)}
				</div>
				<div className="drawer-actions">
					<div className="close-button" onClick={() => setShowNotifications(false)}>
						<CloseIcon />
						<span>Close</span>
					</div>
					{notifications.length > 0 && (
						<div
							role="button"
							className="delete-button"
							onClick={archiveAll}
							data-is-loading={isLoading}>
							<DeleteSweepIcon />
							<span>Delete All Notifications</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FooterMobile;
