/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { PaginationContext } from '__state/Pagination';
import __isEmpty from 'lodash/isEmpty';

import './Table.scss';

import ReactPaginate from 'react-paginate';
import { useTable, useSortBy, useExpanded, usePagination } from 'react-table';

const Table = ({
	columns,
	data,
	getRowProps = () => ({}),
	getHeaderProps = () => ({}),
	renderRowSubComponent = () => ({}),
	showPagination = false,
	selectedPrimaryRow = -1,
	selectedPageSize = 10,
	expandedRows,
	customPageCount,
	totalDataCount = null,
	onNextClick,
	sortedCols,
	resetTablePages,
	setResetTablePages,
}) => {
	totalDataCount = totalDataCount || data.length;

	const [openedRows, setOpenedRows] = useState([]);
	const [sortedColumns, setSortedColumns] = useState([]);
	const { showFirstEntriesPage } = useContext(PaginationContext);
	const [masterPageIndex, setMasterPageIndex] = useState(0);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		flatColumns,
		state: { pageSize },

		//for usePagination
		page,
		gotoPage,
		setPageSize,
	} = useTable(
		{
			columns,
			data,
			useControlledState: (state) => {
				return useMemo(
					() => ({
						...state,
						expanded: openedRows || [],
						sortBy: sortedColumns || [],
						pageIndex: masterPageIndex || 0,
					}),
					// eslint-disable-next-line react-hooks/exhaustive-deps
					[state, openedRows, sortedColumns, masterPageIndex],
				);
			},
		},
		useSortBy,
		useExpanded,
		usePagination,
	);

	useEffect(() => {
		setPageSize(selectedPageSize);
		setMasterPageIndex(0);
	}, [selectedPageSize, setPageSize]);

	useEffect(() => {
		setOpenedRows(expandedRows);
	}, [expandedRows]);

	useEffect(() => {
		if (!__isEmpty(sortedCols)) {
			setSortedColumns(sortedCols);
		}
	}, [sortedCols]);

	useEffect(() => {
		setMasterPageIndex(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalDataCount]);

	useEffect(() => {
		if (resetTablePages) {
			setMasterPageIndex(0);
			setResetTablePages(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetTablePages]);

	const handlePageChange = (selected) => {
		gotoPage(selected);

		// const newPageIndex = masterPageIndex + 1;
		if (masterPageIndex !== selected) {
			onNextClick(pageSize);
			setMasterPageIndex(selected);
		}
	};

	const paginationOpts = {
		breakLabel: '...',
		breakClassName: 'break-me',
		pageCount: customPageCount,
		marginPagesDisplayed: 2,
		pageRangeDisplayed: 5,
		containerClassName: 'pagination',
		subContainerClassName: 'pages pagination',
		activeClassName: 'active',
		forcePage: masterPageIndex,
		onPageChange: ({ selected }) => handlePageChange(selected),
		previousLabel: <div>prev</div>,
		nextLabel: <div>next</div>,
	};

	const maxEntriesOnCurrentPage = pageSize * masterPageIndex + pageSize;
	const currentItemStart = totalDataCount === 0 ? 0 : pageSize * masterPageIndex + 1;

	if (showFirstEntriesPage) {
		paginationOpts.forcePage = 0;
	}

	return (
		<>
			<div className="weather-display-table">
				<div className="table" {...getTableProps()}>
					<div className="thead">
						{headerGroups.map((headerGroup) => (
							<div className="tr" {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<div
										className={`th ${column.className}`}
										{...column.getHeaderProps({
											...column.getSortByToggleProps({
												title:
													column.className && column.className.includes('expand-all-button')
														? column.className.includes('all-expand')
															? 'Expand All'
															: 'Collapse All'
														: 'Sort',
											}),
											...getHeaderProps(column),
										})}>
										{column.render('Header')}
										<span className="sort-icon">
											{column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
										</span>
									</div>
								))}
							</div>
						))}
					</div>
					<div className="tbody" {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);

							return (
								<React.Fragment key={Math.random() * 999999}>
									<div
										{...row.getRowProps(getRowProps(row))}
										className={`tr
                                        ${row.isExpanded ? 'expanded' : ''} 
                                        ${selectedPrimaryRow === row.index ? 'selected' : ''}
                                    `}>
										{row.cells.map((cell) => {
											return (
												<div className="td" {...cell.getCellProps()}>
													{cell.render('Cell')}
												</div>
											);
										})}
									</div>
									{row.isExpanded ? (
										<div className="tr sub-row">
											<div className="td" colSpan={flatColumns.length}>
												{renderRowSubComponent({ row })}
											</div>
										</div>
									) : null}
								</React.Fragment>
							);
						})}
					</div>
				</div>
			</div>
			{showPagination && (
				<div className="table-pagination-wrapper">
					<div className="table-page-info">
						Showing {currentItemStart} to{' '}
						{maxEntriesOnCurrentPage <= totalDataCount ? maxEntriesOnCurrentPage : totalDataCount}{' '}
						entries of {totalDataCount}
					</div>
					<ReactPaginate {...paginationOpts} />
				</div>
			)}
		</>
	);
};

Table.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	getRowProps: PropTypes.func,
	renderRowSubComponent: PropTypes.func,
	showPagination: PropTypes.bool,
	selectedPrimaryRow: PropTypes.number,
	selectedPageSize: PropTypes.number,
	defaultExpanded: PropTypes.array,
};

export default Table;
