import React from 'react';

const Error = () => {
	return (
		<div className="page-wrapper">
			<p>The page you requested does not exist. Please go back.</p>
		</div>
	);
};

export default Error;
