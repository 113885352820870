import React, { createContext, useReducer } from 'react';
import __uniqBy from 'lodash/uniqBy';

const initialState = {
	cachedDeviceEntries: {},
};

const actions = {
	ADD_ENTRIES: 'ADD_ENTRIES',
	RESET_ENTRIES: 'RESET_ENTRIES',
};

const reducer = (state, action) => {
	const { deviceId, entries } = action;
	switch (action.type) {
		case actions.ADD_ENTRIES:
			let tempEntries = state.cachedDeviceEntries;
			tempEntries[deviceId] = tempEntries[deviceId]
				? tempEntries[deviceId].concat(entries)
				: entries;
			tempEntries[deviceId] = __uniqBy(tempEntries[deviceId], 'timestamp');
			return { ...state, cachedDeviceEntries: { ...tempEntries } };
		case actions.RESET_ENTRIES:
			let tempEntries1 = state.cachedDeviceEntries;
			tempEntries1[deviceId] = [];
			return { ...state, cachedDeviceEntries: tempEntries1 };
		default:
			return state;
	}
};

export const DeviceEntriesContext = createContext();

const DeviceEntriesProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = {
		cachedDeviceEntries: state.cachedDeviceEntries,
		addDeviceEntries: (deviceId, entries) => {
			dispatch({
				type: actions.ADD_ENTRIES,
				deviceId: deviceId,
				entries: entries,
			});
		},
		resetDeviceEntries: (deviceId) => {
			dispatch({ type: actions.RESET_ENTRIES, deviceId: deviceId });
		},
	};

	return <DeviceEntriesContext.Provider value={value}>{children}</DeviceEntriesContext.Provider>;
};

export default DeviceEntriesProvider;
