import React, { useState } from 'react';
import wretch from 'wretch';
import { useDeepEffect } from '../../utils/hooks';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

const AccountInfo = () => {
	const selfData = JSON.parse(sessionStorage.getItem('selfData')),
		{ userId, token, metricValOn } = selfData;
	const [isChangeMade, setIsChangeMade] = useState(false);
	const [settings, setSettings] = useState({
		// use keys from API for clean mapping
		metricValOn,
	});

	useDeepEffect(() => {
		const updateSettings = async () => {
			try {
				await wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/${userId}/update`)
					.auth(`Bearer ${token}`)
					.post(settings);

				sessionStorage.setItem('selfData', JSON.stringify({ ...selfData, ...settings }));
			} catch (error) {
				console.log(error);

				if (error.text && JSON.parse(error.text).type === 'token') {
					alert('Your session has expired. Please log in again.');
					return;
				}

				alert('There was a problem updating your settings. Please try again or contact us.');
			} finally {
				setIsChangeMade(false);
			}
		};

		if (isChangeMade) {
			updateSettings();
		}
		return () => {};
	}, [isChangeMade]);

	const handleSettingsChange = (e) => {
		setSettings({ ...settings, [e.target.name]: e.target.checked });
		setIsChangeMade(true);
	};

	return (
		<div className="settings-view">
			<div className="info-block">
				<div className="info-row">
					<p className="info-title">Units Display:</p>
					<p className="info-data">
						<Grid component="label" container alignItems="center" spacing={1}>
      					<Grid item>&nbsp;&nbsp;&nbsp;View All (Default)</Grid>
      					<Grid item>
        					<Switch
          						checked={settings.metricValOn} // relevant state for your case
          						onChange={handleSettingsChange} // relevant method to handle your change
          						name="metricValOn" // some value you need
        					/>
      					</Grid>
      					<Grid item>Metric Only (does not affect Clouds)</Grid>
						</Grid>
					</p>
				</div>
			</div>
		</div>
	);
};

export default AccountInfo;
