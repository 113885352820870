import React, { createContext, useReducer } from 'react';

const initialState = {
	isOpen: false,
	graphToShow: '',
	entries: [],
	deviceType: '',
	chartFigure: 'line',
};

const actions = {
	OPEN_GRAPH: 'OPEN_GRAPH',
	CLOSE_GRAPH: 'CLOSE_GRAPH',
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.OPEN_GRAPH:
			const { graphToShow, entries, deviceType, chartFigure, showMetricOnly } = action;
			return {
				...state,
				isOpen: true,
				graphToShow,
				entries,
				deviceType,
				chartFigure: chartFigure || state.chartFigure,
				showMetricOnly,
			};
		case actions.CLOSE_GRAPH:
			return initialState;
		default:
			return state;
	}
};

export const LargeGraphViewContext = createContext();

const LargeGraphViewProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = {
		isOpen: state.isOpen,
		graphToShow: state.graphToShow,
		entries: state.entries,
		deviceType: state.deviceType,
		chartFigure: state.chartFigure,
		showMetricOnly: state.showMetricOnly,
		openLargeGraph: (graphToShow, entries, deviceType, chartFigure, showMetricOnly) => {
			dispatch({
				type: actions.OPEN_GRAPH,
				graphToShow,
				entries,
				deviceType,
				chartFigure,
				showMetricOnly,
			});
		},
		closeLargeGraph: () => {
			dispatch({ type: actions.CLOSE_GRAPH });
		},
	};

	return <LargeGraphViewContext.Provider value={value}>{children}</LargeGraphViewContext.Provider>;
};

export default LargeGraphViewProvider;
