import React, { useContext } from 'react';
import moment from 'moment';
import { DeviceEntriesContext } from '__state/DeviceEntries';
import { isTampered } from 'utils/functions';
import config from 'utils/config';
import __find from 'lodash/find';

const DashboardView = ({ device }) => {
	const selfData = JSON.parse(sessionStorage.getItem('selfData')),
		{ metricValOn } = selfData;
	const { cachedDeviceEntries } = useContext(DeviceEntriesContext);
	const latestEntry = cachedDeviceEntries[device.id] ? cachedDeviceEntries[device.id][0] : null;
	const data1HourAgo = __find(cachedDeviceEntries[device.id], (entry) => {
		return moment(latestEntry.timestamp).subtract(1, 'hour').valueOf() === entry.timestamp;
	});

	if (!latestEntry || (!latestEntry.METARInternational && !latestEntry.METAR)) {
		return null;
	}

	const showDashboard = () => {
		// common attributes across device types
		const isLightningActive =
			!isNaN(latestEntry.lightningFreq.value) && parseInt(latestEntry.lightningFreq.value) > 0;
		const isRainActive =
			!isNaN(latestEntry.precip.value) && parseFloat(latestEntry.precip.value) > 0;
		const isCloudsActive =
			latestEntry.cloudLayers.value !== 'No Ceilometer' &&
			latestEntry.cloudLayers.value !== 'NCD' &&
			latestEntry.cloudLayers.value !== 'Ceilometer OFF';
		const isTamperingActive = isTampered(latestEntry, data1HourAgo);

		const batteryValue = parseFloat(latestEntry.battery.value);
		const batteryLevel =
			batteryValue >= 5 ? 'good' : batteryValue >= 4.8 && batteryValue < 5 ? 'meh' : 'bad';
		const showMetricOnly = metricValOn;

		const props = {
			device,
			latestEntry,
			isLightningActive,
			isRainActive,
			isCloudsActive,
			isTamperingActive,
			batteryLevel,
			showMetricOnly,
		};

		return config[device.type]['dashboard'](props);
	};

	return <div className="dashboard-view">{showDashboard()}</div>;
};

export default DashboardView;
