import moment from 'moment-timezone';
import __isEmpty from 'lodash/isEmpty';
import __forEach from 'lodash/forEach';
import { getMETAR } from 'utils/functions';
import { createIWOSCSVHeader } from '../helpers';

moment.tz.setDefault('UTC');

// forces all programs opening up csv to use UTF-8 encoding
const BOM = '\uFEFF';

const returnIWOSExport = (entries, deviceTypeOfficial, showMetricOnly) => {
  let allCSVs = {};

  __forEach(entries, (entrySet, deviceIMEI) => {
    let csv = createIWOSCSVHeader(showMetricOnly);

    __forEach(entrySet, (entry) => {
      if (
        (entry.METARInternational && entry.METARInternational.value) ||
        (entry.METAR && entry.METAR.value)
      ) {
        let cloudLayers = entry.cloudLayers ? entry.cloudLayers.value : '';
        cloudLayers = cloudLayers.includes(',') ? `"${cloudLayers}"` : cloudLayers;

        csv += `"${entry.imei}",`;
        csv += `${getMETAR(entry)},`;
        csv += `${moment(entry.timestamp).format('MM/D/YYYY HH:mm')},`;
        csv += `${deviceTypeOfficial},`;
        csv += `Weather,`;
        csv += `${entry.windDir2min ? entry.windDir2min.value : ''},`;
                  
        if (showMetricOnly) {
          csv += `${entry.windSpeedMps ? entry.windSpeedMps.value : ''},`;
          csv += `${entry.visibilityMeters ? entry.visibilityMeters.value : ''},`;
          csv += `${entry.weather ? entry.weather.value : ''},`;
          csv += `${cloudLayers},`;
          csv += `${entry.temperatureC ? entry.temperatureC.value : ''},`;
          csv += `${entry.dewPoint ? entry.dewPoint.value : ''},`;
          csv += `${entry.humidity ? entry.humidity.value : ''},`;
          csv += `${entry.altimeterMb ? entry.altimeterMb.value : ''},`;
          csv += `${entry.lightningFreq ? entry.lightningFreq.value : ''},`;
          csv += `${entry.lightningDist ? `"${entry.lightningDist.value}"` : ''},`;
          csv += `${
            entry.lowestCloud
              ? entry.lowestCloud.value == '0'
                ? ''
                : `"${entry.lowestCloud.value}"`
              : ''
          },`;
          csv += `${entry.sigWaveDir ? entry.sigWaveDir.value : ''},`;
          csv += `${entry.sigWavePrd ? entry.sigWavePrd.value : ''},`;
          csv += `${entry.sigWaveHgt ? entry.sigWaveHgt.value : ''},`;
          csv += `${entry.maxWindDir ? entry.maxWindDir.value : ''},`;
          csv += `${entry.maxWindSpeedMps ? entry.maxWindSpeedMps.value : ''},`;
          csv += `${entry.precipMm ? entry.precipMm.value : ''},`;
          csv += `${entry.windChillIndex ? entry.windChillIndex.value : ''},`;
          csv += `${entry.heatIndex ? entry.heatIndex.value : ''},`;
          
          const pressure = __isEmpty(entry.haPressure) ? entry.stationPressure : entry.haPressure;
          csv += `${pressure ? pressure.value : ''},`;

          csv += `${entry.pressureAlt ? entry.pressureAlt.value : ''},`;
          csv += `${entry.denseAlt ? entry.denseAlt.value : ''},`;
          csv += `${entry.seaTemp ? entry.seaTemp.value : ''},`;
          csv += `${entry.compass ? entry.compass.value : ''},`;
          csv += `${entry.lat ? entry.lat.value : ''},`;
          csv += `${entry.lon ? entry.lon.value : ''},`;
          csv += `${entry.elevation ? entry.elevation.value : ''},`;
          csv += `${entry.tip ? entry.tip.value : ''},`;
          csv += `${entry.orientation ? entry.orientation.value : ''},`;
          csv += `${entry.battery ? entry.battery.value : ''},`;
          csv += `${entry.mode ? entry.mode.value : ''},`;
          csv += `${entry.sediment ? entry.sediment.value : ''},`;
        } else {
          csv += `${entry.windSpeed ? entry.windSpeed.value : ''},`;
          csv += `${entry.visibility ? entry.visibility.value : ''},`;
          csv += `${entry.weather ? entry.weather.value : ''},`;
          csv += `${cloudLayers},`;
          csv += `${entry.temperatureF ? entry.temperatureF.value : ''},`;
          csv += `${entry.dewPointF ? entry.dewPointF.value : ''},`;
          csv += `${entry.humidity ? entry.humidity.value : ''},`;
          csv += `${entry.altimeter ? entry.altimeter.value : ''},`;
          csv += `${entry.lightningFreq ? entry.lightningFreq.value : ''},`;
          csv += `${entry.lightningDist ? `"${entry.lightningDist.value}"` : ''},`;
          csv += `${
            entry.lowestCloud
              ? entry.lowestCloud.value == '0'
                ? ''
                : `"${entry.lowestCloud.value}"`
              : ''
          },`;
          csv += `${entry.sigWaveDir ? entry.sigWaveDir.value : ''},`;
          csv += `${entry.sigWavePrd ? entry.sigWavePrd.value : ''},`;
          csv += `${entry.sigWaveHgtFt ? entry.sigWaveHgtFt.value : ''},`;
          csv += `${entry.maxWindDir ? entry.maxWindDir.value : ''},`;
          csv += `${entry.maxWindSpeed ? entry.maxWindSpeed.value : ''},`;
          csv += `${entry.precip ? entry.precip.value : ''},`;
          csv += `${entry.CT2Counts ? entry.CT2Counts.value : ''},`;
          csv += `${entry.CT2Reading ? entry.CT2Reading.value : ''},`;
          csv += `${entry.windChillIndexF ? entry.windChillIndexF.value : ''},`;
          csv += `${entry.heatIndexF ? entry.heatIndexF.value : ''},`;

          const pressure = __isEmpty(entry.haPressureInHg)
            ? entry.stationPressure
            : entry.haPressureInHg
          csv += `${pressure ? pressure.value : ''},`;
          
          csv += `${entry.pressureAltFt ? entry.pressureAltFt.value : ''},`;
          csv += `${entry.denseAltFt ? entry.denseAltFt.value : ''},`;
          csv += `${entry.seaTempF ? entry.seaTemp.value : ''},`;
          csv += `${entry.compass ? entry.compass.value : ''},`;
          csv += `${entry.lat ? entry.lat.value : ''},`;
          csv += `${entry.lon ? entry.lon.value : ''},`;
          csv += `${entry.elevationFt ? entry.elevationFt.value : ''},`;
          csv += `${entry.tip ? entry.tip.value : ''},`;
          csv += `${entry.orientation ? entry.orientation.value : ''},`;
          csv += `${entry.battery ? entry.battery.value : ''},`;
          csv += `${entry.mode ? entry.mode.value : ''},`;
          csv += `${entry.sediment ? entry.sediment.value : ''},`;
        }

        csv += `${
          entry.fullPacket ? `"${entry.fullPacket.value.replace(/(\r\n|\n|\r)/gm, '  ')}"` : ''
        },\n`;
      }
    });

    allCSVs[deviceIMEI] = new Blob([BOM + csv], {
      type: 'text/csv; charset=utf-8',
    });
  });

  return allCSVs;
};

export default returnIWOSExport;
