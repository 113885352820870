import { getGraphData, getMaxGraphData, getMinGraphData } from '../../../../utils/functions';

const returnMWSPlots = (entries, showMetricOnly) => { 
  const tempData = getGraphData(entries, showMetricOnly ? 'temperature' : 'temperatureF');
  var max_temp = getMaxGraphData(tempData);
  var min_temp = getMinGraphData(tempData);
  max_temp = max_temp == -Infinity ? 120 : max_temp;
  min_temp = min_temp == Infinity ? -50 : min_temp; 

  const humData = getGraphData(entries, 'humidity');
  var max_hum = getMaxGraphData(humData);
  var min_hum = getMinGraphData(humData);
  max_hum = max_hum == -Infinity ? 100 : max_hum;
  min_hum = min_hum == Infinity ? 0 : min_hum; 

  const dewData = getGraphData(entries, showMetricOnly ? 'dewPoint' : 'dewPointF');
  var max_dew = getMaxGraphData(dewData);
  var min_dew = getMinGraphData(dewData);
  max_dew = max_dew == -Infinity ? 120 : max_dew;
  min_dew = min_dew == Infinity ? -50 : min_dew; 

  const windSpeedData = getGraphData(entries, showMetricOnly ? 'windSpeedMps' : 'windSpeedMph');
  var max_windSpeed = getMaxGraphData(windSpeedData);
  var min_windSpeed = getMinGraphData(windSpeedData);
  max_windSpeed = max_windSpeed == -Infinity ? 80 : max_windSpeed;
  min_windSpeed = min_windSpeed == Infinity ? 0 : min_windSpeed; 

  const windDirData = getGraphData(entries, 'windDir');
  var max_windDir = getMaxGraphData(windDirData);
  var min_windDir = getMinGraphData(windDirData);
  max_windDir = max_windDir == -Infinity? 360 : max_windDir;
  min_windDir = min_windDir == Infinity? 0 : min_windDir;

  const maxWindSpeedData = getGraphData(entries, showMetricOnly ? 'maxWindSpeedMps' : 'maxWindSpeedMph');
  var max_maxWindSpeed = getMaxGraphData(maxWindSpeedData);
  var min_maxWindSpeed = getMinGraphData(maxWindSpeedData);
  max_maxWindSpeed = max_maxWindSpeed == -Infinity ? 80 : max_maxWindSpeed;
  min_maxWindSpeed = min_maxWindSpeed == Infinity ? 0 : min_maxWindSpeed; 

  const altimeterData = getGraphData(entries, showMetricOnly ? 'altimeterMb' : 'altimeter');
  var max_altimeter = getMaxGraphData(altimeterData);
  var min_altimeter = getMinGraphData(altimeterData);
  max_altimeter = max_altimeter == -Infinity ? 34 : max_altimeter;
  min_altimeter = min_altimeter == Infinity ? 20 : min_altimeter; 
  
  const stationPressureData = getGraphData(entries, showMetricOnly ? 'stationPressureMb' : 'stationPressure');
  var max_stationPressure = getMaxGraphData(stationPressureData);
  var min_stationPressure = getMinGraphData(stationPressureData);
  max_stationPressure = max_stationPressure == -Infinity ? 34 : max_stationPressure;
  min_stationPressure = min_stationPressure == Infinity ? 20 : min_stationPressure; 

  const lightningData = getGraphData(entries, 'lightningDist');
  var max_lightning = getMaxGraphData(lightningData);
  var min_lightning = getMinGraphData(lightningData);
  max_lightning = max_lightning == -Infinity ? 30 : max_lightning;
  min_lightning = min_lightning == Infinity ? 0 : min_lightning; 

  const visibilityData = getGraphData(entries, showMetricOnly ? 'visibilityMeters' : 'visibility');
  var max_visibility = getMaxGraphData(visibilityData);
  var min_visibility = getMinGraphData(visibilityData);
  max_visibility = max_visibility == -Infinity ? 15 : max_visibility;
  min_visibility = min_visibility == Infinity ? -1 : min_visibility; 

  const precipData = getGraphData(entries, showMetricOnly ? 'precipMm' : 'precip');
  var max_precip = getMaxGraphData(precipData);
  var min_precip = getMinGraphData(precipData);
  max_precip = max_precip == -Infinity ? 1.0 : max_precip;
  min_precip = min_precip == Infinity ? 0 : min_precip; 

  const batteryData = getGraphData(entries, 'battery');
  var max_battery = getMaxGraphData(batteryData);
  var min_battery = getMinGraphData(batteryData);
  max_battery = max_battery == -Infinity ? 8 : max_battery;
  min_battery = min_battery == Infinity ? 0 : min_battery; 

  const tempHumDew = {
    datasets: [
      {
        data: tempData,
        borderColor: 'red',
        backgroundColor: 'red',
        pointRadius: 1,
        pointBorderColor: 'red',
        pointBackgroundColor: 'red',
        pointBorderWidth: 2,
        pointHitRadius: 40,
        lineTension: 0,
        label: `Temperature (°${showMetricOnly ? 'C' : 'F'})`,
        yAxisID: 'temps',
        fill: false,
      },
      {
        data: humData,
        borderColor: 'green',
        backgroundColor: 'green',
        pointRadius: 1,
        pointBorderColor: 'green',
        pointBackgroundColor: 'green',
        pointBorderWidth: 2,
        pointHitRadius: 40,
        lineTension: 0,
        label: 'Relative Humidity',
        yAxisID: 'RH',
        fill: false,
      },
      {
        data: dewData,
        borderColor: 'blue',
        backgroundColor: 'blue',
        pointRadius: 1,
        pointBorderColor: 'blue',
        pointBackgroundColor: 'blue',
        pointBorderWidth: 0,
        pointHitRadius: 40,
        lineTension: 0,
        label: `Dew Point (°${showMetricOnly ? 'C' : 'F'})`,
        fill: false,
      },
    ],
    options: {
      animation: false,
      responsive: true,
      fillColor: '#560620',
      scaleShowVerticalLines: false,
      title: {
        display: true,
        text: `Temperature (°${showMetricOnly ? 'C' : 'F'}), Relative Humidity, Dew Point (°${
          showMetricOnly ? 'C' : 'F'
        })`,
      },
      tooltips: {
        mode: 'label',
      },
      hover: {
        mode: 'dataset',
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'day',
            },
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: false,
              labelString: 'Time',
            },
          },
        ],
        yAxes: [
          {
            id: 'temps',
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: true,
              labelString: `Temperature (°${showMetricOnly ? 'C' : 'F'}), Dew Point (°${
                showMetricOnly ? 'C' : 'F'
              })`,
            },
            ticks: {
              suggestedMin: Math.min(min_temp,min_dew) - (Math.min(min_temp,min_dew)*.1),
              suggestedMax: Math.max(max_temp,max_dew) + (Math.max(max_temp,max_dew)*.1),
            },
          },
          {
            id: 'RH',
            position: 'right',
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: true,
              labelString: 'Relative Humidity (%)',
            },
            ticks: {
              suggestedMin: min_hum - (min_hum*.1),
              suggestedMax: max_hum + (max_hum*.1),
            },
          },
        ],
      },
    },
  };

  const wind = {
    datasets: [
      {
        data: windSpeedData,
        borderColor: 'lightgreen',
        backgroundColor: 'lightgreen',
        pointRadius: 1,
        pointStyle: 'line',
        pointBorderColor: 'lightgreen',
        pointBackgroundColor: 'lightgreen',
        pointBorderWidth: 2,
        pointHitRadius: 40,
        lineTension: 0,
        label: `Wind Speed (${showMetricOnly ? 'm/s' : 'mph'})`,
        yAxisID: 'windSpeed',
        fill: false,
      },
      {
        data: windDirData,
        borderColor: 'black',
        backgroundColor: 'black',
        pointStyle: 'cross',
        showLine: false,
        pointRadius: 4,
        pointBorderColor: 'black',
        pointBackgroundColor: 'black',
        pointBorderWidth: 2,
        pointHitRadius: 40,
        lineTension: 0,
        label: 'Wind Direction (°)',
        yAxisID: 'windDirection',
        fill: false,
      },
      {
        data: maxWindSpeedData,
        borderColor: 'red',
        backgroundColor: 'red',
        pointRadius: 2,
        pointStyle: 'circle',
        showLine: false,
        pointBorderColor: 'red',
        pointBackgroundColor: 'red',
        pointBorderWidth: 0,
        pointHitRadius: 40,
        lineTension: 0,
        label: `Max Wind Speed (${showMetricOnly ? 'm/s' : 'mph'})`,
        fill: false,
      },
    ],
    options: {
      legend: {
        labels: { usePointStyle: true },
      },
      animation: false,
      responsive: true,
      fillColor: '#560620',
      scaleShowVerticalLines: false,
      title: {
        display: true,
        text: 'Wind Speeds',
      },
      tooltips: {
        mode: 'label',
      },
      hover: {
        mode: 'dataset',
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'day',
            },
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: false,
              labelString: 'Time',
            },
          },
        ],
        yAxes: [
          {
            id: 'windSpeed',
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: true,
              labelString: `Wind Speed (${showMetricOnly ? 'm/s' : 'mph'})`,
            },
            ticks: {
              suggestedMin: Math.min(min_maxWindSpeed,min_windSpeed) - (Math.min(min_maxWindSpeed,min_windSpeed)*.1),
              suggestedMax: Math.max(max_maxWindSpeed,max_windSpeed) + (Math.max(max_maxWindSpeed,max_windSpeed)*.1),
            },
          },
          {
            id: 'windDirection',
            position: 'right',
            display: true,
            gridLines: { drawOnChartArea: true },
            scaleLabel: {
              display: true,
              labelString: 'Wind Direction (°)',
            },
            ticks: {
              min: Math.max(0,min_windDir - (min_windDir*.1)),
              max: Math.min(360,max_windDir + (max_windDir*.1)),
            },
          },
        ],
      },
    },
  };

  const altimeterStation = {
    datasets: [
      {
        data: altimeterData,
        borderColor: 'purple',
        pointRadius: 1,
        pointBorderColor: 'purple',
        pointBackgroundColor: 'purple',
        pointBorderWidth: 2,
        pointHitRadius: 40,
        lineTension: 0,
        label: `Altimeter (${showMetricOnly ? 'mb/hPa' : 'inHg'})`,
        fill: false,
      },
      {
        data: stationPressureData,
        borderColor: 'magenta',
        backgroundColor: 'magenta',
        pointRadius: 1,
        pointBorderColor: 'magenta',
        pointBackgroundColor: 'magenta',
        pointBorderWidth: 2,
        pointHitRadius: 40,
        lineTension: 0,
        label: `Station Pressure (${showMetricOnly ? 'mb/hPa' : 'inHg'})`,
        fill: false,
      },
    ],
    options: {
      animation: false,
      responsive: true,
      fillColor: '#560620',
      scaleShowVerticalLines: false,
      title: {
        display: true,
        text: `Altimeter and Station Pressure (${showMetricOnly ? 'mb/hPa' : 'inHg'})`,
      },
      tooltips: {
        mode: 'label',
      },
      hover: {
        mode: 'dataset',
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'day',
            },
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: false,
              labelString: 'Time',
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: { drawOnChartArea: true },
            scaleLabel: {
              display: true,
              labelString: `Altimeter, Station Pressure (${showMetricOnly ? 'mb/hPa' : 'inHg'})`,
            },
            ticks: {
              suggestedMin: Math.min(min_stationPressure,min_altimeter) - (Math.min(min_stationPressure,min_altimeter)*.01),
              suggestedMax: Math.max(max_stationPressure,max_altimeter) + (Math.max(max_stationPressure,max_altimeter)*.01),
            },
          },
        ],
      },
    },
  };

  const lightning = {
    datasets: [
      {
        data: lightningData,
        label: 'Lightning',
        borderColor: 'transparent',
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointBorderColor: 'rgba(250,174,50,0.9)',
        pointBackgroundColor: '#fff',
        pointHitRadius: 40,
        pointRadius: 3,
        fill: false,
      },
    ],
    options: {
      animation: false,
      // hover: { mode: true },
      responsive: true,
      legend: {
        usePointStyle: true,
        display: true,
        fillStyle: '#000',
        strokeStyle: '#000',
        pointStyle: String,
      },
      tooltips: {
        mode: 'label',
      },
      hover: {
        mode: 'dataset',
      },
      title: {
        display: true,
        text: 'Lightning',
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'day',
            },
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: false,
              labelString: 'Time',
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: { drawOnChartArea: true },
            scaleLabel: {
              display: true,
              labelString: 'Lightning Distance (miles)',
            },
            ticks: {
              min: min_lightning - (min_lightning*.1),
              max: max_lightning + (max_lightning*.1),
            },
          },
        ],
      },
    },
  };

  const presentWeather = {
    datasets: [
      {
        data: visibilityData,
        borderColor: 'black',
        backgroundColor: 'black',
        pointRadius: 1,
        pointBorderColor: 'black',
        pointBackgroundColor: 'black',
        pointBorderWidth: 2,
        pointHitRadius: 40,
        lineTension: 0,
        label: `Visibility (${showMetricOnly ? 'meters' : 'miles'})`,
        yAxisID: 'visibility',
        fill: false,
      },
      {
        data: precipData,
        borderColor: 'rgba(30,144,255,0.5)',
        backgroundColor: 'rgba(30,144,255,0.5)',
        pointRadius: 1,
        pointBorderColor: 'rgba(30,144,255,0.5)',
        pointBackgroundColor: 'rgba(30,144,255,0.5)',
        pointBorderWidth: 2,
        pointHitRadius: 40,
        lineTension: 0,
        label: `Precipitation (${showMetricOnly ? 'mm/hr' : 'in/hr'})`,
        yAxisID: 'precipitation',
        fill: false,
      },
    ],
    options: {
      animation: false,
      responsive: true,
      fillColor: '#560620',
      scaleShowVerticalLines: false,
      title: {
        display: true,
        text: 'Present Weather',
      },
      tooltips: {
        mode: 'label',
      },
      hover: {
        mode: 'dataset',
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'day',
            },
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: false,
              labelString: 'Time',
            },
          },
        ],
        yAxes: [
          {
            id: 'visibility',
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: true,
              labelString: `Visibility (${showMetricOnly ? 'meters' : 'miles'})`,
            },
            ticks: {
              suggestedMin: min_visibility - (min_visibility*.1),
              suggestedMax: max_visibility + (max_visibility*.1),
            },
          },
          {
            id: 'precipitation',
            position: 'right',
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: true,
              labelString: `Precipitation (${showMetricOnly ? 'mm/hr' : 'in/hr'})`,
            },
            ticks: {
              suggestedMin: min_precip - (min_precip*.1),
              suggestedMax: max_precip + (max_precip*.1),
            },
          },
        ],
      },
    },
  };

  // const cloudLayers = {
  //     datasets: [{
  //         data: getGraphData(entries, 'cloudLayers'),
  //         borderColor: "green",
  //         backgroundColor: "green",
  //         pointRadius: 4,
  //         pointBorderColor: "green",
  //         pointBackgroundColor: "green",
  //         pointBorderWidth: 2,
  //         pointHitRadius: 40,
  //         lineTension: 0,
  //         pointStyle: "rect",
  //         showLine: false,
  //         label: "Cloud Layer 1",
  //         fill: false
  //     },
  //         // {
  //         //     data: cloudLayer2Period,
  //         //     borderColor: "blue",
  //         //     backgroundColor: "blue",
  //         //     pointRadius: 4,
  //         //     pointBorderColor: "blue",
  //         //     pointBackgroundColor: "blue",
  //         //     pointBorderWidth: 0,
  //         //     pointHitRadius: 40,
  //         //     lineTension: 0,
  //         //     pointStyle: "cross",
  //         //     showLine: false,
  //         //     label: "Cloud Layer 2",
  //         //     //data: dewPoint,
  //         //     fill: false
  //         // },
  //         // {
  //         //     data: cloudLayer3Period,
  //         //     borderColor: "purple",
  //         //     backgroundColor: "purple",
  //         //     pointRadius: 4,
  //         //     pointBorderColor: "purple",
  //         //     pointBackgroundColor: "purple",
  //         //     pointBorderWidth: 0,
  //         //     pointHitRadius: 40,
  //         //     lineTension: 0,
  //         //     pointStyle: "star",
  //         //     showLine: false,
  //         //     label: "Cloud Layer 3",
  //         //     //data: dewPoint,
  //         //     fill: false
  //         // },
  //         // {
  //         //     data: cloudLayer4Period,
  //         //     borderColor: "black",
  //         //     backgroundColor: "black",
  //         //     pointRadius: 4,
  //         //     pointBorderColor: "black",
  //         //     pointBackgroundColor: "black",
  //         //     pointBorderWidth: 0,
  //         //     pointHitRadius: 40,
  //         //     lineTension: 0,
  //         //     pointStyle: "triangle",
  //         //     showLine: false,
  //         //     label: "Cloud Layer 4",
  //         //     fill: false
  //         // }
  //     ],
  //     options: {
  //         legend: {
  //             labels: { usePointStyle: true }
  //         },
  //         animation: false,
  //         responsive: true,
  //         fillColor: "#560620",
  //         scaleShowVerticalLines: false,
  //         title: {
  //             display: true,
  //             text: 'Cloud Layers 1-4'
  //         },
  //         tooltips: {
  //             mode: 'label'
  //         },
  //         hover: {
  //             mode: 'dataset'
  //         },
  //         scales: {
  //             xAxes: [{
  //                 type: 'time',
  //                 time: {
  //                     unit: 'day'
  //                 },
  //                 display: true,
  //                 scaleLabel: {
  //                     display: false,
  //                     labelString: 'Time'
  //                 }
  //             }],
  //             yAxes: [{
  //                 display: true,
  //                 scaleLabel: {
  //                     display: true,
  //                     labelString: 'Cloud Height (ft)'
  //                 }
  //             }
  //             ]
  //         }
  //     }
  // };

  const status = {
    datasets: [
      {
        data: batteryData,
        borderColor: 'gray',
        backgroundColor: 'gray',
        pointRadius: 1,
        pointBorderColor: 'gray',
        pointBackgroundColor: 'gray',
        pointBorderWidth: 2,
        pointHitRadius: 40,
        lineTension: 0,
        label: 'Voltage (VDC)',
        yAxisID: 'volts',
        fill: false,
      },
      /* {
        data: getGraphData(entries, 'tip'),
        borderColor: 'lightblue',
        backgroundColor: 'lightblue',
        pointRadius: 1,
        pointBorderColor: 'lightblue',
        pointBackgroundColor: 'lightblue',
        pointBorderWidth: 2,
        pointHitRadius: 40,
        lineTension: 0,
        label: 'Tilt (°)',
        yAxisID: 'tilt',
        fill: false,
      }, */
    ],
    options: {
      scaleShowGridLines: true,
      animation: false,
      responsive: true,
      scaleShowVerticalLines: true,
      title: {
        display: true,
        text: 'Status',
      },
      tooltips: {
        mode: 'label',
      },
      hover: {
        mode: 'dataset',
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'day',
            },
            gridLines: { drawOnChartArea: false },
            display: true,
            scaleLabel: {
              display: false,
              labelString: 'Time',
            },
          },
        ],
        yAxes: [
          {
            id: 'volts',
            display: true,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: true,
              labelString: 'Voltage (VDC)',
            },
            ticks: {
              suggestedMin: min_battery - (min_battery*.1),
              suggestedMax: max_battery + (max_battery*.1),
            },
          },
          {
            id: 'tilt',
            position: 'right',
            display: false,
            gridLines: { drawOnChartArea: false },
            scaleLabel: {
              display: false,
              labelString: 'Tilt (°)',
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 30,
            },
          },
        ],
      },
    },
  };

  return {
    tempHumDew,
    wind,
    altimeterStation,
    lightning,
    presentWeather,
    // cloudLayers,
    status,
  };
};

export default returnMWSPlots;
