import { useState, useEffect, useContext, useRef } from 'react';
import wretch from 'wretch';
import moment from 'moment-timezone';
import { DevicesContext } from '__state/Devices';
import { LatestDeviceImagesContext } from '__state/LatestDeviceImages';
import { DeviceEntriesContext } from '__state/DeviceEntries';
import { PaginationContext } from '__state/Pagination';
import { ACCT_PLAN_LEVELS } from 'utils/constants';
import __isEqual from 'lodash/isEqual';
import __isEmpty from 'lodash/isEmpty';
import __findIndex from 'lodash/findIndex';
import __last from 'lodash/last';
import __cloneDeep from 'lodash/cloneDeep';

export const useDeepEffect = (fn, deps) => {
	const isFirst = useRef(true);
	const prevDeps = useRef(deps);

	useEffect(() => {
		const isSame = prevDeps.current.every((obj, index) => __isEqual(obj, deps[index]));

		if (isFirst.current || !isSame) {
			fn();
		}

		isFirst.current = false;
		prevDeps.current = deps;
	}, [deps, fn]);
};

export const useDevices = (isLoggingOut, setIsLoading, detectValidToken) => {
	const { username, viewPermissions, token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [devices, setDevices] = useState([]);
	const [totalDeviceCount, setTotalDeviceCount] = useState('');
	const { cachedDevices, setCachedDevices } = useContext(DevicesContext);
	const { setDeviceImages } = useContext(LatestDeviceImagesContext);

	useDeepEffect(() => {
		const getDevices = async () => {
			try {
				await detectValidToken();

				let freshDevices = cachedDevices.length > 0 ? cachedDevices : [];
				if (freshDevices) {
					//get the devices for this specific user
					const { deviceswithFullInfo, devicesWithBasicInfo, latestImages } = await wretch(
						`${process.env.REACT_APP_UTIL_API_URL}/user/units`,
					)
						.auth(`Bearer ${token}`)
						.query({
							email: username,
						})
						.get()
						.json();

					freshDevices = __cloneDeep(deviceswithFullInfo);
					setTotalDeviceCount(deviceswithFullInfo.length);
					setCachedDevices(__cloneDeep(devicesWithBasicInfo));
					setDeviceImages(__cloneDeep(latestImages));
				}

				const finalDevices = [];
				await Promise.all(
					freshDevices.map(async (device) => {
						//check view permissions to see if user is allowed to see the device on the list
						if (viewPermissions === 'ALL' || viewPermissions.includes(device.imei)) {
							const latestEntry = await wretch(
								`${process.env.REACT_APP_UTIL_API_URL}/unit/entries/latest`,
							)
								.auth(`Bearer ${token}`)
								.post({
									device: device,
								})
								.json();

							device.latestEntryTS = latestEntry.timestamp && latestEntry.timestamp[0].value;
							device.latestEntryTS =
								device.latestEntryTS &&
								device.latestEntryTS.replace(/\//g, '-').replace(/\,/g, ' ');
							finalDevices.push(device);
						}
					}),
				);

				setDevices(finalDevices);
			} catch (error) {
				console.log(error);

				if (error.text && JSON.parse(error.text).type === 'token') {
					alert('Your session has expired. Please log in again.');
					return;
				}

				alert('Could not retrieve your devices. Please refresh the page or contact us.');
			} finally {
				setIsLoading(false);
			}
		};

		if (!isLoggingOut && devices.length === 0) {
			getDevices();
		}

		return () => {};
	}, [devices]);

	return { devices, setDevices, totalDeviceCount };
};

export const useEntries = (
	device,
	setAreEntriesLoading,
	setEntryStartTimeIndex,
	setEntryEndTimeIndex,
	planLevel,
) => {
	const { token } = JSON.parse(sessionStorage.getItem('selfData'));
	const [deviceEntries, setDeviceEntries] = useState([]);
	const [totalEntriesCount, setTotalEntriesCount] = useState(0);
	const [totalEntriesTimestamps, setTotalEntriesTimestamps] = useState([]);
	const { cachedDeviceEntries, addDeviceEntries } = useContext(DeviceEntriesContext);
	const { setShowFirstEntriesPage } = useContext(PaginationContext);

	useDeepEffect(() => {
		const retrieveEntries = async () => {
			try {
				let entries = cachedDeviceEntries[device.id];

				let startTs = moment().subtract(24, 'hours').valueOf(); // basic plan
				if (planLevel === ACCT_PLAN_LEVELS.ADVANCED) {
					startTs = moment().subtract(6, 'months').valueOf();
				} else if (planLevel === ACCT_PLAN_LEVELS.PRO) {
					startTs = moment().subtract(1, 'year').valueOf();
				} else if (planLevel === ACCT_PLAN_LEVELS.UNRESTRICTED) {
					startTs = 0;
				}

				// used for pagination
				if (__isEmpty(entries)) {
					const tsRes = await wretch(
						`${process.env.REACT_APP_UTIL_API_URL}/unit/entries/timestamps`,
					)
						.auth(`Bearer ${token}`)
						.query({
							deviceId: device.id,
							startTs,
						})
						.get()
						.json();

					let start = 0;
					let end = 0;
					let length = 0;
					if (tsRes.timestamp) {
						// use 101 instead of 100 as a hack to force a second page if selected page size is 100
						// this will force the app to load more data once the second page is clicked
						start =
							tsRes.timestamp && tsRes.timestamp[101]
								? tsRes.timestamp[101].ts
								: __last(tsRes.timestamp).ts; // timestamps are descending
						end = ++tsRes.timestamp[0].ts;	//increment end time by 1 since api call parameter is not inclusive, otherwise our most recent time gets cut off
						length = tsRes.timestamp.length;
					} else {
						// we get here if there are no entries since we have account levels now
						// levels place a limit on time ranges (e.g. basic is 24 hours and there
						// may not have been an entry during the last 24 hours)
						start = startTs;
						end = Date.now();
					}

					entries = await wretch(`${process.env.REACT_APP_UTIL_API_URL}/unit/entries`)
						.auth(`Bearer ${token}`)
						.post({
							device: device,
							startTime: start,
							endTime: end,
						})
						.json();

					const startIndex = __findIndex(tsRes.timestamp, ['ts', start]);
					addDeviceEntries(device.id, entries);
					setTotalEntriesCount(length);
					setTotalEntriesTimestamps(tsRes.timestamp || {});
					setEntryStartTimeIndex && setEntryStartTimeIndex(startIndex > 0 ? startIndex : 0);
					setEntryEndTimeIndex(0);
				}

				setDeviceEntries(entries);
			} catch (error) {
				console.log(error);

				if (error.text && JSON.parse(error.text).type === 'token') {
					alert('Your session has expired. Please log in again.');
					return;
				}

				return;
			} finally {
				setAreEntriesLoading(false);
				setShowFirstEntriesPage(false);
			}
		};

		if (cachedDeviceEntries[device.id] && cachedDeviceEntries[device.id].length) {
			setDeviceEntries(cachedDeviceEntries[device.id]);
		} else if (!__isEmpty(device)) {
			setAreEntriesLoading(true);
			retrieveEntries();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [device, setAreEntriesLoading, { ...cachedDeviceEntries }]);
	
	return {
		deviceEntries,
		totalEntriesCount,
		totalEntriesTimestamps,
	};
};
