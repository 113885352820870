/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import wretch from 'wretch';

import PDF from '__assets/pdf/quantimet_faq.pdf';
import MWSControl from '__assets/pdf/MWS Control v2.14 Installer.exe';

const Downloads = () => {
	const { username, token } = JSON.parse(sessionStorage.getItem('selfData'));

	const downloadMWSControl = (e) => {
		e.preventDefault();
		window.open(MWSControl, '_blank');

		wretch(`${process.env.REACT_APP_UTIL_API_URL}/user/log-control-download`)
			.auth(`Bearer ${token}`)
			.post({
				username,
			})
			.error(500, (error) => {
				console.error(JSON.stringify(error));
			});
	};

	return (
		<div className="downloads">
			<div className="info-block">
				<div className="info-row">
					<p className="info-title">FAQ:</p>
					<p className="info-data">
						<a target="_blank" href={PDF} rel="noopener noreferrer">
							Link
						</a>
					</p>
				</div>
				<div className="info-row">
					<p className="info-title">MWS Control:</p>
					<p className="info-data">
						<a onClick={downloadMWSControl}>Link</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Downloads;
