import React from 'react'; 
import moment from 'moment-timezone';
import {
	isTampered,
	createTableRowValue,
	getWeather,
	setValueColor,
	formatCloudLayersValue,
	renderAudioLinks,
} from 'utils/functions';

moment.tz.setDefault('UTC');

const MWSTable = ({ row, deviceTypeOfficial, deviceName, highlightedKeys, data1HourAgo, showMetricOnly }) => (
  <div className="device-data-grid">
    {!row.pict &&
      !row.pict1 &&
      !row.pict2 &&
      !row.pict3 &&
      !row.pict4 &&
      !row.spectralINP &&
      !row.spectralMP3 &&
      !row.spectralWAV && (
        <div className="grid-sections">
          <div className="data-inner">
            <ul className="data-titles">
              <li className={highlightedKeys.includes('timestamp') ? 'highlight' : ''}>
                <span>Date/Time (UTC):</span>
              </li>
              <li className={highlightedKeys.includes('windDir') ? 'highlight' : ''}>
                <span>Wind Direction:</span>
              </li>
              <li className={highlightedKeys.includes('windSpeed') ? 'highlight' : ''}>
                <span>Wind Speed:</span>
              </li>
              <li className={highlightedKeys.includes('maxWindDir') ? 'highlight' : ''}>
                <span>Peak Wind Direction:</span>
              </li>
              <li className={highlightedKeys.includes('maxWindSpeed') ? 'highlight' : ''}>
                <span>Peak Wind Speed:</span>
              </li>
              <li>&nbsp;</li>
              <li className={highlightedKeys.includes('cloudLayers') ? 'highlight' : ''}>
                <span>Cloud Layers:</span>
              </li>
              <li className={highlightedKeys.includes('ceilometerHits') ? 'highlight' : ''}>
                <span>Ceilometer Hits:</span>
              </li>
              <li className={highlightedKeys.includes('lightningFreq') ? 'highlight' : ''}>
                <span>Lightning Freq.:</span>
              </li>
              <li className={highlightedKeys.includes('lightningDist') ? 'highlight' : ''}>
                <span>Lightning Dist.:</span>
              </li>
            </ul>
            <ul className="data-values">
              <li>
                <span>{moment(row.timestamp).format('YYYY-MM-DD HH:mm:ss')}</span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.windDir, units: '°', show: true }])}{' '}
                  {row.windDirCard && row.windDirCard.value}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    { rowObj: row.windSpeed, units: ' kt', show: true },
                    {
                      rowObj: row.windSpeedMph,
                      units: ' mph',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.windSpeedMps, units: ' m/s', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.maxWindDir, units: '°', show: true }])}{' '}
                  {row.maxWindDirCard && row.maxWindDirCard.value}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    { rowObj: row.maxWindSpeed, units: ' kt', show: true },
                    {
                      rowObj: row.maxWindSpeedMph,
                      units: ' mph',
                      show: !showMetricOnly,
                    },
                    {
                      rowObj: row.maxWindSpeedMps,
                      units: ' m/s',
                      show: true,
                    },
                  ])}
                </span>
              </li>
              <li>&nbsp;</li>
              <li>
                <span>{formatCloudLayersValue(row.cloudLayers)}</span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.ceilometerHits, units: '', show: true }])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.lightningFreq, units: '', show: true }])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.lightningFreq.value == '0' ? { value: '' } : row.lightningDist,
                      units: row.lightningFreq == '0' ? '' : ' NM',
                      show: true,
                    }, // special rule
                  ])}
                </span>
              </li>
            </ul>
          </div>
          <div className="data-inner">
            <ul className="data-titles">
              <li className={highlightedKeys.includes('temperature') ? 'highlight' : ''}>
                <span>Temperature:</span>
              </li>
              <li className={highlightedKeys.includes('dewPoint') ? 'highlight' : ''}>
                <span>Dew Point:</span>
              </li>
              <li className={highlightedKeys.includes('humidity') ? 'highlight' : ''}>
                <span>Relative Humidity:</span>
              </li>
              <li className={highlightedKeys.includes('windChillIndex') ? 'highlight' : ''}>
                <span>Wind Chill Index:</span>
              </li>
              <li className={highlightedKeys.includes('heatIndex') ? 'highlight' : ''}>
                <span>Heat Index:</span>
              </li>
              <li>&nbsp;</li>
              <li className={highlightedKeys.includes('visibility') ? 'highlight' : ''}>
                <span>Visibility:</span>
              </li>
              <li className={highlightedKeys.includes('weather') ? 'highlight' : ''}>
                <span>Present Weather:</span>
              </li>
              <li className={highlightedKeys.includes('precip') ? 'highlight' : ''}>
                <span>Precip Accumulation:</span>
              </li>
              <li>&nbsp;</li>
              <li className={highlightedKeys.includes('altimeter') ? 'highlight' : ''}>
                <span>Altimeter:</span>
              </li>
              <li className={highlightedKeys.includes('stationPressure') ? 'highlight' : ''}>
                <span>Station Pressure:</span>
              </li>
              <li className={highlightedKeys.includes('pressureAlt') ? 'highlight' : ''}>
                <span>Pressure Altitude:</span>
              </li>
              <li className={highlightedKeys.includes('denseAlt') ? 'highlight' : ''}>
                <span>Density Altitude:</span>
              </li>
            </ul>
            <ul className="data-values">
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.temperatureF,
                      units: ' °F',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.temperature, units: ' °C', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.dewPointF,
                      units: ' °F',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.dewPoint, units: ' °C', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.humidity, units: '%', show: true }])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.windChillIndexF,
                      units: ' °F',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.windChillIndex, units: ' °C', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.heatIndexF,
                      units: ' °F',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.heatIndex, units: ' °C', show: true },
                  ])}
                </span>
              </li>
              <li>&nbsp;</li>
              <li>
                <span>
                  {createTableRowValue([
                    { rowObj: row.visibility, units: '', show: !showMetricOnly },
                    { rowObj: row.visibilityMeters, units: ' m', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>{getWeather(row.weather)}</span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.precip,
                      units: ' in/hr',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.precipMm, units: ' mm/hr', show: true },
                  ])}
                </span>
              </li>
              <li>&nbsp;</li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.altimeter,
                      units: ' inHg',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.altimeterMb, units: ' mb/hPa', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.stationPressure,
                      units: ' inHg',
                      show: !showMetricOnly,
                    },
                    {
                      rowObj: row.stationPressureMb,
                      units: ' mb/hPa',
                      show: true,
                    },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.pressureAltFt,
                      units: ' ft',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.pressureAlt, units: ' m', show: true },
                  ])}
                </span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.denseAltFt,
                      units: ' ft',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.denseAlt, units: ' m', show: true },
                  ])}
                </span>
              </li>
            </ul>
          </div>
          <div className="data-inner">
            <ul className="data-titles">
              <li className={highlightedKeys.includes('lon') ? 'highlight' : ''}>
                <span>Latitude:</span>
              </li>
              <li className={highlightedKeys.includes('lat') ? 'highlight' : ''}>
                <span>Longitude:</span>
              </li>
              <li className={highlightedKeys.includes('elevation') ? 'highlight' : ''}>
                <span>Elevation:</span>
              </li>
              <li>&nbsp;</li>
              <li className="section-title system"></li>
              <li>&nbsp;</li>
              <li>Alias:</li>
              <li>Type:</li>
              <li className={highlightedKeys.includes('tilt') ? 'highlight' : ''}>
                <span>Tilt:</span>
              </li>
              <li className={highlightedKeys.includes('orientation') ? 'highlight' : ''}>
                <span>Orientation:</span>
              </li>
              <li className={highlightedKeys.includes('battery') ? 'highlight' : ''}>
                <span>Voltage:</span>
              </li>
              <li className={highlightedKeys.includes('mode') ? 'highlight' : ''}>
                <span>Mode:</span>
              </li>
              <li className={highlightedKeys.includes('sediment') ? 'highlight' : ''}>
                <span>Vis Contamination:</span>
              </li>
              <li className={highlightedKeys.includes('tamper') ? 'highlight' : ''}>
                <span>Tampering:</span>
              </li>
            </ul>
            <ul className="data-values">
              <li>
                <span>{createTableRowValue([{ rowObj: row.lat, units: '°', show: true }])}</span>
              </li>
              <li>
                <span>{createTableRowValue([{ rowObj: row.lon, units: '°', show: true }])}</span>
              </li>
              <li>
                <span>
                  {createTableRowValue([
                    {
                      rowObj: row.elevationFt,
                      units: ' ft',
                      show: !showMetricOnly,
                    },
                    { rowObj: row.elevation, units: ' m', show: true },
                  ])}
                </span>
              </li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>
                <span>{deviceName || <>&nbsp;</>}</span>
              </li>
              <li>
                <span>{deviceTypeOfficial}</span>
              </li>
              <li>
                <span>{createTableRowValue([{ rowObj: row.tip, units: '°', show: true }])}</span>
              </li>
              <li>
                <span>
                  {createTableRowValue([{ rowObj: row.orientation, units: '°', show: true }])}{' '}
                  {row.orientationCard && row.orientationCard.value}
                </span>
              </li>
              <li>
                <span className={setValueColor(row, data1HourAgo, 'mws-voltage', row.battery)}>
                  {createTableRowValue([{ rowObj: row.battery, units: ' V', show: true }])}
                </span>
              </li>
              <li>
                <span>{createTableRowValue([{ rowObj: row.mode, units: '', show: true }])}</span>
              </li>
              <li>
                <span className={setValueColor(row, data1HourAgo, 'sediment', row.sediment)}>
                  {createTableRowValue([{ rowObj: row.sediment, units: '', show: true }])}
                </span>
              </li>
              <li>
                <span className={setValueColor(row, data1HourAgo, 'tampering', row.battery)}>
                  {isTampered(row, data1HourAgo) ? 'Yes' : 'No'}
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
    {renderAudioLinks(row)}
  </div>
);

export default MWSTable;
