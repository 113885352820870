import React, { useEffect, useState } from 'react';
import { useDevices, useEntries, useDeepEffect } from 'utils/hooks';
import {
	VIEW_TABLE,
	VIEW_COMMAND,
	VIEW_DASHBOARD,
	VIEW_NOTIFICATIONS,
	ACCT_PLAN_LEVELS,
} from 'utils/constants';
import __isEmpty from 'lodash/isEmpty';

import './WeatherDisplayMobile.scss';

import HeaderDefault from 'components/HeaderDefault';
import FooterMobile from 'components/FooterMobile';
import ViewChooser from 'components/ViewChooser';
import Dashboard from 'containers/WeatherDisplay/SingleDeviceView/Dashboard';
import Notifications from 'containers/WeatherDisplay/SingleDeviceView/Notifications';
import EntriesMobile from 'components/EntriesMobile';
import CommandForm from 'components/Forms/Command';

const WeatherDisplayMobile = ({ detectValidToken, router, response }) => {
	const selfData = JSON.parse(sessionStorage.getItem('selfData')),
		{ planLevel, viewPermissions, cmdPermissions, metricValOn } = selfData;
	const [selectedDevice, setSelectedDevice] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isLoggingOut, setIsLoggingOut] = useState(false);
	const { devices } = useDevices(isLoggingOut, setIsLoading, detectValidToken);

	const [, setEntryStartTimeIndex] = useState(null);
	const [, setEntryEndTimeIndex] = useState(null);
	const { deviceEntries } = useEntries(
		selectedDevice,
		setIsLoading,
		setEntryStartTimeIndex,
		setEntryEndTimeIndex,
		planLevel,
	);

	useDeepEffect(() => {
		if (devices.length > 0 && __isEmpty(selectedDevice)) {
			setSelectedDevice(devices[0]);
		} else if (devices.length === 0 && !isLoading) {
			setIsLoading(true);
		}
	}, [devices, isLoading]);

	// tabs scroll control
	useEffect(() => {
		if (response.location.hash === VIEW_NOTIFICATIONS.toLowerCase()) {
			document
				.querySelector('.view-chooser')
				.scrollTo(document.querySelector('.view-chooser').clientWidth, 0);
		} else if (response.location.hash === VIEW_TABLE.toLowerCase()) {
			document.querySelector('.view-chooser').scrollTo(0, 0);
		}
	}, [response.location.hash]);

	const selectDevice = (e) => {
		e.preventDefault();

		const deviceObj = devices.find((device) => device.imei === e.target.value);
		setSelectedDevice(deviceObj);
	};

	const setPanelView = () => {
		if (isLoading) {
			return <div className="loading-data">Loading Data...</div>;
		}

		switch (response.location.hash) {
			case VIEW_COMMAND.toLowerCase():
				//all we need is the device id and imei numbers
				const deviceInfoSlim = {
					id: selectedDevice.id,
					imei: selectedDevice.imei,
					name: selectedDevice.name,
					modem: selectedDevice.modem,
					type: selectedDevice.type,
				};

				return <CommandForm device={deviceInfoSlim} />;
			case VIEW_DASHBOARD.toLowerCase():
				return <Dashboard device={selectedDevice} />;
			case VIEW_NOTIFICATIONS.toLowerCase():
				return <Notifications device={selectedDevice} showMetricOnly={metricValOn} />;
			case VIEW_TABLE.toLowerCase():
				return (
					<EntriesMobile
						deviceType={selectedDevice.type}
						deviceTypeOfficial={`${selectedDevice.type} ${selectedDevice.model} (${selectedDevice.modem})`}
						deviceName={selectedDevice.name}
						entries={[...deviceEntries]}
						showMetricOnly={metricValOn}
					/>
				);
			default:
				// TODO: so dirty
				const url = router.url({ name: 'WeatherDisplay', hash: `#table` });
				router.navigate({ url });
				break;
		}
	};

	let views = [VIEW_TABLE, VIEW_DASHBOARD];
	if (planLevel !== ACCT_PLAN_LEVELS.BASIC) {
		views.push(VIEW_NOTIFICATIONS);
	}
	if (viewPermissions === 'ALL' || cmdPermissions.includes(selectedDevice.imei)) {
		views.splice(1, 0, VIEW_COMMAND);
	}

	return (
		<div className="weather-display-mobile">
			<HeaderDefault router={router} setIsLoggingOut={setIsLoggingOut} />
			<div className="device-selector">
				<select onChange={selectDevice}>
					{devices.map((device, i) => (
						<option key={i} value={device.imei}>
							{device.type} - {device.name || device.imei}
						</option>
					))}
				</select>
			</div>
			<ViewChooser type="entries" views={views} router={router} response={response} />
			{setPanelView()}
			<FooterMobile router={router} setIsLoggingOut={setIsLoggingOut} />
		</div>
	);
};

export default WeatherDisplayMobile;
