import React, { useState, useEffect } from 'react';
import wretch from 'wretch';
import moment from 'moment-timezone';
import { useDeepEffect } from 'utils/hooks';
import __uniq from 'lodash/uniq';

moment.tz.setDefault('UTC');

const CommandHistory = ({ device, shouldGetHistory, setShouldGetHistory }) => {
	const { token } = JSON.parse(sessionStorage.getItem('selfData'));
	const ABS_MIN_DATE = moment('2020-11-13'); // we were testing before then
	const threeMonthsAgo = moment().subtract(3, 'months');
	const startDate = threeMonthsAgo.isBefore(ABS_MIN_DATE) ? ABS_MIN_DATE : threeMonthsAgo;
	const [fullHistory, setFullHistory] = useState([]);
	const [filteredHistory, setFilteredHistory] = useState([]);
	const [historyStart, setHistoryStart] = useState(startDate);
	const [historyEnd, setHistoryEnd] = useState(moment());
	const [anyHistoryLeft, setAnyHistoryLeft] = useState(true);
	const [allCmdIssuers, setAllCmdIssuers] = useState([]);
	const [filterCmdIssuer, setFilterCmdIssuer] = useState('');

	useEffect(() => {
		const getHistory = async () => {
			try {
				// we're not allowing control of time because it's redundant
				const latestTime = {
					hour: 23,
					minute: 59,
					second: 59,
					millisecond: 999,
				};
				const historyEndTime = moment().isSame(historyEnd) ? {} : latestTime; // if end date is today, take the current time, else take the end of the day

				const historyRes = await wretch(
					`${process.env.REACT_APP_UTIL_API_URL}/unit/commands/history`,
				)
					.auth(`Bearer ${token}`)
					.query({
						imei: device.imei,
						startTime: historyStart.set(latestTime).valueOf(),
						endTime: historyEnd.set(historyEndTime).valueOf(),
					})
					.get()
					.json();

				// disable load more button if no more history entries
				setAnyHistoryLeft(fullHistory.length !== historyRes.length && historyRes.length !== 0);
				setFullHistory(historyRes);
				setFilteredHistory(historyRes); // to be filtered later

				let tmpIssuers = historyRes.map((event) => event.Issuer);
				let issuers = __uniq(tmpIssuers);
				setAllCmdIssuers(issuers);
			} catch (error) {
				console.log(error);

				if (error.text && JSON.parse(error.text).type === 'token') {
					alert('Your session has expired. Please log in again.');
					return;
				}

				alert('Something went wrong getting the device history. Please try again or contact us.');
			} finally {
				setShouldGetHistory(false);
			}
		};

		if (device && shouldGetHistory) {
			getHistory();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [device, shouldGetHistory]);

	// every time the device changes
	useDeepEffect(() => {
		setShouldGetHistory(true);

		return () => {
			setShouldGetHistory(false);
		};
	}, [device]);

	// auto-update history
	useEffect(() => {
		const historyInterval = setInterval(() => {
			setShouldGetHistory(true);
		}, 1000 * 60 * 5); // 5 minutes

		return () => {
			setShouldGetHistory(false);
			clearInterval(historyInterval);
		};
	}, [setShouldGetHistory]);

	// filter by issuer
	useEffect(() => {
		if (filterCmdIssuer !== '') {
			let history = fullHistory;
			let newHistory = history.filter((event) => event.Issuer === filterCmdIssuer);
			setFilteredHistory(newHistory);
		}
	}, [fullHistory, filterCmdIssuer]);

	const loadMoreHistory = () => {
		// for now, load 3 months back at a time
		const threeMonthsPrior = moment(historyStart).subtract(3, 'months');

		if (threeMonthsPrior.isBefore(ABS_MIN_DATE)) {
			setAnyHistoryLeft(false);
			return;
		}

		setHistoryStart(threeMonthsPrior);
		setShouldGetHistory(true);
	};

	return (
		<div className="history">
			<div className="history-header">
				<span>Command History (updates every 5 mins)</span>
				<input type="submit" value="Update" onClick={() => setShouldGetHistory(true)} />
			</div>
			<div className="history-filters">
				<div className="issuer-control">
					<p>Issuer:</p>
					<select onChange={(e) => setFilterCmdIssuer(e.target.value)}>
						<option value="">All Issuers</option>
						{allCmdIssuers.map((issuer, i) => (
							<option key={i} value={issuer}>
								{issuer}
							</option>
						))}
					</select>
				</div>
				<div className="date-controls action">
					<div className="date-change start">
						<p>Start Date (UTC)</p>
						<input
							type="date"
							value={historyStart.format('YYYY-MM-DD')}
							min={ABS_MIN_DATE.format('YYYY-MM-DD')}
							onChange={(e) => setHistoryStart(moment(e.target.value))}
						/>
					</div>
					<div className="date-change end">
						<p>End Date (UTC)</p>
						<input
							type="date"
							value={historyEnd.format('YYYY-MM-DD')}
							max={moment().format('YYYY-MM-DD')}
							onChange={(e) => setHistoryEnd(moment(e.target.value))}
						/>
					</div>
				</div>
			</div>
			<div className="history-table">
				<div className="table">
					<div className="thead">
						<div className="cell timestamp">Timestamp</div>
						<div className="cell issuer">Issuer</div>
						<div className="cell command">Commands</div>
					</div>
					<div className="tbody">
						{filteredHistory.map((command, i) => (
							<div className="tr" key={i}>
								<div className="cell">{moment(command.ts).format('YYYY-MM-DD  HH:mm')}</div>
								<div className="cell">{command.Issuer}</div>
								<div className="cell">{command.Settings}</div>
							</div>
						))}
						{anyHistoryLeft ? (
							<div className="tr load-more">
								<input type="submit" value="Load More" onClick={() => loadMoreHistory()} />
							</div>
						) : (
							<div className="tr no-more">
								<em>No more entries</em>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CommandHistory;
