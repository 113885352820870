import React from 'react';
import { USER_ROLES } from 'utils/constants';

const AccountInfo = () => {
	const { roleId } = JSON.parse(sessionStorage.getItem('selfData'));

	return (
		<div className="account-info-view">
			<div className="info-block">
				<div className="info-row">
					<p className="info-title">Account Type:</p>
					<p className="info-data">{Object.keys(USER_ROLES)[roleId - 1]}</p>
				</div>
			</div>
		</div>
	);
};

export default AccountInfo;
