import React, { useState } from 'react';
import { useResponse, useRouter } from '@curi/react-dom';
import DevicesProvider from '../__state/Devices';
import DeviceEntriesProvider from '../__state/DeviceEntries';
import DataWindowProvider from '../__state/DataWindowCollapse';
import ViewChoiceProvider from '../__state/ViewChoice';
import LatestDeviceImagesProvider from '../__state/LatestDeviceImages';
import PaginationProvider from '../__state/Pagination';
import LargeGraphViewProvider from '../__state/LargeGraphView';
import NotificationsProvider from '../__state/Notifications';

import './App.scss';

import CloseIcon from '@material-ui/icons/Close';

const App = () => {
	const [showMaintenance, setShowMaintenance] = useState(false);
	const router = useRouter();
	const { response } = useResponse();
	const { body: Body } = response;

	const detectValidToken = () => {
		if (sessionStorage.getItem('selfData') === null) {
			redirectToLogin();
		}
	};

	const redirectToLogin = () => {
		const url = router.url({ name: 'Login' });
		router.navigate({ url });
	};

	return (
		<>
			{showMaintenance && (
				// MODIFY THIS MESSAGE WHEN NEEDED
				<div className="maintenance-banner">
					<p>
						Quantimet is currently undergoing maintenance. Services may be temporarily intermittent
						during this time. We apologize for any inconvenience.
					</p>
					<div role="button" onClick={() => setShowMaintenance(false)}>
						<CloseIcon />
					</div>
				</div>
			)}
			<div className={`app-wrapper ${showMaintenance ? 'with-maintenance' : ''}`}>
				<div className="body-wrapper">
					<DevicesProvider>
						<DataWindowProvider>
							<DeviceEntriesProvider>
								<ViewChoiceProvider>
									<LatestDeviceImagesProvider>
										<PaginationProvider>
											<LargeGraphViewProvider>
												<NotificationsProvider>
													<Body
														response={response}
														router={router}
														detectValidToken={detectValidToken}
													/>
												</NotificationsProvider>
											</LargeGraphViewProvider>
										</PaginationProvider>
									</LatestDeviceImagesProvider>
								</ViewChoiceProvider>
							</DeviceEntriesProvider>
						</DataWindowProvider>
					</DevicesProvider>
				</div>
			</div>
		</>
	);
};

export default App;
