import React from 'react';
import __isEmpty from 'lodash/isEmpty';
import __forEach from 'lodash/forEach';

import AudioIcon from '__assets/img/audio-icon.png';

export const createTableRowValue = (rowData = []) => {
	/**
	 * rowData: [{
	 *  rowObj: {ts: number, value: string},
	 *  measurement: string,
	 *  shouldShow?: boolean
	 * }]
	 */

	return rowData.reduce((previousVal, currentVal, index) => {
		const { rowObj, units, show, key, log } = currentVal;
		log && console.log(log); // debugging only when needed

		const isDataEmpty =
			__isEmpty(rowObj) ||
			__isEmpty(rowObj.value) ||
			// (key === 'cloudCoverage' && rowObj.value.replace(/ /g, '') === ',,,,,,')
			(key === 'lowestCloud' && rowObj.value == '0');
		if (isDataEmpty) {
			if (index + 1 === rowData.length) {
				return <>&nbsp;</>;
			}

			// another unit comes after
			return '';
		}

		if (!show) {
			return previousVal;
		}

		// add parentheses to units after the first unit
		let first = rowData[0];
		let didFirstShow = first.rowObj && first.rowObj.value && first.show;
		let val = index > 0 && didFirstShow ? `(${rowObj.value}${units})` : `${rowObj.value}${units}`;
		val = index > 0 ? ` ${val}` : val;
		return previousVal + val;
	}, '');
};

export const isTampered = (entry, data1HourAgo) => {
	// don't compare to prev if it has images
	return (
		!__isEmpty(data1HourAgo) &&
		!data1HourAgo.pict &&
		!data1HourAgo.pict1 &&
		((entry.lat && data1HourAgo.lat && entry.lat.value - data1HourAgo.lat.value > 0.005) ||
			(entry.lon && data1HourAgo.lon && entry.lon.value - data1HourAgo.lon.value > 0.005) ||
			(entry.elevation &&
				data1HourAgo.elevation &&
				entry.elevation.value - data1HourAgo.elevation.value > 3) ||
			(entry.orientation &&
				data1HourAgo.orientation &&
				entry.orientation.value - data1HourAgo.orientation.value > 10))
	);
};

export const getWeather = (obj) => {
	let value = __isEmpty(obj) || __isEmpty(obj.value) ? <>&nbsp;</> : obj.value;

	switch (value) {
		case '+RA':
			return `${value} (Rain)`;
		case 'RA':
			return `${value} (Rain)`;
		case '-RA':
			return `${value} (Rain)`;
		case 'FG':
			return `${value} (Fog)`;
		case 'HZ':
			return `${value} (Haze)`;
		case 'BR':
			return `${value} (Mist)`;
		default:
			return value;
	}
};

export const setValueColor = (row, data1HourAgo, metric, value) => {
	let colorClass;

	switch (metric) {
		case 'mws-voltage':
			if (__isEmpty(value) || __isEmpty(value.value)) {
				colorClass = '';
			} else if (value.value >= 5) {
				colorClass = 'good-value';
			} else if (value.value >= 4.8 && value.value < 5) {
				colorClass = 'meh-value';
			} else {
				colorClass = 'bad-value';
			}
			break;
		case 'sediment':
			if (__isEmpty(value) || __isEmpty(value.value)) {
				colorClass = '';
			} else if (value.value === 'Interference') {
				colorClass = 'bad-value';
			} else {
				colorClass = 'good-value';
			}
			break;
		case 'tampering':
			if (isTampered(row, data1HourAgo)) {
				colorClass = 'bad-value';
			} else {
				colorClass = 'good-value';
			}
			break;
		case 'flood':
			if (value.value === 1) {
				colorClass = 'bad-value';
			}
			break;
		default:
			colorClass = '';
			break;
	}

	return colorClass;
};

export const formatCloudLayersValue = (cloudLayers) => {
	let value =
		__isEmpty(cloudLayers) || __isEmpty(cloudLayers.value) ? <>&nbsp;</> : cloudLayers.value;

		if (
		value === 'NCD' ||
		value === 'No Ceilometer' ||
		value === 'Ceilometer OFF' ||
		React.isValidElement(value)
	) {
		return value;
	}
	
	const separateLayers = value.split(',');
	const formatted = separateLayers.map((layer) => {
		return `${layer} ft`;
	});

	return formatted.join(',');
};

export const formatCloudLayersCoverage = (cloudLayers, cloudCoverage) => {
	let value =
		__isEmpty(cloudCoverage) || __isEmpty(cloudCoverage.value) ? <>&nbsp;</> : cloudCoverage.value;

		if (
			React.isValidElement(value)
		) {
			return value;
		}
	let formatted = formatCloudLayersValue(cloudLayers);
	const seperateCoverage = value.split(',');
	while(seperateCoverage[0] == "CLR"){
		seperateCoverage.shift();
	}
	
	formatted = formatted.split(',');
	if(formatted.length == seperateCoverage.length){
		for(let i = 0; i < formatted.length; i++){
			formatted[i] = formatted[i] + ` (${seperateCoverage[i]})`
		}
	}

	return formatted.join(',');

}

export const renderAudioLinks = (row) => {
	if (row.spectralINP || row.spectralMP3 || row.spectralWAV) {
		return (
			<div className="audio-block">
				{row.spectralINP && row.spectralINP.value && (
					<a
						target="_blank"
						rel="noopener noreferrer"
						className="download-link"
						href={`${process.env.REACT_APP_AUDIO_URL}/${row.spectralINP.value}`}>
						<img alt="" src={AudioIcon} />
						Download INP File
					</a>
				)}
				{row.spectralMP3 && row.spectralMP3.value && (
					<a
						target="_blank"
						rel="noopener noreferrer"
						className="download-link"
						href={`${process.env.REACT_APP_AUDIO_URL}/${row.spectralMP3.value}`}>
						<img alt="" src={AudioIcon} />
						Download MP3 File
					</a>
				)}
				{row.spectralWAV && row.spectralWAV.value && (
					<a
						target="_blank"
						rel="noopener noreferrer"
						className="download-link"
						href={`${process.env.REACT_APP_AUDIO_URL}/${row.spectralWAV.value}`}>
						<img alt="" src={AudioIcon} />
						Download WAV File
					</a>
				)}
			</div>
		);
	}

	return null;
};

export const getGraphData = (entries, metric) => {
	const points = [];

	__forEach(entries, (entry) => {
		if (entry[metric]) {
			points.push({
				t: entry[metric].ts,
				y: parseFloat(entry[metric].value),
			});
		}
	});

	return points;
};

export const getMaxGraphData = (points) => {

	var max = -Infinity;

	__forEach(points, (point) => {
		if (point["y"] > max) {
			max = point["y"];
		}
	});

	return max;
};

export const getMinGraphData = (points) => {
	var min = Infinity;

	__forEach(points, (point) => {
		if (point["y"] < min) {
			min = point["y"];
		}
	});

	return min;
};

export const getMETAR = (data, showMetricOnly) => {
	if (!showMetricOnly) {
		return data.METAR ? data.METAR.value : 'No METAR';
	}

	return data.METARInternational
		? data.METARInternational.value
		: data.METAR
		? data.METAR.value
		: 'No METAR';
};

//if the row has a No METAR header, return false
export const showRow = (data, showMetricOnly) => {
	if (!showMetricOnly) {
		return data.METAR ? data.METAR.value : false; 
	}

	return data.METARInternational
		? data.METARInternational.value
		: true
		? data.METAR.value
		: false;
};

export const doesWeatherValueExist = (weatherVal) => {
	return weatherVal && !__isEmpty(weatherVal.value);
};
